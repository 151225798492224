import React from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { TextFieldProps } from '@material-ui/core/TextField';
import labels from '../../../../dictionaries/discrepancy-labels.json';

interface IDiscrepancyLabels {
  [key: string]: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
      position: 'relative',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: '1px solid #333'
        }
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          border: '1px solid #f0f0f0'
        }
      }
    },
    errorIcon: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(-4),
      color: '#bf3939'
    }
  })
);

export const DiscrepancyTextField = (props: TextFieldProps) => {
  const classes = useStyles();
  const { name } = props;
  const label = (labels as IDiscrepancyLabels)[name as string];

  return (
    <div className={classes.root}>
      {props.disabled === false && <ErrorIcon className={classes.errorIcon} />}
      <TextField {...props} label={label} fullWidth variant="outlined" />
    </div>
  );
};
