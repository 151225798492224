import React from 'react';
import logo from './../../assets/images/logo.svg';

import './sign-in-brand.scss';

export const SignInBrand = () => (
  <div className="sign-in-logo">
    <img src={logo} alt="Shop Direct Logo" />
    <strong>Customs Reprocessing System</strong>
  </div>
);
