export interface IDiscrepancyEntity {
  SKU: string;
  catalogueNumber: string;
  ItemDescription: string;
  CostPrice: number;
  NetWeight: number;
  GrossWeight: number;
  HarmonisedSystemCode: number;
  UKHarmonisedSystemCode: number;
  CountryOfOrigin: string;
  PK: string;
  SK: string;
  UKVATRate: number;
  RoIVATRate: number;
  version: number;
}

export interface IResolvedDiscrepancyEntity {
  SKU?: string;
  catalogueNumber?: string;
  ItemDescription?: string;
  CostPrice?: number;
  NetWeight?: number;
  GrossWeight?: number;
  HarmonisedSystemCode?: number;
  UKHarmonisedSystemCode?: number;
  CountryOfOrigin?: string;
  PK?: string;
  SK?: string;
  UKVATRate?: number;
  RoIVATRate?: number;
  version?: number;
}

export const createEmptyDiscrepancy = () => ({
  SKU: '',
  catalogueNumber: '',
  ItemDescription: '',
  CostPrice: 0,
  NetWeight: 0,
  GrossWeight: 0,
  HarmonisedSystemCode: 0,
  UKHarmonisedSystemCode: 0,
  CountryOfOrigin: '',
  PK: '',
  SK: '',
  UKVATRate: 0,
  RoIVATRate: 0,
  version: 0
});
