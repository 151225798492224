import React, { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

interface IConfirmationDialogProps {
  children?: ReactNode;
  title: string;
  open: boolean;
  text: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm(): void;
  onClose(): void;
}

export const ConfirmationDialog = ({
  open,
  title,
  text,
  onClose,
  onConfirm,
  children,
  confirmButtonLabel = 'Yes',
  cancelButtonLabel = 'No'
}: IConfirmationDialogProps) => {
  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog open={open} disableBackdropClick fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel} color="default">
          {cancelButtonLabel}
        </Button>
        <Button variant="contained" onClick={handleOk} color="primary">
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
