import React, { Fragment } from 'react';
import { PageHeading } from '../../components/common/page-heading';
import { DiscrepancyList } from '../../components/discrepancy-list';
import { DiscrepanciesProvider } from '../../context/discrepancies';

export const DiscrepanciesPage = () => {
  return (
    <Fragment>
      <PageHeading>Discrepancies</PageHeading>
      <DiscrepanciesProvider>
        <DiscrepancyList />
      </DiscrepanciesProvider>
    </Fragment>
  );
};
