import { createMuiTheme } from '@material-ui/core/styles';

const pallete = {
  primary: {
    main: 'rgb(233, 71, 145)',
    shade: 'rgb(200, 71, 145)',
    tint: 'rgb(233, 100, 145)'
  }
};

const customTheme = createMuiTheme({
  palette: pallete,
  overrides: {
    MuiSelect: {
      select: {
        'min-width': '180px'
      }
    },
    MuiMenuItem: {
      root: {
        'min-width': '180px'
      }
    },
    MuiCard: {
      root: {
        margin: '5px'
      }
    },
    MuiCardHeader: {
      title: {
        fontSize: '1rem'
      }
    }
  }
});

export { customTheme, pallete };
