import React from 'react';
import { HandlersProvider } from '../context/handlers';

const withHandlersProvider = (Component: any) => {
  return (props: any) => {
    return (
      <HandlersProvider>
        <Component {...props} />
      </HandlersProvider>
    );
  };
};

export default withHandlersProvider;
