import React, { ReactNode } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import './main.scss';

interface Props {
  children: ReactNode;
}

export const Main = (props: Props) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <SnackbarProvider preventDuplicate>
        <div id="main">{props.children}</div>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  );
};
