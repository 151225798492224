import { dashFormatDate } from '../../helpers';

export const createEmptyConsignment = () => ({
  trailerType: '',
  handler: '',
  origin: '',
  statusLabel: '',
  consignmentId: '',
  userDefinedId: '',
  date: dashFormatDate()
});
