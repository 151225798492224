import React, { Fragment, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useUserGroups } from '../../../../hooks/use-user-handlers';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  IconButton,
  Theme,
  Typography,
  Container
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  AssignmentLate as AssignmentLateIcon,
  Archive as ArchiveIcon,
  ChevronLeft as ChevronLeftIcon,
  Build as BuildIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  ToggleOn as ToggleOnIcon
} from '@material-ui/icons';
import config from '../../../../config';
import { URLS, USER_GROUPS } from '../../../../constants';
import logo from '../../../../assets/images/logo.svg';
import { isUserAuthorised } from '../../../../helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: theme.spacing(4),
      flexGrow: 1
    },
    list: {
      width: theme.spacing(40),
      padding: 0
    },
    navLinkActive: {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.palette.grey[600]
      }
    },
    drawerHeader: {
      display: 'flex',
      padding: theme.spacing(2, 1),
      alignItems: 'center'
    },
    footerText: {
      color: theme.palette.grey[600]
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      padding: theme.spacing(0, 0, 0, 1)
    }
  })
);

export const NavigationMenu = () => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = React.useState(false);

  const handleMenuToggle = () => setIsOpened(!isOpened);
  const groups = useUserGroups();

  const menuItems = [
    {
      icon: <ArchiveIcon />,
      link: URLS.CONSIGNMENT_LIST,
      label: 'Consignments'
    }
  ];
  if (groups) {
    if (isUserAuthorised(groups, USER_GROUPS.DISCREPANCY)) {
      menuItems.push({
        icon: <AssignmentLateIcon />,
        link: URLS.DISCREPANCIES_LIST,
        label: 'Discrepancies'
      });
    }
    if (isUserAuthorised(groups, USER_GROUPS.ADMIN)) {
      menuItems.push({
        icon: <BuildIcon />,
        link: URLS.ADMIN,
        label: 'Admin'
      });
    }
    if (isUserAuthorised(groups, USER_GROUPS.USER_ADMIN)) {
      menuItems.push({
        icon: <PeopleIcon />,
        link: URLS.USER_ADMIN,
        label: 'User Admin'
      });
    }
    if (isUserAuthorised(groups, USER_GROUPS.SCAN_DATA_LOG)) {
      menuItems.push({
        icon: <ReceiptIcon />,
        link: URLS.SCAN_DATA_LOG,
        label: 'Scan Data Log'
      });
    }
    if (isUserAuthorised(groups, USER_GROUPS.DEV)) {
      menuItems.push({
        icon: <ToggleOnIcon />,
        link: URLS.FEATURES,
        label: 'Features'
      });
    }
  }

  const renderNavLink = (to: string) =>
    forwardRef((linkProps: any, ref) => (
      <NavLink activeClassName={classes.navLinkActive} to={to} {...linkProps} innerRef={ref} />
    ));

  const navList = (
    <List className={classes.list}>
      {menuItems.map((item, index) => (
        <ListItem button component={renderNavLink(item.link)} key={index}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Fragment>
      <IconButton onClick={handleMenuToggle}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isOpened} onClose={handleMenuToggle}>
        <div tabIndex={0} role="button" onClick={handleMenuToggle} onKeyDown={handleMenuToggle}>
          <div className={classes.drawerHeader}>
            <img className={classes.logo} src={logo} alt="logo" />
            <IconButton onClick={handleMenuToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {navList}
          <Divider />
          <Container className={classes.footer}>
            <Typography variant="caption" className={classes.footerText} display="block">
              Environment: {config.app.environment}
            </Typography>
            <Typography variant="caption" className={classes.footerText} display="block">
              Version: {config.app.buildVersion}
            </Typography>
          </Container>
        </div>
      </Drawer>
    </Fragment>
  );
};
