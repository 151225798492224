import { useContext } from 'react';
import { HandlersContext } from '../context/handlers';
import { useUserGroups as useContextUserGroups } from '../context/authentication';
import { IFormFields } from '../models/form-fields';
import { IHandler } from '../models/handler';

export const useUserGroups = () => {
  return useContextUserGroups();
};

export const useUserHandlers = () => {
  const { handlersState } = useContext(HandlersContext);
  const userGroups = useUserGroups();
  let handlers = handlersState.handlers && handlersState.handlers;
  if (!Array.isArray(handlers)) {
    handlers = handlers.handlers;
  }
  const originsDict: IFormFields[] = [];
  if (Array.isArray(userGroups) && Array.isArray(handlers)) {
    userGroups.map((group: string) => {
      handlers.map((handler: IHandler) => {
        if (group === handler.PK) {
          originsDict.push({
            label: handler.displayName,
            value: handler.PK
          });
        }
      });
    });
  }

  return originsDict;
};
export const useUserHandlersInfo = () => {
  const { handlersState } = useContext(HandlersContext);
  let handlers = handlersState.handlers && handlersState.handlers;
  const displayNames: any = {};
  if (!Array.isArray(handlers)) {
    handlers = handlers.handlers;
  }
  for (const handler of handlers) {
    displayNames[handler.PK] = handler.displayName;
  }
  return displayNames;
};

export const useShippingLines = () => {
  const { handlersState } = useContext(HandlersContext);
  const handlers = handlersState.handlers && handlersState.handlers;
  if (!Array.isArray(handlers)) {
    return handlers.shipping_lines;
  }
  return [];
};
