import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress, DialogContent, Grid, Modal, Paper, Table, Typography } from '@material-ui/core';
import { EnhancedTableBody, EnhancedTableHead, Filters } from '../common/filterable-table';
import { getParcels } from '../../services/api';
import { HTTP_ERROR_MESSAGE } from '../../constants';
import { useUserHandlers, useUserHandlersInfo } from '../../hooks/use-user-handlers';
import { createEmptyParcel } from './model';
import consignmentStatusesDict from '../../dictionaries/consignment-statuses.json';
import { formatAlphanumericInput, formatDate, objectFilter } from '../../helpers';
import columnsConfig from '../parcels-list/parcels-table-config.json';
import { Tooltips } from './tooltips';
import { EmptyPopup, PopupDefinition, StatusPopup } from '../common/status-popup';
import { customTheme } from '../main/materialStyles';
import { ParcelsContext } from '../../context/parcels';
import { Pagination } from '@material-ui/lab';
import { ceil } from 'lodash';
import Button from '@material-ui/core/Button';
import { getFileURL } from '../../services/storage';
import { CloudDownload, OpenInNew } from '@material-ui/icons';
import { DocumentsRequest } from '../parcels-document-request';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '27px 23px'
    },
    table: {
      tableLayout: 'fixed'
    },
    tableWrapper: {
      overflow: 'overlay',
      height: window.innerHeight - 360 + 'px'
    },
    loader: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      height: '100%'
    },
    emptyData: {
      opacity: 0.5
    },
    button: {
      margin: '10px 5px 0',
      float: 'right'
    },
    icon: {
      marginRight: theme.spacing(1),
      fontSize: 20
    }
  })
);

const useTooltipsStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      padding: theme.spacing(0, 1, 0)
    },
    statusMarker: {
      display: 'inline-block',
      width: theme.spacing(1),
      height: theme.spacing(1),
      margin: theme.spacing(0, 1, 0, 0),
      borderRadius: 4
    },
    green: {
      backgroundColor: '#a7cb19'
    },
    blue: {
      backgroundColor: '#76c5fd'
    },
    orange: {
      backgroundColor: '#e7b15e'
    },
    tooltips: {
      width: theme.spacing(11)
    }
  })
);

const useRowsStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: 'pointer'
    }
  })
);

const carrierMap: any = {};
const getCarrier = (handler: string) => {
  return carrierMap[handler] || handler;
};

type LastEvaluatedKey = {
  parcelIdentifier: string;
  documentType: string;
  s3Key: string;
  crn: string;
};

export interface IParcelListProps extends RouteComponentProps {
  consignmentId: string;
}

export const ParcelsList = withRouter((props: IParcelListProps) => {
  const classes = useStyles();
  const rowsClasses = useRowsStyles();
  const tooltipsClasses = useTooltipsStyles();
  const carriers = useUserHandlers();
  const originsDict = useUserHandlers();
  const handlersInfo = useUserHandlersInfo();
  const { consignmentId } = props;
  const { parcelsState, dispatchParcels } = useContext(ParcelsContext);
  const [filters, setFilters] = useState('');
  const [popup, setPopup] = React.useState(EmptyPopup);
  const [values, setValues] = useState(createEmptyParcel());
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(250);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [paginationKeys, setPaginationKeys] = useState<LastEvaluatedKey[]>([]);
  const [openDocsRequest, setOpenDocsRequest] = useState(false);
  const allFilter = {
    value: '',
    label: 'ALL'
  };
  const handlers = [allFilter, ...carriers];
  const statuses = [allFilter, ...consignmentStatusesDict];
  const origins = [allFilter, ...originsDict];

  for (const carrier of carriers) {
    carrierMap[carrier.value] = carrier.label;
  }

  const columns = columnsConfig.map((column: any, index: number) => {
    if (column.id === 'handler') {
      column.options = handlers;
    }
    if (column.id === 'origin') {
      column.options = origins.map((origin) => {
        return { value: origin.value, label: handlersInfo[origin.label] || origin.label };
      });
    }
    if (column.id === 'status') {
      column.options = statuses;
    }
    return column;
  });

  useEffect(() => {
    (async () => {
      await setParcelsToContext(filters);
    })();
  }, []);

  const formatInputValue = (fieldName: string, value: string) => {
    const fields = {
      consignmentId: formatAlphanumericInput,
      userDefinedId: formatAlphanumericInput
    } as any;
    return fields[fieldName] ? fields[fieldName](value) : value.trim();
  };

  const setInputValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    const formattedValue = formatInputValue(name, value);
    setValues({ ...values, [name]: formattedValue });
    return { name, formattedValue };
  };

  const prepareFilterParams = (name: string, value: string) => {
    const searchParams = new URLSearchParams(filters);
    if (value === '') {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }
    const filterParams = searchParams.toString();
    setFilters(filterParams);
    return filterParams;
  };

  const setParcelsToContext = async (filterParams: string, pageNumber: number = page) => {
    try {
      setLoader(false);
      const lastKey = paginationKeys.length === 0 || pageNumber === 1 ? {} : paginationKeys[pageNumber - 2];
      const response = await getParcels(
        consignmentId,
        encodeURIComponent(JSON.stringify(lastKey)),
        pageSize,
        `${filterParams}`
      );
      const consignment = response.consignment;
      if ('parcelCount' in consignment) {
        setTotalItemCount(consignment.parcelCount);
      }
      if (paginationKeys.length < pageNumber && response.lastKey !== {}) {
        setPaginationKeys([...paginationKeys, response.lastKey]);
      }
      const items = [];
      for (const parcel of response.parcels) {
        const parcelItem = { ...consignment };
        parcelItem.identifier = parcel.parcelIdentifier;
        parcelItem.crn = parcel.crn;
        parcelItem.invoiceKeyIE =
          (parcel.documents.IE_VAT_INVOICE_PDF && parcel.documents.IE_VAT_INVOICE_PDF) ||
          (parcel.documents.VAT_INVOICE_PDF && parcel.documents.VAT_INVOICE_PDF);
        parcelItem.customsInvoiceKeyIE =
          (parcel.documents.IE_CUSTOMS_INVOICE_PDF && parcel.documents.IE_CUSTOMS_INVOICE_PDF) ||
          (parcel.documents.CUSTOMS_INVOICE_PDF && parcel.documents.CUSTOMS_INVOICE_PDF);
        parcelItem.manifestKeyIE =
          (parcel.documents.IE_MANIFEST_PDF && parcel.documents.IE_MANIFEST_PDF) ||
          (parcel.documents.MANIFEST_PDF && parcel.documents.MANIFEST_PDF);
        items.push(parcelItem);
      }
      setPageCount(items.length);
      dispatchParcels({ type: 'set', items });
      setLoader(true);
    } catch (e) {
      console.error(e);
      setPopup(PopupDefinition.create('ERROR', HTTP_ERROR_MESSAGE));
      setLoader(true);
    }
  };

  const handleChangePage = async (event: any, newPage: number) => {
    setPage(newPage);
    await setParcelsToContext(filters, newPage);
  };

  const calcPageCount = () => {
    if (paginationKeys.length + 1 > ceil(totalItemCount / pageSize)) return ceil(totalItemCount / pageSize);
    else return paginationKeys.length + 1;
  };

  const filter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, formattedValue } = setInputValue(event);
    const filterParams = prepareFilterParams(name, formattedValue);
    const { type } = event.target as HTMLInputElement;
    if (type !== 'search') {
      await setParcelsToContext(filterParams);
    } else if (event.which === 13) {
      event.preventDefault();
      await setParcelsToContext(filterParams);
    } else if (!formattedValue && typeof event.which === 'undefined') {
      await setParcelsToContext(filterParams);
    }
  };

  const parcels = parcelsState.parcels
    ? parcelsState.parcels.map((parcel: any) => {
        const tooltip = () => (
          <Tooltips
            consignmentId={parcel.consignmentId}
            invoiceKeyIE={parcel.invoiceKeyIE && parcel.invoiceKeyIE}
            customsInvoiceKeyIE={parcel.customsInvoiceKeyIE && parcel.customsInvoiceKeyIE}
            manifestKeyIE={parcel.manifestKeyIE && parcel.manifestKeyIE}
          />
        );
        const rowStyles = rowsClasses.tableRow;
        const created = () => formatDate(parcel.created);
        parcel.tooltips = tooltip();
        parcel.date = created();
        const data: any = objectFilter(parcel, columns, 'id');
        data.handler = handlersInfo[parcel.handler];
        data.origin = handlersInfo[parcel.origin];

        return { data, rowStyles };
      })
    : [];

  const MemoizedEnhancedTable = useMemo(
    () => (
      <Table className={classes.table}>
        <EnhancedTableBody rows={parcels} cellsStyles={tooltipsClasses} />
      </Table>
    ),
    [parcelsState, handlersInfo]
  );

  let popupMessage = <></>;
  if (popup.type && popup.message) {
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Paper className={classes.root}>
        <Modal open={openDocsRequest}>
          <DialogContent>
            <DocumentsRequest
              consignmentId={consignmentId}
              setOpenDocsRequest={setOpenDocsRequest}
              setPopup={setPopup}
            />
          </DialogContent>
        </Modal>
        {popupMessage}
        <Grid container>
          <Grid item xs={4} />
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <p>
              <b>Parcels on this page : {pageCount}</b> | Total Parcels : {totalItemCount} |
              <b> No. of Pages : {ceil(totalItemCount / pageSize)}</b>
            </p>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Pagination
                count={totalItemCount < pageSize ? 1 : calcPageCount()}
                defaultPage={1}
                siblingCount={1}
                boundaryCount={1}
                page={page}
                onChange={handleChangePage}
                showLastButton={false}
              />
            </Box>
            <br />
          </Grid>
          <Grid item xs={4}>
            <Button
              id="open-docs-request"
              disabled={!loader}
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => {
                setOpenDocsRequest(true);
              }}
            >
              <OpenInNew className={classes.icon} />
              Docs Request
            </Button>
          </Grid>
        </Grid>
        <Table className={classes.table}>
          <EnhancedTableHead rows={columns}>
            <Filters
              filter={filter}
              dateFilter={null}
              handleInputTyping={setInputValue}
              values={values}
              rows={columns}
            />
          </EnhancedTableHead>
        </Table>
        <div className={classes.tableWrapper}>
          {loader ? (
            typeof parcelsState.parcels !== 'undefined' && parcelsState.parcels.length > 0 ? (
              MemoizedEnhancedTable
            ) : (
              <Grid container className={classes.loader} justifyContent="center">
                <Typography className={classes.emptyData} variant="h5">
                  No Data
                </Typography>
              </Grid>
            )
          ) : (
            <Grid container className={classes.loader} justifyContent="center">
              <CircularProgress disableShrink />
            </Grid>
          )}
        </div>
      </Paper>
    </MuiThemeProvider>
  );
});
