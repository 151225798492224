import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import '../../styles/styles.css';
import { DataGrid } from '@material-ui/data-grid';
import { commonStyles } from '../../styles';
import { getConsignmentUpiHistory } from '../../services/api';

export default function AlertDialog(historyFlag: boolean, upi: string) {
  const [open, setOpen] = React.useState(false);
  const [upisLogs, setUpisLogs] = React.useState([]);
  const classes = commonStyles();

  const columns = [
    { field: 'id', headerName: 'id', width: 70 },
    { field: 'userId', headerName: 'userId', width: 160 },
    { field: 'consignmentId', headerName: 'consignmentId', width: 160 },
    { field: 'dateTime', headerName: 'dateTime', width: 220 }
  ];

  const handleClickOpen = async () => {
    const response = await getConsignmentUpiHistory(upi);
    setUpisLogs(response.history);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid>
      <Button
        variant="outlined"
        className={classes.button}
        color="primary"
        onClick={handleClickOpen}
        disabled={!historyFlag}
      >
        History
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle id="alert-dialog-title">{'UPI`s history'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Log history for current UPI: {upi}</DialogContentText>
          <div>
            <DataGrid autoHeight autoPageSize rows={upisLogs} columns={columns} pageSize={5} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
