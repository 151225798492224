import Amplify, { API, Auth } from 'aws-amplify';
import endpoints, {
  ABORT_PARCEL,
  ADD_PARCEL_TO_CONSIGNMENT,
  CLOSE_CONSIGNMENT,
  CONSIGNMENT_BY_ID,
  CONSIGNMENT_DOCS,
  CONSIGNMENT_LIST,
  CONSIGNMENT_UPI,
  CONSIGNMENT_UPIS,
  CREATE_CONSIGNMENT,
  CREATE_PARCEL,
  DISCREPANCIES_LIST,
  DISCREPANCY_BY_ID,
  DOCUMENTS_REQUEST,
  EDIT_CONSIGNMENT,
  FEATURE_GET_LIST,
  FEATURE_UPDATE_STATE,
  GET_PARCEL,
  HANDLERS,
  PARCEL_ITEM_BY_ID,
  PARCEL_ITEMS_ADVANCED_SEARCH,
  PARCELS_LIST,
  RESOLVE_DISCREPANCY,
  SCAN_DATA_LOG,
  SETTINGS,
  SETTINGS_EXCHANGE_RATE,
  SETTINGS_FREIGHT_CHARGES,
  SETTINGS_SFTP_CONFIG,
  SETTINGS_STATISTICAL_VALUES,
  USER,
  USER_SESSION_SETTINGS,
  USERS
} from './endpoints';
import config from '../../config';

const getAuthHeader = async () => {
  const {
    idToken: { jwtToken }
  }: any = await Auth.currentSession();
  return { Authorization: jwtToken };
};

const apiDescription = Object.keys(endpoints).map((name: string) => ({
  name,
  endpoint: config.api.baseUrl,
  custom_header: getAuthHeader
}));

Amplify.configure({
  API: { endpoints: apiDescription }
});

export const addParcel = (consignmentId: any, id: string) =>
  API.patch(ADD_PARCEL_TO_CONSIGNMENT, `${config.api.stagePath}${endpoints[ADD_PARCEL_TO_CONSIGNMENT].path}/${id}`, {
    body: { consignmentId }
  });

export const getDiscrepancies = (filter: string) =>
  API.get(DISCREPANCIES_LIST, `${config.api.stagePath}${endpoints[DISCREPANCIES_LIST].path}${filter}`, null);

export const getDiscrepancyById = (id: string) =>
  API.get(DISCREPANCY_BY_ID, `${config.api.stagePath}${endpoints[DISCREPANCY_BY_ID].path}/${id}`, null);

export const resolveDiscrepancy = (body: any, id: string) =>
  API.patch(RESOLVE_DISCREPANCY, `${config.api.stagePath}${endpoints[RESOLVE_DISCREPANCY].path}/${id}`, { body });

export const getParcel = (id: any) =>
  API.get(GET_PARCEL, `${config.api.stagePath}${endpoints[GET_PARCEL].path}/${id}`, null);

export const getParcelItemById = (id: string) =>
  API.get(PARCEL_ITEM_BY_ID, `${config.api.stagePath}${endpoints[PARCEL_ITEM_BY_ID].path}/${id}`, null);

export const addItemsToParcel = (id: string, consignmentId: string, keys: string[]) =>
  API.post(CREATE_PARCEL, `${config.api.stagePath}${endpoints[CREATE_PARCEL].path}/${id}`, {
    body: {
      keys,
      consignmentId
    }
  });

export const abortParcel = (id: string) =>
  API.post(ABORT_PARCEL, `${config.api.stagePath}${endpoints[ABORT_PARCEL].path}/${id}/abort`, null);

export const getHandlers = () => API.get(HANDLERS, `${config.api.stagePath}${endpoints[HANDLERS].path}`, null);

export const searchParcelItems = (value: string) =>
  API.get(
    PARCEL_ITEMS_ADVANCED_SEARCH,
    `${config.api.stagePath}${endpoints[PARCEL_ITEMS_ADVANCED_SEARCH].path}?value=${value}`,
    null
  );

export const getUserSessionSettings = () =>
  API.get(USER_SESSION_SETTINGS, `${config.api.stagePath}${endpoints[USER_SESSION_SETTINGS].path}`, null);

export const createScanDateLogCsv = (body: any) =>
  API.post(SCAN_DATA_LOG, `${config.api.stagePath}${endpoints[SCAN_DATA_LOG].path}`, { body });

/**
 * CONSIGNMENTS
 */
export const getConsignments = (filter: string) =>
  API.get(CONSIGNMENT_LIST, `${config.api.stagePath}${endpoints[CONSIGNMENT_LIST].path}${filter}`, null);

export const getParcels = (consignmentId: string, lastKey: string, pageSize: number, filter: string) =>
  API.get(
    PARCELS_LIST,
    `${config.api.stagePath}${endpoints[PARCELS_LIST].path}/${consignmentId}/parcels?lastEvaluatedKey=${lastKey}&pageSize=${pageSize}&${filter}`,
    null
  );

export const submitDocsRequest = (consignmentId: string, body: object) =>
  API.post(
    DOCUMENTS_REQUEST,
    `${config.api.stagePath}${endpoints[DOCUMENTS_REQUEST].path}/${consignmentId}/parcels/documentRequest`,
    { body }
  );

export const createConsignment = (body: any) =>
  API.post(CREATE_CONSIGNMENT, `${config.api.stagePath}${endpoints[CREATE_CONSIGNMENT].path}`, { body });
export const editConsignment = (id: string, body: any) =>
  API.patch(EDIT_CONSIGNMENT, `${config.api.stagePath}${endpoints[EDIT_CONSIGNMENT].path}/${id}`, { body });
export const getConsignmentById = (id: string) =>
  API.get(CONSIGNMENT_BY_ID, `${config.api.stagePath}${endpoints[CONSIGNMENT_BY_ID].path}/${id}`, null);
export const closeConsignment = (id: string) =>
  API.post(CLOSE_CONSIGNMENT, `${config.api.stagePath}${endpoints[CLOSE_CONSIGNMENT].path}/${id}/close`, {
    body: { status: 'CLOSED' }
  });
export const getConsignmentUpiHistory = (id: string) =>
  API.get(CONSIGNMENT_UPI, `${config.api.stagePath}${endpoints[CONSIGNMENT_UPI].path}/${id}/history`, null);
export const regenerateConsignmentDocs = (body: any) =>
  API.put(CONSIGNMENT_DOCS, `${config.api.stagePath}${endpoints[CONSIGNMENT_DOCS].path}`, { body });
export const removeUpisFromConsignment = (body: any) =>
  API.del(CONSIGNMENT_UPIS, `${config.api.stagePath}${endpoints[CONSIGNMENT_UPIS].path}`, { body });

/**
 * USER MANAGEMENT
 */
export const getUsers = () => API.get(USERS, `${config.api.stagePath}${endpoints[USERS].path}`, null);
export const createUser = (body: any) => API.post(USER, `${config.api.stagePath}${endpoints[USER].path}`, { body });
export const updateUser = (body: any) => API.patch(USER, `${config.api.stagePath}${endpoints[USER].path}`, { body });

/**
 * SETTINGS
 */
export const getSettings = () => API.get(SETTINGS, `${config.api.stagePath}${endpoints[SETTINGS].path}`, null);
export const updateExchangeRate = (body: any) =>
  API.patch(SETTINGS_EXCHANGE_RATE, `${config.api.stagePath}${endpoints[SETTINGS_EXCHANGE_RATE].path}`, { body });
export const updateSftpConfigs = (body: any) =>
  API.patch(SETTINGS_SFTP_CONFIG, `${config.api.stagePath}${endpoints[SETTINGS_SFTP_CONFIG].path}`, { body });
export const updateStatisticalValue = (body: any) =>
  API.patch(SETTINGS_STATISTICAL_VALUES, `${config.api.stagePath}${endpoints[SETTINGS_STATISTICAL_VALUES].path}`, {
    body
  });
export const updateFreightCharges = (body: any) =>
  API.patch(SETTINGS_FREIGHT_CHARGES, `${config.api.stagePath}${endpoints[SETTINGS_FREIGHT_CHARGES].path}`, {
    body
  });

/**
 * FEATURES
 */
export const getFeatures = () =>
  API.get(FEATURE_GET_LIST, `${config.api.stagePath}${endpoints[FEATURE_GET_LIST].path}`, null);
export const updateFeature = (body: any) =>
  API.patch(FEATURE_UPDATE_STATE, `${config.api.stagePath}${endpoints[FEATURE_UPDATE_STATE].path}`, { body });
