import moment from 'moment';

export const initGenerateScanDataLogForm = () => ({
  dateFrom: moment().startOf('day').toISOString(),
  dateTo: moment().endOf('day').toISOString(),
  consignmentId: '',
  carrier: '',
  productTypeIndicator: [],
  upiStatus: [],
  carrierBarcode: ''
});
