import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { CircularProgress, Grid, Paper, Table, Typography } from '@material-ui/core';
import { EnhancedTableBody, EnhancedTableHead, Filters } from '../common/filterable-table';
import { getDiscrepancies } from '../../services/api';
import { DiscrepanciesContext } from '../../context/discrepancies';
import { dashFormatDate, formatAlphanumericInput, formatDate, formatNumericInput, objectFilter } from '../../helpers';
import rows from './discrepancy-table-config.json';
import { createEmptyDiscrepancies } from './model';
import { DeviceHub as DeviceHubIcon } from '@material-ui/icons';
import cIcon from '../../assets/images/c-icon.svg';
import pIcon from '../../assets/images/p-icon.svg';
import { StatusPopup } from '../../components/common/status-popup';
import { HTTP_ERROR_MESSAGE } from '../../constants'
import { customTheme } from "../main/materialStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
    table: {
      tableLayout: 'fixed'
    },
    tableWrapper: {
      overflow: 'overlay',
      height: window.innerHeight - 350
    },
    loader: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      height: '100%'
    },
    emptyData: {
      opacity: 0.5
    },
    icon: {
      verticalAlign: 'middle',
      fontSize: theme.spacing(2.5),
      margin: theme.spacing(0, 0.5, 0),
      color: theme.palette.text.secondary
    },
    dataMissingIcon: {
      verticalAlign: 'middle',
      width: theme.spacing(2),
      margin: theme.spacing(0, 0.5, 0)
    }
  })
);

const useSKUStyles = makeStyles((theme: Theme) =>
  createStyles({
    SKU: {
      padding: theme.spacing(2, 2, 2, 3.5)
    }
  })
);

export const DiscrepancyList = withRouter((props) => {
  const classes = useStyles();
  const SKUClasses = useSKUStyles();
  // @ts-ignore
  const { state, dispatch } = useContext(DiscrepanciesContext);
  const [filters, setFilters] = useState('');
  const [error, setError] = React.useState("");
  const [values, setValues] = useState(createEmptyDiscrepancies());
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      await setDiscrepanciesToContext('sortingField=affectedParcelsCount&sortType=desc');
    })();
  }, []);

  const formatInputValue = (fieldName: string, value: string) => {
    const fields = {
      SKU: formatAlphanumericInput,
      affectedParcelsCount: formatNumericInput
    } as any;
    return fields[fieldName] ? fields[fieldName](value) : value;
  };

  const setInputValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    const formattedValue = formatInputValue(name, value);
    setValues({ ...values, [name]: formattedValue });
    return { name, formattedValue };
  };

  const prepareFilterParams = (name: string, value: string) => {
    const searchParams = new URLSearchParams(filters);
    if (value === '') {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }
    const filterParams = searchParams.toString();
    setFilters(filterParams);
    return filterParams;
  };

  const setDiscrepanciesToContext = async (filterParams: string) => {
    try {
      setLoader(false);
      const discrepanciesData = await getDiscrepancies(`?${filterParams}`);
      dispatch({ type: 'set', discrepanciesData });
      setLoader(true);
    } catch (e) {
      console.error(e);
      setError(HTTP_ERROR_MESSAGE);
      setLoader(true);
    }
  };

  const filter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, formattedValue } = setInputValue(event);
    const filterParams = prepareFilterParams(name, formattedValue);
    const { type } = event.target as HTMLInputElement;
    if (type !== 'search') {
      await setDiscrepanciesToContext(filterParams);
    } else if (event.which === 13) {
      event.preventDefault();
      await setDiscrepanciesToContext(filterParams);
    } else if (!formattedValue && typeof event.which === 'undefined') {
      await setDiscrepanciesToContext(filterParams);
    }
  };

  const dateFilter = async (date: string) => {
    const searchParams = new URLSearchParams(filters);
    if (date === null) {
      searchParams.delete('firstIngestionDate');
    } else {
      searchParams.set('firstIngestionDate', dashFormatDate(date));
    }
    const filterParams = searchParams.toString();
    setFilters(filterParams);
    setValues({ ...values, firstIngestionDate: date });
    await setDiscrepanciesToContext(filterParams);
  };

  const sort = async (property: any, order: any) => {
    setLoader(false);
    const searchParams = new URLSearchParams(filters);
    searchParams.set('sortingField', property);
    searchParams.set('sortType', order);
    const sortParams = searchParams.toString();
    setFilters(sortParams);
    try {
       const discrepanciesData = await getDiscrepancies(`?${sortParams}`);
       setLoader(true);
       dispatch({ type: 'set', discrepanciesData });
    } catch (e) {
       console.error(e);
      setError(HTTP_ERROR_MESSAGE)
       setLoader(true);
    }
  };

  const isProductDataMissing = (discrepancy: any): boolean =>
    ['NetWeight', 'GrossWeight', 'CostPrice', 'CountryOfOrigin', 'UKVATRate', 'RoIVATRate'].some(
      (v: string) => typeof discrepancy[v] === 'undefined'
    );

  const isCommodityChildrenProductDataMissing = (commodityChildren = []) =>
    commodityChildren.some((discrepancy) => isProductDataMissing(discrepancy));

  const isHSCodeMissing = (discrepancy: any): boolean => typeof discrepancy.HarmonisedSystemCode === 'undefined';

  const isCommodityChildrenHSCodeMissing = (commodityChildren = []) =>
    commodityChildren.some((discrepancy) => isHSCodeMissing(discrepancy));

  const SKUCell = ({ discrepancy }: any) => {
    return (
      <Fragment>
        {discrepancy.CommodityChildren && <DeviceHubIcon className={classes.icon} />}
        {(isProductDataMissing(discrepancy) ||
          isCommodityChildrenProductDataMissing(discrepancy.CommodityChildren)) && (
          <img src={pIcon} alt="P" className={classes.dataMissingIcon} />
        )}
        {(isHSCodeMissing(discrepancy) || isCommodityChildrenHSCodeMissing(discrepancy.CommodityChildren)) && (
          <img src={cIcon} alt="С" className={classes.dataMissingIcon} />
        )}
        {discrepancy.SKU}
      </Fragment>
    );
  };

  const discrepanciesList = state.discrepanciesList
    ? state.discrepanciesList.items.map((discrepancy: any) => {
        const itemSKU = discrepancy.SKU.toString();
        const handleClick = () => {
          props.history.push(`/resolve-discrepancy/${itemSKU}`);
        };
        discrepancy.firstIngestionDate = formatDate(discrepancy.firstIngestionDate);
        discrepancy.SKU = <SKUCell discrepancy={{ ...discrepancy }} />;
        return { data: objectFilter(discrepancy, rows, 'id'), handleClick };
      })
    : [];

  const MemoizedEnhancedTable = useMemo(
    () => (
      <Table className={classes.table} aria-labelledby="tableTitle">
        <EnhancedTableBody rows={discrepanciesList} cellsStyles={SKUClasses} />
      </Table>
    ),
    [state.discrepanciesList]
  );

  let errorMsg = <></>
  if (error!= ""){
     errorMsg = <StatusPopup text={error} type="ERROR" />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
    <Paper className={classes.root}>
      {errorMsg}
      <Table className={classes.table} aria-labelledby="tableTitle">
        <EnhancedTableHead rows={rows} sort={sort}>
          <Filters
            filter={filter}
            dateFilter={dateFilter}
            handleInputTyping={setInputValue}
            values={values}
            rows={rows}
          />
        </EnhancedTableHead>
      </Table>
      <div className={classes.tableWrapper}>
        {loader ? (
          typeof state.discrepanciesList.items !== 'undefined' && state.discrepanciesList.items.length > 0 ? (
            MemoizedEnhancedTable
          ) : (
            <Grid container className={classes.loader} justify="center">
              <Typography className={classes.emptyData} variant="h5">
                No Data
              </Typography>
            </Grid>
          )
        ) : (
          <Grid container className={classes.loader} justify="center">
            <CircularProgress disableShrink />
          </Grid>
        )}
      </div>
    </Paper>
    </MuiThemeProvider>
  );
});
