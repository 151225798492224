import React, { useContext, useEffect, forwardRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Radio,
  RadioGroup,
  Paper,
  Typography,
  FormControlLabel,
  CircularProgress,
  Theme
} from '@material-ui/core';
import { createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { URLS } from '../../constants';
import { editConsignment, getConsignmentById, getHandlers } from '../../services/api';
import { useUserHandlers } from '../../hooks/use-user-handlers';
import { HandlersContext } from '../../context/handlers';
import { useCreateConsignmentForm } from '../../hooks/use-create-consignment-form';
import { formatAlphanumericInput, isAllObjectValuesTrue, objectFilter } from '../../helpers';
import formFields from './consignment-form-config.json';
import { StatusPopup } from '../common/status-popup';
import { HTTP_ERROR_MESSAGE } from '../../constants';
import { customTheme } from '../main/materialStyles';

interface IEditConsignmentFormProps extends RouteComponentProps {
  consignmentId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0)
    },
    container: {
      padding: theme.spacing(3, 0, 0),
      width: theme.spacing(62),
      margin: '0 auto'
    },
    formInput: {
      marginTop: theme.spacing(4)
    },
    buttonsHolder: {
      margin: theme.spacing(4, -1.5)
    }
  })
);

export const EditConsignmentForm = withRouter((props: IEditConsignmentFormProps) => {
  const classes = useStyles();
  const { consignmentId } = props;
  const [loader, setLoader] = React.useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [error, setError] = React.useState('');
  const carriers = useUserHandlers();
  const origins = useUserHandlers();
  const ConsignmentsLink = forwardRef((linkProps: any, ref) => (
    <Link to={URLS.CONSIGNMENT_LIST} {...linkProps} ref={ref} />
  ));
  const { dispatchHandlers } = useContext(HandlersContext);
  const { values, setValues, initForm } = useCreateConsignmentForm();

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { name, value } = event.target;
    const formattedValue = formatAlphanumericInput(value);
    setValues((currentValues) => ({ ...currentValues, [name]: formattedValue }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoader(true);
    setError('');
    event.preventDefault();
    const { userDefinedId, vesselNumber } = values;
    try {
      await editConsignment(consignmentId, { userDefinedId, vesselNumber });
    } catch (e) {
      setError(HTTP_ERROR_MESSAGE);
      console.error(e);
      setLoader(false);
      return false;
    }
    props.history.push(URLS.CONSIGNMENT_LIST);
    return false;
  };

  useEffect(() => {
    (async () => {
      try {
        const body = await getHandlers();
        dispatchHandlers({ type: 'set', body });
      } catch (e) {
        setError(HTTP_ERROR_MESSAGE);
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const consignmentData = await getConsignmentById(consignmentId);
        setValues((value) => ({ ...value, ...consignmentData }));
      } catch (e) {
        setError(HTTP_ERROR_MESSAGE);
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    const fields = objectFilter(values, formFields, 'id');
    isAllObjectValuesTrue(fields) ? setFormTouched(true) : setFormTouched(false);
  }, [values]);

  const formButtons = () => {
    if (loader) {
      return (
        <Grid container spacing={4} justify="center" className={classes.buttonsHolder}>
          <CircularProgress disableShrink />
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={4} justify="center" className={classes.buttonsHolder}>
          <Grid item>
            <Button variant="contained" size="large" component={ConsignmentsLink}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" disabled={!formTouched} color="primary" size="large" type="submit">
              Apply
            </Button>
          </Grid>
        </Grid>
      );
    }
  };
  let errorMsg = <></>;
  if (error != '') {
    errorMsg = <StatusPopup text={error} type="ERROR" />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Paper className={classes.root}>
        {errorMsg}
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className={classes.container}>
            <FormControl fullWidth variant="outlined" className={classes.formInput}>
              <InputLabel htmlFor="handler">Carrier</InputLabel>
              <Select
                fullWidth
                disabled
                value={values.handler}
                input={<OutlinedInput labelWidth={50} name="handler" id="handler" />}
              >
                {carriers.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" className={classes.formInput}>
              <InputLabel htmlFor="origin">Destination</InputLabel>
              <Select
                fullWidth
                disabled
                value={values.origin}
                input={<OutlinedInput labelWidth={80} name="origin" id="origin" />}
              >
                {origins.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formInput}>
              <Typography>Purpose</Typography>
              <RadioGroup name="purpose" value={values.purpose}>
                <FormControlLabel value="OUTBOUND" disabled control={<Radio color="primary" />} label="Dispatch" />
                <FormControlLabel value="INBOUND" disabled control={<Radio color="primary" />} label="Returns" />
              </RadioGroup>
            </FormControl>
            <TextField
              fullWidth
              onChange={handleTextFieldChange}
              value={values.userDefinedId}
              className={classes.formInput}
              helperText="Trailer Plate, Pallet ID"
              label="Trailer number"
              variant="outlined"
              name="userDefinedId"
              inputProps={{
                maxLength: 10
              }}
            />
            <TextField
              fullWidth
              onChange={handleTextFieldChange}
              value={values.vesselNumber}
              className={classes.formInput}
              label="Vessel Number"
              variant="outlined"
              name="vesselNumber"
            />
            {formButtons()}
          </div>
        </form>
      </Paper>
    </MuiThemeProvider>
  );
});
