import React, { Fragment, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Grid, Paper, ButtonBase, Typography } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { URLS } from '../../constants';
import { ScanParcel } from './components/scan-parcel';
import { ConsignmentInfo } from '../consignment-info';
import { IConsignmentEntity } from '../../models/consignment';
import { customTheme } from "../main/materialStyles";

interface Props {
  consignment: IConsignmentEntity;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeading: {
      fontSize: 26,
      fontWeight: 100,
      margin: theme.spacing(1, 0, 3)
    },
    root: {
      flexGrow: 1
    },
    scanBox: {
      padding: theme.spacing(4),
      height: '100%'
    },
    accepted: {
      backgroundColor: '#a7cb19',
      color: '#fff',
      textAlign: 'center',
      padding: '34px 0',
      height: '200px'
    },
    discrepancies: {
      backgroundColor: '#e47536',
      color: '#fff',
      textAlign: 'center',
      padding: '34px 0',
      height: '200px'
    },
    parcelCount: {
      margin: '30px 0 0',
      color: '#fff',
      fontSize: 36
    },
    btnBack: {
      textTransform: 'none',
      margin: theme.spacing(0, 0, 0, 0.5)
    }
  })
);

export const AddParcel = (props: Props) => {
  const { consignment }: { consignment: IConsignmentEntity } = props;
  const classes = useStyles();
  const ConsignmentsListLink = forwardRef((linkProps: any, ref) => (
    <Link to={URLS.CONSIGNMENT_LIST} {...linkProps} ref={ref} />
  ));

  return (
    <MuiThemeProvider theme={customTheme}>
    <Fragment>
      <ButtonBase component={ConsignmentsListLink} className={classes.btnBack}>
        <ChevronLeftIcon />
        <Typography id="back-to-list">Back to list</Typography>
      </ButtonBase>
      <Typography className={classes.pageHeading}>Add Parcels</Typography>
      <ConsignmentInfo {...consignment} />
      <Grid container spacing={10} className={classes.root} direction="row" alignItems="stretch">
        <Grid item xs={12}>
          <Paper className={classes.scanBox}>
            <Typography>Please scan UPI or enter it in the text field below</Typography>
            <ScanParcel consignmentId={consignment.consignmentId} />
          </Paper>
        </Grid>
        {
          // @todo: Uncomment for counting scanned parcels/discrepancies
          /*<Grid item xs={2}>
          <Paper className={classes.accepted}>
            SCANNED
            <Typography className={classes.parcelCount}>0</Typography>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.discrepancies}>
            DISCREPANCIES
            <Typography className={classes.parcelCount}>0</Typography>
          </Paper>
        </Grid>*/
        }
      </Grid>
    </Fragment>
    </MuiThemeProvider>
  );
};
