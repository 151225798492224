export interface IHandler {
  identifier: string;
  GSI1_PK: string;
  GSI1_SK: string;
  PK: string;
  SK: string;
  address_line_1: string;
  address_line_2: string;
  company: string;
  email: string;
  origin: string;
  packaging: string;
  postcode_city: string;
  prefix: string;
  purpose: string;
  telephone: string;
  displayName: string;
}

export const createInitHandler = () => ({
  handlers: [
    {
      identifier: '',
      GSI1_PK: '',
      GSI1_SK: '',
      PK: '',
      SK: '',
      address_line_1: '',
      address_line_2: '',
      company: '',
      email: '',
      origin: '',
      packaging: '',
      postcode_city: '',
      prefix: '',
      purpose: '',
      telephone: '',
      displayName: ''
    }
  ]
});
