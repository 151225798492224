import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeading } from '../../components/common/page-heading';
import { ResolveDiscrepancyForm } from '../../components/resolve-discrepancy-form';

interface MatchParams {
  discrepancyId: string;
}

export const ResolveDiscrepancyPage = ({ match }: RouteComponentProps<MatchParams>) => {
  const { discrepancyId } = match.params;

  return (
    <Fragment>
      <PageHeading>Resolve discrepancy</PageHeading>
      <ResolveDiscrepancyForm discrepancyId={discrepancyId} />
    </Fragment>
  );
};
