import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { commonStyles } from '../../styles';
import { customTheme } from '../../components/main/materialStyles';
import { PageHeading } from '../../components/common/page-heading';
import { EmptyPopup, handleAPIException, PopupDefinition, StatusPopup } from '../../components/common/status-popup';
import { getUsers, updateUser } from '../../services/api';
import {
  DataGrid,
  GridAlignment,
  GridColDef,
  GridColumnsToolbarButton,
  GridFilterModelParams,
  GridFilterToolbarButton,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarExport,
  useGridSlotComponentProps
} from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import CheckIcon from '@material-ui/icons/Check';
import { Button, CircularProgress, Modal, Paper } from '@material-ui/core';
import { NewUserPage } from './newUserPage';
import { EditUserForm } from './editUserForm';

export const UserAdminPage = () => {
  const [popup, setPopup] = React.useState(EmptyPopup);
  const [loading, setLoading] = useState(true);
  const classes = commonStyles();
  const [usersAndGroups, setUsersAndGroups] = useState({ users: [], groups: [] });
  const [selectedId, setSelectedId]: any = useState('');
  const updateUsersList = async () => {
    try {
      const body = await getUsers();
      setUsersAndGroups(body);
    } catch (e) {
      handleAPIException(e, setPopup);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setPopup(EmptyPopup);
      updateUsersList();
    })();
  }, []);

  const checkboxCell = (params: any) => (params.value ? <CheckIcon style={{ width: '100%' }} /> : <div />);

  const createColumns = () => {
    const columns: GridColDef[] = [
      { field: 'username', headerName: 'Username', width: 200 },
      { field: 'enabled', headerName: 'Enabled', renderCell: checkboxCell },
      { field: 'email', headerName: 'Email', width: 300 },
      { field: 'user_status', headerName: 'User Status', flex: 1 },
      ...usersAndGroups.groups.map((group) => {
        return {
          field: group,
          headerName: group,
          width: 170,
          renderCell: checkboxCell,
          align: 'center' as GridAlignment,
          headerAlign: 'center' as GridAlignment
        };
      })
    ];
    return columns;
  };

  const createRows = () => {
    return usersAndGroups.users.map((user, index) => {
      return {
        id: index,
        ...(user as object),
        // @ts-ignore
        email_verified: user.email_verified === 'true',
        // @ts-ignore
        ...(user.groups as object)
      };
    });
  };

  function CustomPagination() {
    const { state, apiRef } = useGridSlotComponentProps();

    return (
      <Pagination
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  const handleFormControlChange = (event: GridRowParams) => {
    setSelectedId(event.id);
  };

  const changeUserStateHandle = async () => {
    if (selectedId !== '') {
      setLoading(true);
      const newUserData = { ...usersAndGroups.users };
      const selectedUser: any = newUserData[selectedId];
      selectedUser.enabled = !selectedUser.enabled;
      try {
        const user = { ...selectedUser };
        await updateUser(user);
        setLoading(false);
        setPopup(
          PopupDefinition.create(
            'SUCCESS',
            'User "' + selectedUser.username + '" ' + (selectedUser.enabled ? 'enabled' : 'disabled') + ' successfully'
          )
        );
        await updateUsersList();
      } catch (e) {
        handleAPIException(e, setPopup);
      } finally {
        setLoading(false);
      }
    }
  };

  let popupMessage = <></>;
  if (popup.type) {
    // @ts-ignore
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }
  return (
    <MuiThemeProvider theme={customTheme}>
      <Modal open={loading}>
        <Grid container className={classes.loader} justify="center">
          <CircularProgress disableShrink />
        </Grid>
      </Modal>
      <Grid container>
        <Grid item xs={9}>
          <PageHeading>User Management</PageHeading>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" className={classes.button} onClick={changeUserStateHandle}>
            Enable/Disable
          </Button>
        </Grid>
        <EditUserForm userInfo={usersAndGroups.users[selectedId]} onUserChanged={updateUsersList} />
        <NewUserPage users={usersAndGroups.users} userGroups={usersAndGroups.groups} onUserCreated={updateUsersList} />
      </Grid>
      {popupMessage}
      <Paper>
        <DataGrid
          className={classes.usersWrapper}
          rows={createRows()}
          density="compact"
          columns={createColumns()}
          pageSize={17}
          autoHeight
          disableColumnMenu
          pagination
          onFilterModelChange={(params: GridFilterModelParams) => {
            params.api.setPage(0);
          }}
          onRowClick={handleFormControlChange}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <GridFilterToolbarButton />
                <GridColumnsToolbarButton />
                <GridToolbarExport />
              </GridToolbarContainer>
            ),
            Pagination: CustomPagination
          }}
        />
      </Paper>
    </MuiThemeProvider>
  );
};
