import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { commonStyles } from '../../styles';
import { customTheme } from '../main/materialStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';

export interface ISessionTimeoutDialogProps {
  open: boolean;
  countdown: number;
  onLogout: () => void;
  onContinue: () => void;
}

export const SessionTimeoutDialog = (props: ISessionTimeoutDialogProps) => {
  const classes = commonStyles();
  return (
    <MuiThemeProvider theme={customTheme}>
      <Dialog open={props.open}>
        <DialogTitle>Session Timeout</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            The current session is about to expire in <span>{props.countdown}</span> seconds.
          </Typography>
          <Typography variant="body2">Would you like to continue the session?</Typography>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={props.onLogout} color="primary" variant="outlined">
            Logout
          </Button>
          <Button className={classes.button} onClick={props.onContinue} color="primary" variant="contained">
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};
