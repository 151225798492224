
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from "@material-ui/core";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import '../styles/customCalendar.css'
import moment from "moment"; // theme css file

interface dateInfo{
  dateFilter: any;
}

export const CustomCalendar = (props: dateInfo) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const {dateFilter} = props
  const [open, setOpen] = React.useState(false);
  const [inputText, setInputText] = React.useState(prettyDate(state[0].startDate, state[0].endDate));
  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClear= () => {
    setInputText("All dates")
    dateFilter(null)
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setInputText(prettyDate(state[0].startDate, state[0].endDate))
    dateFilter([state[0].startDate, state[0].endDate])
    setOpen(false);
  };

  const handleOnChange = (ranges: any) =>{
    setState([{
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection"
    }])
  }

  function prettyDate(date1: Date, date2: Date){
    return `${moment(date1).format('DD/MM/YYYY')} - ${moment(date2).format('DD/MM/YYYY')}`
  }

  return (
    <Grid>
      <TextField
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        value={inputText}
      >
      </TextField>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{"Calendar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Choose Date
          </DialogContentText>
          <div>
            <DateRangePicker
              onChange={handleOnChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />;
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleClear} color="primary">
            Clear
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
)
}
