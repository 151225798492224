import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { AddCircle as AddCircleIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';

interface ISearchResultItem {
  EWS: string;
  QPL: string;
  SKU: {
    ItemDescription: string;
    SKU: string;
  };
  selected?: boolean;
}

interface ISearchResultsListProps {
  items: ISearchResultItem[];
  parcelItems: any;
  onSelect(item: any): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    addIcon: {
      color: '#528618'
    },
    row: {
      cursor: 'pointer'
    }
  })
);

export const SearchResultsList = (props: ISearchResultsListProps) => {
  const classes = useStyles();
  const { parcelItems, items, onSelect } = props;

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelectItem = (item: ISearchResultItem) => {
    const { EWS, QPL } = item;
    const existingParcelItem = parcelItems.find(
      (v: any) => v.EWS.toString() === EWS.toString() && v.QPL.toString() === QPL.toString()
    );

    if (!existingParcelItem) {
      onSelect(item);
      setSelectedItems(Array.from(new Set([...selectedItems, EWS])));
    }
  };

  const isSelected = (id: string) => selectedItems.includes(id);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>SKU</TableCell>
          <TableCell>Description</TableCell>
          <TableCell padding="default" />
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((row: ISearchResultItem, i) => (
          <TableRow key={i} onClick={() => handleSelectItem(row)} hover selected={!!isSelected(row.EWS)}>
            <TableCell>{row.SKU.SKU}</TableCell>
            <TableCell>{row.SKU.ItemDescription}</TableCell>
            <TableCell padding="checkbox">
              {isSelected(row.EWS) ? (
                <CheckCircleIcon className={classes.addIcon} />
              ) : (
                <AddCircleIcon className={classes.addIcon} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
