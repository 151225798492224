import React, { useEffect, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useAuthState } from './authentication';
import { Auth } from 'aws-amplify';
import { SessionTimeoutDialog } from '../components/session-timeout';
import { AuthState } from '@aws-amplify/ui-components';
import { getUserSessionSettings } from '../services/api';

const SessionTimeoutConfigurationDefaults = {
  debounce: 250,
  timeoutMinutes: 30,
  dialogTimeoutSeconds: 30
};

class SessionTimeoutConfiguration {
  timeoutMinutes: number;
  dialogTimeoutSeconds: number;

  constructor(timeoutMinutes?: number, dialogTimeoutSeconds?: number) {
    this.timeoutMinutes = timeoutMinutes ? timeoutMinutes : SessionTimeoutConfigurationDefaults.timeoutMinutes;
    this.dialogTimeoutSeconds = dialogTimeoutSeconds
      ? dialogTimeoutSeconds
      : SessionTimeoutConfigurationDefaults.dialogTimeoutSeconds;
  }

  getTimeoutMilliseconds() {
    return 1000 * 60 * this.timeoutMinutes - 1000 * this.dialogTimeoutSeconds;
  }
}

export function SessionTimeout() {
  const idleTimer = useRef(null);
  const authState = useAuthState();

  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  // tslint:disable-next-line:no-empty
  const [countDownInterval, setCountDownInterval] = useState(setInterval(() => {}));
  const [sessionTimeout, setSessionTimeout] = useState(new SessionTimeoutConfiguration().getTimeoutMilliseconds());
  const [dialogTimeout, setDialogTimeout] = useState(new SessionTimeoutConfiguration().dialogTimeoutSeconds);

  useEffect(() => {
    (async () => {
      try {
        if (authState === AuthState.SignedIn) {
          const body = await getUserSessionSettings();
          const sessionTimeoutConfig = new SessionTimeoutConfiguration(body.timeoutMinutes, body.dialogTimeoutSeconds);

          setSessionTimeout(sessionTimeoutConfig.getTimeoutMilliseconds());
          setDialogTimeout(sessionTimeoutConfig.dialogTimeoutSeconds);
        }
      } catch (e) {
        setSessionTimeout(new SessionTimeoutConfiguration().getTimeoutMilliseconds());
        setDialogTimeout(new SessionTimeoutConfiguration().dialogTimeoutSeconds);
      }
    })();
  }, [authState]);

  const clearSessionTimeout = () => {
    clearTimeout(sessionTimeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countDownInterval);
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const handleLogout = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    Auth.signOut();
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    if (authState === AuthState.SignedIn && !timeoutModalOpen) {
      setTimeout(() => {
        let countDown = dialogTimeout;
        setTimeoutCountdown(countDown);
        setTimeoutModalOpen(true);
        setCountDownInterval(
          setInterval(() => {
            if (countDown > 0) {
              setTimeoutCountdown(--countDown);
            } else {
              handleLogout();
            }
          }, 1000)
        );
      }, 0);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={SessionTimeoutConfigurationDefaults.debounce}
        timeout={sessionTimeout}
      />
      <SessionTimeoutDialog
        open={timeoutModalOpen}
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={handleLogout}
      />
    </>
  );
}
