import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AddParcel } from '../../components/add-parcel';
import { AddParcelToReturn } from '../../components/add-parcel-to-return';
import { getConsignmentById } from '../../services/api';
import { PURPOSES } from '../../constants';
import { IConsignmentEntity, createEmptyConsignment } from '../../models/consignment';

interface MatchParams {
  consignmentId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export const AddParcelToConsignmentPage = ({ match }: Props) => {
  const { consignmentId } = match.params;

  const [consignment, setConsignmentData] = useState<IConsignmentEntity>(createEmptyConsignment());

  useEffect(() => {
    (async () => {
      const consignmentData = await getConsignmentById(consignmentId);
      setConsignmentData(consignmentData);
    })();
  }, []);

  return (
    {
      [PURPOSES.OUTBOUND]: <AddParcel consignment={consignment} />,
      [PURPOSES.INBOUND]: <AddParcelToReturn consignment={consignment} />
    }[consignment.purpose] || null
  );
};
