import React, { Fragment, useContext, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { PageHeading } from '../../components/common/page-heading';
import { HandlersContext } from '../../context/handlers';
import { StatusPopup } from '../../components/common/status-popup';
import { getHandlers } from '../../services/api';
import withHandlersProvider from '../../containers/with-handler-provider';
import { HTTP_ERROR_MESSAGE } from '../../constants';
import { customTheme } from '../../components/main/materialStyles';
import { ParcelsProvider } from '../../context/parcels';
import { ParcelsList } from '../../components/parcels-list';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
  consignmentId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export const ParcelsPage = withHandlersProvider((props: Props) => {
  const { consignmentId } = props.match.params;
  const { dispatchHandlers } = useContext(HandlersContext);
  const [error, setError] = React.useState('');

  useEffect(() => {
    (async () => {
      try {
        const body = await getHandlers();
        dispatchHandlers({ type: 'set', body });
      } catch (e) {
        console.error(e);
        setError(HTTP_ERROR_MESSAGE);
      }
    })();
  }, []);

  let errorMsg = <></>;
  if (error !== '') {
    errorMsg = <StatusPopup text={error} type="ERROR" />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Fragment>
        {errorMsg}
        <Grid container>
          <Grid item>
            <PageHeading>Parcels</PageHeading>
          </Grid>
        </Grid>
        <ParcelsProvider>
          <ParcelsList consignmentId={consignmentId} />
        </ParcelsProvider>
      </Fragment>
    </MuiThemeProvider>
  );
});
