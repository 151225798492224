import React, { Fragment } from 'react';
import { PageHeading } from '../../components/common/page-heading';
import { HandlersProvider } from '../../context/handlers';
import { FeatureToggleComponent } from '../../components/feature-toggle-component';

export const FeatureTogglePage = () => {
  return (
    <Fragment>
      <PageHeading>Features</PageHeading>
      <HandlersProvider>
        <FeatureToggleComponent />
      </HandlersProvider>
    </Fragment>
  );
};
