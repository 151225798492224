import React, { useReducer } from 'react';
import { IHandler, createInitHandler } from '../models/handler';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'set':
      return { ...state, handlers: action.body };
    default:
      return;
  }
};

const handlersEntity: IHandler | any = createInitHandler();

const HandlersContext = React.createContext(handlersEntity);

function HandlersProvider(props: any) {
  const [handlersState, dispatchHandlers] = useReducer(reducer, handlersEntity);
  return (
    <HandlersContext.Provider value={{ handlersState, dispatchHandlers }}>{props.children}</HandlersContext.Provider>
  );
}

export { HandlersContext, HandlersProvider };
