import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { reverse } from 'named-urls';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { HTTP_ERROR_MESSAGE, URLS } from '../../constants';
import { createConsignment, getHandlers } from '../../services/api';
import { useShippingLines, useUserHandlers, useUserHandlersInfo } from '../../hooks/use-user-handlers';
import { HandlersContext } from '../../context/handlers';
import { useCreateConsignmentForm } from '../../hooks/use-create-consignment-form';
import { formatAlphanumericInput, isAllObjectValuesTrue } from '../../helpers';
import { EmptyPopup, PopupDefinition, StatusPopup } from '../common/status-popup';
import { customTheme } from '../main/materialStyles';
import moment, { Moment } from 'moment/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0)
    },
    container: {
      padding: theme.spacing(3, 0, 0),
      width: theme.spacing(77),
      margin: '0 auto'
    },
    formInput: {
      marginTop: theme.spacing(4)
    },
    buttonsHolder: {
      margin: theme.spacing(4, -1.5)
    },
    toggleButton: {
      '&.Mui-selected': {
        backgroundColor: 'rgb(233, 71, 145)',
        color: 'rgb(255, 255, 255)'
      },
      '&:hover': {
        backgroundColor: 'rgb(233, 100, 145)',
        color: 'rgb(255, 255, 255)'
      },
      '&.Mui-selected:hover': {
        backgroundColor: 'rgb(233, 71, 145)',
        color: 'rgb(255, 255, 255)'
      }
    }
  })
);

export const CreateConsignmentForm = withRouter((props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = React.useState(EmptyPopup);
  const [formTouched, setFormTouched] = useState(false);
  const carriers = useUserHandlers();
  const [originsList, setOriginsList] = React.useState<object[]>([]);
  const handlersInfo = useUserHandlersInfo();
  const shippingLines = useShippingLines();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedTime, setSelectedTime] = useState(moment());
  const [sailingTime, setSailingTime] = useState('');
  const [trailerType, setTrailerType] = useState('');
  const [filteredShippingLines, setFilteredShippingLines] = useState([{}]);
  const [hideDateTimePicker, setHideDateTimePicker] = useState(false);
  const onDateChange = (date: MaterialUiPickersDate) => {
    if (date !== null) {
      setSelectedDate(date);
    }
  };
  const onTimeChange = (time: MaterialUiPickersDate) => {
    if (time !== null) {
      setSelectedTime(time);
    }
  };
  const onSailingTimeChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setSailingTime(value);
    setTrailerType('Standard');
    setPopup(EmptyPopup);
    if (value !== null) {
      if (value.includes('NDD')) {
        setTrailerType('NDD');
        setPopup(PopupDefinition.create('AMBER', 'You have selected an NDD trailer time.'));
      }
      if (value.includes('MIXED')) {
        setTrailerType('Mixed');
        setPopup(PopupDefinition.create('AMBER', 'You have selected an MIXED trailer time.'));
      }
      const splitted = value.split('-', 2);
      value = splitted[0];
      const hhmm = value.match(/.{2}/g);
      if (hhmm !== null && hhmm.length === 2) {
        const time = moment().hour(Number(hhmm[0])).minute(Number(hhmm[1]));
        setSelectedTime(time);
      }
    }
  };

  const ConsignmentsLink = forwardRef((linkProps: any, ref) => (
    <Link to={URLS.CONSIGNMENT_LIST} {...linkProps} ref={ref} />
  ));
  const { dispatchHandlers } = useContext(HandlersContext);
  const { values, setValues, initForm } = useCreateConsignmentForm();
  const [shippingLine, setShippingLine] = useState('');
  const skygateFastwayShippingLine = shippingLines.filter(
    (option: any) => option.name === 'STENA' || option.name === 'SEATRUCK'
  );

  function checkOriginValues(handler: string, origin: any) {
    const origValues: any[] = [];
    if (handler === 'DHL') {
      for (const v of Array.from(origin.split(','))) {
        origValues.push({ label: v, value: v });
      }
    } else {
      origValues.push({ label: origin, value: origin });
    }
    setOriginsList(origValues);
    return origValues;
  }

  function resetSelectedDateIfInvalid() {
    if (!selectedDate.isValid()) {
      setSelectedDate(moment());
    }
  }

  function resetSelectedTimeIfInvalid() {
    if (!selectedTime.isValid()) {
      setSelectedTime(moment());
    }
  }

  function setDateTimePickers(handler: string) {
    if (handler === 'SKYGATE-FASTWAY') {
      resetSelectedDateIfInvalid();
      setSelectedTime(moment.invalid());
      setHideDateTimePicker(true);
    } else {
      resetSelectedDateIfInvalid();
      resetSelectedTimeIfInvalid();
      setSailingTime('');
      setTrailerType('Standard');
      setHideDateTimePicker(false);
    }
  }
  const handleSelectFieldChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    setDateTimePickers(event.target.value);
    setOrigins(event.target.value);
    filterShippingLines(event.target.value);
  };

  const originSelectFieldChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    const selectedOrigin = event.target.value;
    setValues((value) => ({ ...value, origin: selectedOrigin }));
  };

  const filterShippingLines = (handler: string) => {
    let lines;
    if (handler === 'SKYGATE-FASTWAY') {
      lines = skygateFastwayShippingLine;
    }
    setFilteredShippingLines(lines);
  };

  const populateShippingLines = () => {
    let lines = shippingLines;
    if (values.handler === 'SKYGATE-FASTWAY') {
      lines = filteredShippingLines;
      if (lines) {
        lines = skygateFastwayShippingLine;
      }
    }
    if (lines) {
      return lines.map((option: any) => (
        <MenuItem value={option.name + '_' + option.nationality} key={option.name + '_' + option.nationality}>
          {option.name}
        </MenuItem>
      ));
    }
  };

  const handleShippingLineChanged = (event: React.ChangeEvent<any>) => {
    event.persist();
    const shipLine = event.target.value;
    const [line, nationality] = shipLine.split('_');
    const port = getPort(shipLine);
    if (port) {
      setValues((currentValues) => ({ ...currentValues, shippingLine: line, nationality, port }));
    }
    setShippingLine(shipLine);
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { name, value } = event.target;
    const formattedValue = formatAlphanumericInput(value);
    setValues((currentValues) => ({ ...currentValues, [name]: formattedValue }));
  };

  const getPort = (shippingLineNationality: string) => {
    const [lineName, nationality] = shippingLineNationality.split('_');
    if (!values.handler) {
      return null;
    }
    for (const line of shippingLines) {
      if (line.name === lineName && line.nationality === nationality) {
        return line.port[values.handler];
      }
    }
    return null;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoader(true);
    setPopup(EmptyPopup);
    event.preventDefault();
    try {
      const sendValues = { ...values };
      const consignment = await createConsignment(sendValues);
      const { consignmentId } = consignment;
      props.history.push(reverse(URLS.ADD_PARCELS_TO_CONSIGNMENT, { consignmentId }));
      return false;
    } catch (e) {
      console.error(e);
      setPopup(PopupDefinition.create('ERROR', HTTP_ERROR_MESSAGE));
      setLoader(false);
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const body = await getHandlers();
        dispatchHandlers({ type: 'set', body });
      } catch (e) {
        console.error(e);
        setPopup(PopupDefinition.create('ERROR', HTTP_ERROR_MESSAGE));
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedDate.isValid() && selectedTime.isValid()) {
      const dateTime = selectedDate.format('YYYYMMDD') + selectedTime.format('HHmm');
      console.log(dateTime);
      setValues((currentValues) => ({ ...currentValues, departureDate: dateTime }));
    } else {
      setValues((currentValues) => ({ ...currentValues, departureDate: '' }));
    }
    setValues((currentValues) => ({ ...currentValues, trailerType }));
  }, [selectedDate, selectedTime]);

  const setOrigins = (initValue: string) => {
    const { handler, origin: originInit, purpose } = initForm(initValue);
    let origin = originInit;
    const origValues = checkOriginValues(handler, origin);
    origin = origValues[0].label;
    setValues((value: any) => ({ ...value, handler, origin, purpose }));
    setOriginsList(origValues);
  };

  useEffect(() => {
    if (carriers.length === 1) {
      setDateTimePickers(carriers[0].value);
      setOrigins(carriers[0].value);
    }
  }, [carriers.length]);

  useEffect(() => {
    isAllObjectValuesTrue(values) ? setFormTouched(true) : setFormTouched(false);
  }, [values]);

  const formButtons = () => {
    if (loader) {
      return (
        <Grid container spacing={4} justify="center" className={classes.buttonsHolder}>
          <CircularProgress disableShrink />
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={4} justify="center" className={classes.buttonsHolder}>
          <Grid item>
            <Button variant="contained" size="large" component={ConsignmentsLink}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <MuiThemeProvider theme={customTheme}>
              <Button
                id="create-consignment-button"
                variant="contained"
                disabled={!formTouched}
                color="primary"
                size="large"
                type="submit"
              >
                Create
              </Button>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      );
    }
  };
  let popupMessage = <></>;
  if (popup.type && popup.message) {
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Paper className={classes.root}>
        {popupMessage}
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className={classes.container}>
            <FormControl fullWidth variant="outlined" className={classes.formInput}>
              <InputLabel htmlFor="handler">Carrier</InputLabel>
              <Select
                fullWidth
                value={values.handler}
                onChange={handleSelectFieldChange}
                input={<OutlinedInput labelWidth={50} name="handler" id="handler" />}
              >
                {carriers.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" className={classes.formInput}>
              <InputLabel htmlFor="origin">Destination</InputLabel>
              <Select
                fullWidth
                value={values.origin}
                onChange={originSelectFieldChange}
                input={<OutlinedInput labelWidth={80} name="origin" id="origin" />}
              >
                {originsList.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {handlersInfo[option.label]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" className={classes.formInput}>
              <InputLabel htmlFor="ship_line">Shipping Line</InputLabel>
              <Select
                fullWidth
                value={shippingLine}
                onChange={handleShippingLineChanged}
                input={<OutlinedInput labelWidth={80} name="ship_line" id="ship_line" />}
              >
                {populateShippingLines()}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              disabled
              variant="outlined"
              className={classes.formInput}
              value={shippingLine.split('_')[1] || 'Select Shipping Line'}
              label="Nationality"
            />
            <TextField
              fullWidth
              disabled
              variant="outlined"
              className={classes.formInput}
              value={getPort(shippingLine) || 'Select Shipping Line and Carrier'}
              label="Port"
            />
            <FormControl variant="outlined" className={classes.formInput}>
              <Typography>Purpose</Typography>
              <RadioGroup name="purpose" value={values.purpose}>
                <FormControlLabel value="OUTBOUND" disabled control={<Radio color="primary" />} label="Dispatch" />
                <FormControlLabel value="INBOUND" disabled control={<Radio color="primary" />} label="Returns" />
              </RadioGroup>
            </FormControl>
            <Box hidden={hideDateTimePicker} alignContent="center">
              <Typography align="center">Departure Date and Time:</Typography>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                alignContent="center"
                className={classes.formInput}
              >
                <Grid item xs={6}>
                  <DatePicker
                    onChange={(value) => onDateChange(value)}
                    value={selectedDate}
                    variant="static"
                    orientation="portrait"
                    minDate={moment().add(-7, 'days')}
                    maxDate={moment().add(14, 'days')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    ampm={false}
                    value={selectedTime}
                    onChange={(value) => onTimeChange(value)}
                    variant="static"
                    orientation="portrait"
                    minutesStep={5}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box hidden={!hideDateTimePicker} alignContent="center">
              <Typography align="center">Departure Date and Time from Port:</Typography>
              <Grid container direction="row" justify="center" alignItems="center" className={classes.formInput}>
                <Grid item xs={6}>
                  <DatePicker
                    onChange={(value) => onDateChange(value)}
                    value={selectedDate}
                    variant="static"
                    orientation="portrait"
                    minDate={moment().add(-7, 'days')}
                    maxDate={moment().add(14, 'days')}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <Box justifyContent="center" alignItems="center" display="flex">
                    <ToggleButtonGroup
                      color="primary"
                      defaultValue=""
                      exclusive
                      orientation="vertical"
                      value={sailingTime}
                      size="large"
                      onChange={onSailingTimeChange}
                    >
                      <ToggleButton value="1445-MIXED" className={classes.toggleButton}>
                        14:45 Sailing
                        <br />
                        MIXED DELIVERY
                      </ToggleButton>
                      <ToggleButton value="0215" className={classes.toggleButton}>
                        02:15 Sailing
                        <br />
                        STANDARD DELIVERY
                      </ToggleButton>
                      <ToggleButton value="2030" className={classes.toggleButton}>
                        20:30 Sailing
                        <br />
                        STANDARD DELIVERY
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              onChange={handleTextFieldChange}
              className={classes.formInput}
              label="Vessel Number"
              name="vesselNumber"
            />
            <TextField
              fullWidth
              onChange={handleTextFieldChange}
              value={values.userDefinedId}
              className={classes.formInput}
              helperText="Trailer Plate, Pallet ID"
              label="Trailer Number"
              variant="outlined"
              name="userDefinedId"
              id="userDefinedId"
              inputProps={{
                maxLength: 10
              }}
            />
            {formButtons()}
          </div>
        </form>
      </Paper>
    </MuiThemeProvider>
  );
});
