import {
  AddParcelToConsignmentPage,
  AdminPage,
  ConsignmentsPage,
  CreateConsignmentPage,
  DiscrepanciesPage,
  EditConsignmentPage,
  ParcelsPage,
  ResolveDiscrepancyPage,
  UserAdminPage,
  EditVesselNumberPage
} from '../pages';
import React, { Fragment } from 'react';
import { Route } from 'react-router';
import Helmet from 'react-helmet';
import { URLS, USER_GROUPS } from '../constants';
import { useUserGroups } from '../hooks/use-user-handlers';
import { ScanDataLogPage } from '../pages/scan-data-log';
import { FeatureTogglePage } from '../pages/feature-toggle';
import { isUserAuthorised } from '../helpers';

interface AppRoute {
  path: string;
  component: React.ComponentType<any>;
  routes?: AppRoute[];
  exact?: boolean;
  title?: string;
}

export const routes = [
  {
    path: '/',
    exact: true,
    component: ConsignmentsPage,
    title: 'Consignments'
  },
  {
    path: URLS.CREATE_CONSIGNMENT,
    component: CreateConsignmentPage,
    title: 'New Consignment'
  },
  {
    path: URLS.CONSIGNMENT_LIST,
    exact: true,
    component: ConsignmentsPage,
    title: 'Consignments'
  },
  {
    path: URLS.PARCELS_LIST,
    component: ParcelsPage,
    title: 'Parcels'
  },
  {
    path: URLS.ADD_PARCELS_TO_CONSIGNMENT,
    component: AddParcelToConsignmentPage,
    title: 'Add Parcels'
  },
  {
    path: URLS.EDIT_CONSIGNMENT,
    component: EditConsignmentPage,
    title: 'Edit Consignment'
  },
  {
    path: URLS.EDIT_VESSEL_NUMER,
    component: EditVesselNumberPage,
    title: 'Edit Vessel Number'
  }
];

const RouteWithSubRoutes = (route: AppRoute) => (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props) => (
      <Fragment>
        <Helmet title={route.title} />
        <route.component {...props} routes={route.routes} />
      </Fragment>
    )}
  />
);

export const Routes = () => {
  const groups = useUserGroups();
  const rts = [...routes];
  if (groups) {
    if (isUserAuthorised(groups, USER_GROUPS.DEV)) {
      rts.push({
        path: URLS.FEATURES,
        component: FeatureTogglePage,
        title: 'Features'
      });
    }
    if (isUserAuthorised(groups, USER_GROUPS.ADMIN)) {
      rts.push({
        path: URLS.ADMIN,
        component: AdminPage,
        title: 'Admin'
      });
    }
    if (isUserAuthorised(groups, USER_GROUPS.USER_ADMIN)) {
      rts.push({
        path: URLS.USER_ADMIN,
        component: UserAdminPage,
        title: 'Users Admin'
      });
    }
    if (isUserAuthorised(groups, USER_GROUPS.DISCREPANCY)) {
      rts.push(
        {
          path: URLS.DISCREPANCIES_LIST,
          component: DiscrepanciesPage,
          title: 'Discrepancies'
        },
        {
          exact: true,
          path: URLS.RESOLVE_DISCREPANCY,
          component: ResolveDiscrepancyPage,
          title: 'Resolve Discrepancy'
        }
      );
    }
    if (isUserAuthorised(groups, USER_GROUPS.SCAN_DATA_LOG)) {
      rts.push({
        path: URLS.SCAN_DATA_LOG,
        component: ScanDataLogPage,
        title: 'Scan Data Log'
      });
    }
  }

  return (
    <Fragment>
      {rts.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Fragment>
  );
};
