import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { ArrowUpward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
// import rows from '../../consignments-list/consignments-table-config.json';

type Order = 'asc' | 'desc' | undefined;
interface IParams {
  defaultOrder: Order;
  defaultSortColumn: string;
  sortableColumns: any;
}

const useStyles = makeStyles({
  sortLabel: {
    cursor: 'default'
  },
  cell: {
    position: 'sticky',
    top: 55,
    backgroundColor: '#fff',
    zIndex: 10
  }
});

export const EnhancedTableHead = (props: { rows: any; sort?: any; children: any }) => {
  const { rows, sort } = props;
  const init = () => {
    const params: IParams = { defaultOrder: 'asc', defaultSortColumn: '', sortableColumns: [] };
    rows.map((column: any, index: number) => {
      if (column.sortOption && column.sortOption.orderBy) {
        params.defaultOrder = column.sortOption.orderBy;
        params.defaultSortColumn = column.id;
      }
      if (column.sortOption && column.sortOption.sortable) {
        params.sortableColumns.push(column.id);
      }
    });
    return params;
  };
  const initParams = init();
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>(initParams.defaultOrder);
  const [orderBy, setOrderBy] = React.useState(initParams.defaultSortColumn);

  const handleRequestSort = (property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => () => {
    handleRequestSort(property);
    sort(property, order);
  };

  const optionalSort = (row: any) => {
    if (initParams.sortableColumns.indexOf(row.id) === -1 && initParams.defaultSortColumn !== row.id) {
      return row.label;
    } else {
      return (
        <Tooltip title="Sort" placement={'bottom-end'} enterDelay={300}>
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            className={initParams.sortableColumns.length === 0 ? classes.sortLabel : undefined}
            onClick={initParams.sortableColumns.length !== 0 ? createSortHandler(row.id) : undefined}
            IconComponent={ArrowUpward}
          >
            {row.label}
          </TableSortLabel>
        </Tooltip>
      );
    }
  };

  return (
    <TableHead>
      <TableRow>{props.children}</TableRow>
      <TableRow>
        {rows.map(
          (row: any) =>
            row.fieldtype !== 'none' && (
              <TableCell
                key={row.id}
                className={classes.cell}
                padding="default"
                sortDirection={orderBy === row.id ? order : false}
              >
                {optionalSort(row)}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};
