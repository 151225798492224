import React, { useReducer } from 'react';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'set':
      return { ...state, discrepanciesList: action.discrepanciesData };
    case 'add':
      const count = state.discrepanciesList.count;
      return {
        ...state,
        discrepanciesList: {
          count: count + action.count,
          items: [...state.discrepanciesList.items, ...action.discrepancies.items],
          lastEvaluatedKey: action.discrepancies.lastEvaluatedKey
        }
      };
    default:
      return;
  }
};

const discrepanciesList = {
  discrepanciesList: {
    count: 0,
    items: [],
    lastEvaluatedKey: ''
  }
};
const DiscrepanciesContext = React.createContext(discrepanciesList);

const DiscrepanciesProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, discrepanciesList);
  // @ts-ignore
  return <DiscrepanciesContext.Provider value={{ state, dispatch }}>{props.children}</DiscrepanciesContext.Provider>;
};

export { DiscrepanciesContext, DiscrepanciesProvider };
