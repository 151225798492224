import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { customTheme } from '../main/materialStyles';
import { EmptyPopup, handleAPIException, PopupDefinition, StatusPopup } from '../common/status-popup';
import Grid from '@material-ui/core/Grid';
import { commonStyles } from '../../styles';
import { initGenerateScanDataLogForm } from './model';
import { useUserHandlers } from '../../hooks/use-user-handlers';
import { ProductTypeIndicator } from '../common/product-type-indicator';
import { EnumValues } from '../common/enum-utils/enum-utils';
import { UPIStatus } from '../common/upi-status';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { createScanDateLogCsv, getHandlers } from '../../services/api';
import { HandlersContext } from '../../context/handlers';
import { Autocomplete } from '@material-ui/lab';
import { ISOFormatDateAtEndOfDay, ISOFormatDateAtStartOfDay } from '../../helpers';
const _ = require('lodash')


const isArrayEmpty = (arr: any) => {
  return Array.isArray(arr) && arr.length === 0;
};

export const GenerateScanDataLogForm = withRouter((props) => {
  const classes = commonStyles();

  const [loading, setLoading] = React.useState(false);
  const [popup, setPopup] = React.useState(EmptyPopup);
  const [formValues, setFormValues] = React.useState(_.cloneDeep(initGenerateScanDataLogForm()));
  const [requestCSVDisabled, setRequestCSVDisabled] = React.useState(true);
  const carriers = useUserHandlers();
  const { dispatchHandlers } = useContext(HandlersContext);

  useEffect(() => {
    setRequestCSVDisabled(!requiredFormFieldsFilled());
  }, [formValues]);

  useEffect(() => {
    (async () => {
      try {
        const body = await getHandlers();
        dispatchHandlers({ type: 'set', body });
      } catch (e) {
        handleAPIException(e, setPopup);
      }
    })();
  }, []);

  useEffect(() => {
    if (carriers.length === 1) {
      setFormValues({ ...formValues, carrier: carriers[0].value });
    }
  }, [carriers.length]);

  const requiredFormFieldsFilled = () => {
    const requiredFields = ['dateFrom', 'dateTo', 'carrier'];
    return Object.keys(formValues).every((key) => {
      const value = Reflect.get(formValues, key);
      return (
        !requiredFields.includes(key) || (value !== null && value !== undefined && value !== '' && !isArrayEmpty(value))
      );
    });
  };

  const handleFormValueChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFormMultiselectValueChange = (event: React.ChangeEvent<any>, values: any, id: string) => {
    event.persist();
    setFormValues({ ...formValues, [id]: values });
  };

  const adjustSubmittedValues = () => {
    const adjustedFormValues: any = Object.assign({}, formValues);
    Object.getOwnPropertyNames(adjustedFormValues).forEach((property) => {
      if (
        adjustedFormValues[property] === null ||
        adjustedFormValues[property] === undefined ||
        adjustedFormValues[property] === '' ||
        isArrayEmpty(adjustedFormValues[property])
      ) {
        delete adjustedFormValues[property];
      }
    });
    adjustedFormValues['dateFrom'] = ISOFormatDateAtStartOfDay(adjustedFormValues['dateFrom']);
    adjustedFormValues['dateTo'] = ISOFormatDateAtEndOfDay(adjustedFormValues['dateTo'])
    return adjustedFormValues;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    setPopup(EmptyPopup);
    event.preventDefault();

    try {
      const csv = await createScanDateLogCsv(adjustSubmittedValues());
      window.open(csv.url, "_blank");
      setPopup(PopupDefinition.create('SUCCESS', 'Requested scan data log file has been downloaded.'));
      setFormValues(_.cloneDeep(initGenerateScanDataLogForm()));
    } catch (e) {
      handleAPIException(e, setPopup);
    } finally {
      setLoading(false);
    }
  };

  let popupMessage = <></>;
  if (popup.type) {
    // @ts-ignore
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      {popupMessage}
      <Modal open={loading}>
        <Grid container className={classes.loader} justify="center">
          <CircularProgress disableShrink />
        </Grid>
      </Modal>
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className={classes.container}>
            <div>
              <Grid container spacing={4} justify="center">
                <Grid item>
                  <FormControl className={classes.formInput}>
                    <DatePicker
                      required
                      fullWidth
                      disableFuture
                      autoOk
                      allowKeyboardControl={false}
                      format="DD/MM/YYYY"
                      showTodayButton
                      views={['year', 'month', 'date']}
                      value={formValues.dateFrom}
                      label="From"
                      name="dateFrom"
                      inputVariant="outlined"
                      onChange={(date) => {
                        if (moment(date).isAfter(moment.utc(formValues.dateTo))) {
                          setFormValues({
                            ...formValues,
                            dateFrom: ISOFormatDateAtStartOfDay(date?.toLocaleString()),
                            dateTo: formValues.dateTo
                          });
                        } else {
                          setFormValues({ ...formValues, dateFrom: ISOFormatDateAtStartOfDay(date?.toLocaleString()) });
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formInput}>
                    <DatePicker
                      required
                      fullWidth
                      disableFuture
                      autoOk
                      allowKeyboardControl={false}
                      format="DD/MM/YYYY"
                      showTodayButton
                      views={['year', 'month', 'date']}
                      minDate={formValues.dateFrom}
                      value={formValues.dateTo}
                      label="To"
                      name="dateTo"
                      inputVariant="outlined"
                      onChange={(date) =>
                        setFormValues({ ...formValues, dateTo:  ISOFormatDateAtEndOfDay(date?.toLocaleString()) })
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>
              <FormControl fullWidth variant="outlined" className={classes.formInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={formValues.consignmentId}
                  label="Consignment Number"
                  name="consignmentId"
                  onChange={handleFormValueChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="outlined" className={classes.formInput}>
                <InputLabel htmlFor="carrier">Fulfilment Centre</InputLabel>
                <Select
                  required
                  fullWidth
                  variant="outlined"
                  name="carrier"
                  onChange={handleFormValueChange}
                  input={<OutlinedInput labelWidth={80} name="carrier" id="carrier" />}
                  value={formValues.carrier}
                >
                  {carriers.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="outlined" className={classes.formInput}>
                <Autocomplete
                  multiple
                  id="productTypeIndicator"
                  options={EnumValues<string>(ProductTypeIndicator)}
                  getOptionLabel={(option) => option}
                  onChange={(event, value)=>handleFormMultiselectValueChange(event, value, "productTypeIndicator")}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="DD/DC" placeholder="DD/DC" />
                  )}
                />
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="outlined" className={classes.formInput}>
                <Autocomplete
                  multiple
                  id="upiStatus"
                  options={EnumValues<string>(UPIStatus)}
                  getOptionLabel={(option:string) => option}
                  onChange={(event, value)=>handleFormMultiselectValueChange(event, value, "upiStatus")}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Scan Status" placeholder="Scan Status" />
                  )}
                />
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="outlined" className={classes.formInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={formValues.carrierBarcode}
                  label="Carrier Barcode"
                  name="carrierBarcode"
                  onChange={handleFormValueChange}
                />
              </FormControl>
            </div>
            <div>
              <Grid container spacing={4} justify="center" className={classes.buttonsHolder}>
                <Grid item>
                  <Button variant="contained" color="primary" size="large" type="submit" disabled={requestCSVDisabled}>
                    Request CSV
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </form>
      </Paper>
    </MuiThemeProvider>
  );
});
