export const PURPOSES = {
  OUTBOUND: 'OUTBOUND',
  INBOUND: 'INBOUND'
};

export const URLS = {
  CONSIGNMENT_LIST: '/consignments',
  PARCELS_LIST: '/consignment/:consignmentId/parcels',
  CREATE_CONSIGNMENT: '/create-consignment',
  EDIT_CONSIGNMENT: '/edit-consignment/:consignmentId',
  DISCREPANCIES_LIST: '/discrepancies',
  ADMIN: '/admin',
  USER_ADMIN: '/user_admin',
  ADD_PARCELS_TO_CONSIGNMENT: '/consignments/:consignmentId/add-parcel',
  RESOLVE_DISCREPANCY: '/resolve-discrepancy/:discrepancyId',
  USER_SESSION_SETTINGS: '/user-session/settings',
  SCAN_DATA_LOG: '/scan-data-log',
  FEATURES: '/features',
  EDIT_VESSEL_NUMER: '/consignments/:consignmentId/edit-vessel-number'
};

export const USER_GROUPS = {
  ADMIN: 'admin',
  DEV: 'developer',
  DISCREPANCY: 'discrepancy',
  USER_ADMIN: 'user_admin',
  SCAN_DATA_LOG: 'scan_data_log'
};

export const HTTP_SUCCESS_MESSAGE = 'Done';
export const HTTP_ERROR_MESSAGE = 'Unexpected error occurred';
export const HTTP_UNKNOWN_MESSAGE = 'Unknown result';

export const yodelBarcodePrefix = '2LGBDB999AA';

// RegEx patterns for valid barcode formats for each carrier
// See the Confluence page for further description of each format:
// https://theverygroup.atlassian.net/wiki/spaces/CA/pages/4454220726/Blue+Scan+-+For+Incorrect+Barcodes+being+Scanned
export const validWrexhamBarcodes = [
  /\b[A-Z]{2}\d{8}\d{1}GB\b/,                                      // Royal Mail       (e.g. ZY342469380GB)
  /\bJJD00022\d{4}\d{7}\b/,                                        // Yodel BN         (e.g. JJD0002210661832161)
  /\b\d{1}VRY\d{2}\d{6}[A-O]{1}0(0[1-9]|[1-9][0-9])\b/,            // Arrow XL         (e.g. 0VRY00163815B050)
  /\b\d{1}VRY[A-Z]\d{7}[A-O]0(0[0-9]|[1-9][0-9])\b/,               // Arrow XL         (e.g. 0VRYC0000001A087)
  /\b8\w{3}\d{8}[A-Z]{1}([02]){1}(0[1-9]|[1-9][0-9])\b/,           // Yodel Collect+   (e.g. 894B10195464A028)
  /* XDP Format:
    [6] Digit and Character Account Number Assigned by XDP: 1 digit, 1 letter, 3 digits, 1 letter
    [6] Digit Number Range (1-999999)
    [3] Digit Parcel Number (1 - 999)

    Example: 3J550A003032001

    For the 6 Digit Number Range, a '1' would be represented as '000001'. To handle this as regex:
    '0*' consumes all leading zeroes, then '[1-9]|[1-9][0-9]{1,5}' checks for a range between 1-999999.

    For the 3 Digit Parcel Number (00[1-9]|0[1-9][0-9]|[1-9][0-9]{2}) checks for '001' (with 3 digits) up
    to '999').
    */
  /\b\d{1}[A-Z]{1}\d{3}[A-Z]{1}0*([1-9]|[1-9][0-9]{1,5})(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})\b/,


];
