import React from 'react';
import { AuthenticationProvider } from '../context/authentication';

const withAuthenticationProvider = (Component: any) => {
  return (props: any) => {
    return (
      <AuthenticationProvider>
        <Component {...props} />
      </AuthenticationProvider>
    );
  };
};

export default withAuthenticationProvider;
