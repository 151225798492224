import React, { ReactNode } from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { formatDate } from '../../../helpers';

interface IPageHeadingProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 0, 2)
    }
  })
);

export const PageHeading = ({ children }: IPageHeadingProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h5">{children}</Typography>
      <Typography>Today {formatDate()}</Typography>
    </Box>
  );
};
