export const createEmptyConsignment = () => ({
  handler: '',
  origin: '',
  purpose: 'DISPATCH',
  userDefinedId: '',
  shippingLine: '',
  nationality: '',
  port: '',
  vesselNumber: '',
  pbnNumber: 'empty',
  departureDate: '',
  trailerType: ''
});
