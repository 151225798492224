import React, { ComponentType } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { indigo, lightGreen } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: lightGreen,
    secondary: indigo
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#fff'
      }
    }
  }
});

export const withRoot = <P extends object>(Component: any) => (props: P) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Component {...props} />
  </MuiThemeProvider>
);
