import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { EnhancedTableCell } from '../../enhanced-table-cell/enhanced-table-cell';

export const EnhancedTableBody = (props: { rows: any; handleClick?: any; cellsStyles?: any }) => {
  const { rows, cellsStyles } = props;
  return (
    <TableBody id="consignments-rows">
      {rows
        ? rows.map((row: any, index: number) => {
            const items = [];
            for (const key in row.data) {
              items.push(
                <EnhancedTableCell key={key} className={cellsStyles && key in cellsStyles ? cellsStyles[key] : ''}>
                  {row.data[key]}
                </EnhancedTableCell>
              );
            }
            return (
              <TableRow
                hover
                className={row.rowStyles && row.rowStyles}
                onClick={row.handleClick}
                role="checkbox"
                tabIndex={-1}
                key={index}
              >
                {items}
              </TableRow>
            );
          })
        : ''}
    </TableBody>
  );
};
