export enum EnumType {}

export const EnumValues = <T>(enumType: any):T[] => {
  return (
    Object.values(enumType)
  );
};

export const EnumKeys = (enumType: any):string[] => {
  return Object.keys(enumType);
};
