import React, { Fragment } from 'react';
import { PageHeading } from '../../components/common/page-heading';
import { GenerateScanDataLogForm } from '../../components/generate-scan-data-log-form';
import { HandlersProvider } from '../../context/handlers';

export const ScanDataLogPage = () => {
  return (
    <Fragment>
      <PageHeading>Request Scan Data Log</PageHeading>
      <HandlersProvider>
        <GenerateScanDataLogForm />
      </HandlersProvider>
    </Fragment>
  );
};
