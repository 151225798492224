import dotenv from 'dotenv';

dotenv.config();

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_STAGE_PATH,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_DEFAULT_BUCKET,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_ENVIRONMENT,
  REACT_APP_BUILD_VERSION
} = process.env;

export default {
  app: {
    environment: REACT_APP_ENVIRONMENT,
    buildVersion: REACT_APP_BUILD_VERSION
  },
  api: {
    baseUrl: REACT_APP_API_BASE_URL,
    stagePath: REACT_APP_STAGE_PATH
  },
  auth: {
    region: REACT_APP_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    storage: window.sessionStorage
  },
  AWSS3: {
    bucket: REACT_APP_DEFAULT_BUCKET,
    region: REACT_APP_REGION
  }
};
