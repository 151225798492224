import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Button, Grid, Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { URLS } from '../../constants';
import { formatAlphaInput, formatFloatNumericInput, formatNumericInput } from '../../helpers';
import { createEmptyDiscrepancy, IDiscrepancyEntity, IResolvedDiscrepancyEntity } from './model';
import { getDiscrepancyById, resolveDiscrepancy } from '../../services/api';
import { ConfirmationDialog } from '../common/confirmation-dialog';
import { AlertDialog } from '../common/alert-dialog';
import { DiscrepancyForm } from './components/discrepancy-form';
import { CommoditySplitItem } from './components/commodity-split-item';
import { ResolvedDiscrepancyChangesList } from './components/resolved-discrepancy-changes-list';
import { StatusPopup } from '../../components/common/status-popup';
import { HTTP_ERROR_MESSAGE } from '../../constants'
import { customTheme } from "../main/materialStyles";

interface IResolveDiscrepancyFormProps extends RouteComponentProps<any> {
  discrepancyId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0)
    },
    container: {
      padding: theme.spacing(2, 0, 0),
      width: theme.spacing(62),
      margin: '0 auto'
    },
    buttonsHolder: {
      margin: theme.spacing(2, -4, 0)
    }
  })
);

export const ResolveDiscrepancyForm = withRouter((props: IResolveDiscrepancyFormProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { discrepancyId, history } = props;

  const [discrepancy, setDiscrepancyData] = useState<IDiscrepancyEntity>(createEmptyDiscrepancy());
  const [commodityChildren, setCommodityChildren] = useState([]);
  const [error, setError] = React.useState("");
  const [resolvedCommodityChildren, setResolvedCommodityChildren] = useState({});
  const [resolvedDiscrepancy, setResolvedDiscrepancyData] = useState<IResolvedDiscrepancyEntity>({});
  const [leaveConfirmationDialogVisibility, setLeaveConfirmationDialogVisibility] = useState(false);
  const [alreadyUpdatedDialogVisibility, setAlreadyUpdatedDialogVisibility] = useState(false);
  const [submitConfirmationDialogVisibility, setSubmitConfirmationDialogVisibility] = useState(false);

  const getCommodityChildrenItemById = (id: string) => commodityChildren.find((item: any) => id === item.id);

  const resolvedCommodityChildrenList = Object.keys(resolvedCommodityChildren).map((id: string) => ({
    item: getCommodityChildrenItemById(id),
    resolvedFields: (resolvedCommodityChildren as any)[id]
  }));

  useEffect(() => {
    (async () => {
      try {
         const response = await getDiscrepancyById(discrepancyId);
         setDiscrepancyData(response);
         if (response.CommodityChildren) {
           setCommodityChildren(response.CommodityChildren);
         }
      } catch (e) {
         setError(HTTP_ERROR_MESSAGE)
      }
    })();
  }, []);

  const formatInputValue = (fieldName: string, value: string) => {
    const fields = {
      CountryOfOrigin: formatAlphaInput,
      CostPrice: formatFloatNumericInput,
      NetWeight: formatFloatNumericInput,
      GrossWeight: formatFloatNumericInput,
      HarmonisedSystemCode: formatNumericInput,
      UKHarmonisedSystemCode: formatNumericInput,
      UKVATRate: formatFloatNumericInput,
      RoIVATRate: formatFloatNumericInput
    } as any;
    return fields[fieldName] ? fields[fieldName](value) : value;
  };

  const handleFormControlChange = (name: string, value: string) => {
    const formattedValue = formatInputValue(name, value);
    setDiscrepancyData((data) => ({ ...data, [name]: formattedValue }));
    setResolvedDiscrepancyData((data) => ({ ...data, [name]: formattedValue }));
  };

  const isDiscrepancyChanged = () => {
    const isParentChanged =
      Object.keys(resolvedDiscrepancy).length > 0 &&
      Object.keys(resolvedDiscrepancy).some((v: string) => (resolvedDiscrepancy as any)[v] !== '');

    const isChildrenChanged = Object.keys(resolvedCommodityChildren).length > 0;

    return isParentChanged || isChildrenChanged;
  };

  const handleOpenConfirmationDialog = () => {
    if (isDiscrepancyChanged()) {
      setLeaveConfirmationDialogVisibility(true);
    } else {
      moveToDiscrepanciesListPage();
    }
  };

  const handleDialogClose = () => {
    setLeaveConfirmationDialogVisibility(false);
  };

  const handleSubmitConfirmationDialogClose = () => {
    setSubmitConfirmationDialogVisibility(false);
  };

  const moveToDiscrepanciesListPage = () => {
    history.push(URLS.DISCREPANCIES_LIST);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitConfirmationDialogVisibility(true);
    return false;
  };

  const saveResolvedDiscrepancy = async () => {
    try {
      const resolvedChildren = Object.keys(resolvedCommodityChildren).map((id: string) => ({
        id,
        ...(resolvedCommodityChildren as any)[id]
      }));

      const resolvedCommodityChildrenPayload = {
        ...(resolvedChildren.length
          ? {
              CommodityChildren: resolvedChildren
            }
          : {})
      };

      setError("")
      await resolveDiscrepancy(
        {
          ...resolvedDiscrepancy,
          ...resolvedCommodityChildrenPayload,
          version: discrepancy.version
        },
        discrepancyId
      );
      moveToDiscrepanciesListPage();
      enqueueSnackbar('Data successfully saved!', { variant: 'success' });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        setAlreadyUpdatedDialogVisibility(true);
        setSubmitConfirmationDialogVisibility(false);
      }
      setError(HTTP_ERROR_MESSAGE)
    }
  };

  const handleAlreadyUpdatedDialogClose = () => {
    setAlreadyUpdatedDialogVisibility(false);
  };

  const handleChildChange = (id: string, data: any) => {
    setResolvedCommodityChildren({
      ...resolvedCommodityChildren,
      [id]: {
        ...(resolvedCommodityChildren as any)[id],
        ...data
      }
    });
  };

  const isDiscrepancyFieldHasValue = (name: keyof IDiscrepancyEntity) => {
       if (name === "GrossWeight") {
          // @ts-ignore: False positive warning:
          // This condition will always return 'true' since the types 'number' and 'string' have no overlap.  TS2367
          return discrepancy[name] !== '' &&
                 // Type could be number or string, so using '!='
                 discrepancy[name] != 0 &&
                 !resolvedDiscrepancy[name]
       }
       return discrepancy[name] !== '' && !resolvedDiscrepancy[name];
  }

  let errorMsg = <></>
  if (error!= ""){
     errorMsg = <StatusPopup text={error} type="ERROR" />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
    <Paper className={classes.root}>
      {errorMsg}
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <div className={classes.container}>
          <DiscrepancyForm
            discrepancy={discrepancy}
            onInputChange={handleFormControlChange}
            isDiscrepancyFieldHasValue={isDiscrepancyFieldHasValue}
          />
          {commodityChildren.map((item: any) => (
            <CommoditySplitItem item={item} onChange={handleChildChange} key={item.id} />
          ))}
          <Grid container spacing={5} justify="center" className={classes.buttonsHolder}>
            <Grid item>
              <Button variant="contained" size="large" onClick={handleOpenConfirmationDialog}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="large" type="submit" disabled={!isDiscrepancyChanged()}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
      <ConfirmationDialog
        open={submitConfirmationDialogVisibility}
        onClose={handleSubmitConfirmationDialogClose}
        onConfirm={saveResolvedDiscrepancy}
        title="Confirm"
        text="Are you sure you want to apply following changes?"
      >
        <ResolvedDiscrepancyChangesList
          resolvedDiscrepancy={resolvedDiscrepancy}
          resolvedCommodityChildrenList={resolvedCommodityChildrenList}
        />
      </ConfirmationDialog>
      <ConfirmationDialog
        open={leaveConfirmationDialogVisibility}
        onClose={handleDialogClose}
        onConfirm={moveToDiscrepanciesListPage}
        title="Confirm"
        text="Are you sure you want to leave this page?"
      />
      <AlertDialog open={alreadyUpdatedDialogVisibility} onClose={handleAlreadyUpdatedDialogClose}>
        <Fragment>
          The data has been already updated and the discrepancy might be fixed. Please refresh the page.
        </Fragment>
      </AlertDialog>
    </Paper>
    </MuiThemeProvider>
  );
});
