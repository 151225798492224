export const CONSIGNMENT_LIST = 'ListOfConsignments';
export const PARCELS_LIST = 'listOfParcels';
export const DOCUMENTS_REQUEST = 'requestDocuments';
export const DISCREPANCIES_LIST = 'ListOfDiscrepancies';
export const CREATE_CONSIGNMENT = 'CreateConsignment';
export const EDIT_CONSIGNMENT = 'EditConsignment';
export const CONSIGNMENT_BY_ID = 'ConsignmentById';
export const CLOSE_CONSIGNMENT = 'CloseConsignment';
export const ADD_PARCEL_TO_CONSIGNMENT = 'AddParcel';
export const DISCREPANCY_BY_ID = 'DiscrepancyById';
export const RESOLVE_DISCREPANCY = 'ResolveDiscrepancy';
export const PARCEL_ITEM_BY_ID = 'ParcelItemById';
export const PARCEL_ITEMS_ADVANCED_SEARCH = 'ParcelItemsAdvancedSearch';
export const GET_PARCEL = 'GetParcel';
export const CREATE_PARCEL = 'CreateParcel';
export const ABORT_PARCEL = 'AbortParcel';
export const HANDLERS = 'Handlers';
export const USER_SESSION_SETTINGS = 'UserSessionSettings';
export const SCAN_DATA_LOG = 'ScanDataLog';
export const USERS = 'GetUsers';
export const USER = 'User';
export const SETTINGS = 'Settings';
export const SETTINGS_EXCHANGE_RATE = 'SettingsExchangeRate';
export const SETTINGS_SFTP_CONFIG = 'SettingsSftpConfig';
export const SETTINGS_STATISTICAL_VALUES = 'SettingsStatisticalValues';
export const SETTINGS_FREIGHT_CHARGES = 'SettingsFreightCharges';
export const CONSIGNMENT_UPI = 'ConsignmentUpi';
export const CONSIGNMENT_UPIS = 'ConsignmentUpis';
export const CONSIGNMENT_DOCS = 'ConsignmentDocs';
export const FEATURE_UPDATE_STATE = 'FeatureUpdateState';
export const FEATURE_GET_LIST = 'FeatureGetList';

interface IEndpoints {
  [key: string]: {
    path: string;
    name: string;
  };
}

const endpoints: IEndpoints = {
  [CONSIGNMENT_LIST]: {
    path: '/consignments',
    name: CONSIGNMENT_LIST
  },
  [CONSIGNMENT_BY_ID]: {
    path: '/consignments',
    name: CONSIGNMENT_BY_ID
  },
  [CLOSE_CONSIGNMENT]: {
    path: '/consignments',
    name: CLOSE_CONSIGNMENT
  },
  [CREATE_CONSIGNMENT]: {
    path: '/consignments',
    name: CREATE_CONSIGNMENT
  },
  [EDIT_CONSIGNMENT]: {
    path: '/consignments',
    name: EDIT_CONSIGNMENT
  },
  [PARCELS_LIST]: {
    path: '/consignment',
    name: PARCELS_LIST
  },
  [DOCUMENTS_REQUEST]: {
    path: '/consignment',
    name: DOCUMENTS_REQUEST
  },
  [DISCREPANCIES_LIST]: {
    path: '/discrepancies',
    name: DISCREPANCIES_LIST
  },
  [DISCREPANCY_BY_ID]: {
    path: '/discrepancies',
    name: DISCREPANCY_BY_ID
  },
  [RESOLVE_DISCREPANCY]: {
    path: '/discrepancies',
    name: RESOLVE_DISCREPANCY
  },
  [ADD_PARCEL_TO_CONSIGNMENT]: {
    path: '/parcels',
    name: ADD_PARCEL_TO_CONSIGNMENT
  },
  [GET_PARCEL]: {
    path: '/parcels',
    name: GET_PARCEL
  },
  [CREATE_PARCEL]: {
    path: '/parcels',
    name: CREATE_PARCEL
  },
  [ABORT_PARCEL]: {
    path: '/parcels',
    name: ABORT_PARCEL
  },
  [PARCEL_ITEM_BY_ID]: {
    path: '/parcel-items',
    name: PARCEL_ITEM_BY_ID
  },
  [PARCEL_ITEMS_ADVANCED_SEARCH]: {
    path: '/parcel-items/advanced-search',
    name: PARCEL_ITEMS_ADVANCED_SEARCH
  },
  [HANDLERS]: {
    path: '/metadata/handlers',
    name: HANDLERS
  },
  [USERS]: {
    path: '/users',
    name: USERS
  },
  [USER]: {
    path: '/user',
    name: USER
  },
  [USER_SESSION_SETTINGS]: {
    path: '/user-session/settings',
    name: USER_SESSION_SETTINGS
  },
  [SCAN_DATA_LOG]: {
    path: '/scan-data-log',
    name: SCAN_DATA_LOG
  },
  [SETTINGS]: {
    path: '/settings',
    name: SETTINGS
  },
  [SETTINGS_EXCHANGE_RATE]: {
    path: '/settings/exchange-rate',
    name: SETTINGS_EXCHANGE_RATE
  },
  [SETTINGS_SFTP_CONFIG]: {
    path: '/settings/sftp-config',
    name: SETTINGS_SFTP_CONFIG
  },
  [SETTINGS_STATISTICAL_VALUES]: {
    path: '/settings/statistical-values',
    name: SETTINGS_STATISTICAL_VALUES
  },
  [SETTINGS_FREIGHT_CHARGES]: {
    path: '/settings/freight-charges',
    name: SETTINGS_FREIGHT_CHARGES
  },
  [CONSIGNMENT_UPI]: {
    path: '/consignment/upi',
    name: CONSIGNMENT_UPI
  },
  [CONSIGNMENT_UPIS]: {
    path: '/consignment/upis',
    name: CONSIGNMENT_UPIS
  },
  [CONSIGNMENT_DOCS]: {
    path: '/consignment/docs',
    name: CONSIGNMENT_DOCS
  },
  [FEATURE_GET_LIST]: {
    path: '/features',
    name: FEATURE_GET_LIST
  },
  [FEATURE_UPDATE_STATE]: {
    path: '/feature',
    name: FEATURE_UPDATE_STATE
  }
};

export default endpoints;
