import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MoreVert as MoreVertIcon, Receipt as ReceiptIcon } from '@material-ui/icons';
import { CircularProgress, IconButton, Link, MenuItem, MenuList, Popover } from '@material-ui/core';
import { getFileURL } from '../../../services/storage';
import { IMenuItemsProps, IParcelDocumentUrls, ITooltipProps } from './model';

type initAnchorEl = HTMLDivElement | null;

const useStyles = makeStyles({
  navLink: {
    width: '100%',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  disabledNavLink: {
    width: '100%',
    textDecoration: 'none',
    opacity: 0.5,
    pointerEvents: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  disabledMenuItem: {
    cursor: 'not-allowed'
  },
  iconButton: {
    color: 'rgba(0, 0, 0, 0.5)'
  }
});

export const Tooltips = (props: ITooltipProps) => {
  const classes = useStyles();
  const { invoiceKeyIE, customsInvoiceKeyIE, manifestKeyIE } = props;
  const [loading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null as initAnchorEl);
  const [urls, setUrls] = useState<IParcelDocumentUrls>({
    manifestUrlIE: undefined,
    vatInvoiceUrlIE: undefined,
    customsInvoiceUrlIE: undefined
  });
  const open = !!anchorEl;

  const handleMenuIconClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const element = event.currentTarget as HTMLDivElement;
    const links: IParcelDocumentUrls = {
      manifestUrlIE: undefined,
      vatInvoiceUrlIE: undefined,
      customsInvoiceUrlIE: undefined
    };
    if (typeof invoiceKeyIE !== 'undefined') {
      links.vatInvoiceUrlIE = await getFileURL({ key: invoiceKeyIE });
    }
    if (typeof customsInvoiceKeyIE !== 'undefined') {
      links.customsInvoiceUrlIE = await getFileURL({ key: customsInvoiceKeyIE });
    }
    if (typeof manifestKeyIE !== 'undefined') {
      links.manifestUrlIE = await getFileURL({ key: manifestKeyIE });
    }
    setUrls(links);
    setAnchorEl(element);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const menuItemsProps = [
    { url: urls.vatInvoiceUrlIE, label: 'Open Commercial Invoice (IE)', show: true },
    { url: urls.customsInvoiceUrlIE, label: 'Open Customs Commercial Invoice (IE)', show: false },
    { url: urls.manifestUrlIE, label: 'Open Packing List (IE)', show: true }
  ];

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <div onClick={handleMenuIconClick}>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuList>
            {menuItemsProps
              .filter((item) => item.show)
              .map((menuItemsProp: IMenuItemsProps, key: number) => (
                <MenuItem
                  onClick={handleCloseMenu}
                  key={key}
                  className={menuItemsProp.url ? '' : classes.disabledMenuItem}
                >
                  <Link
                    href={menuItemsProp.url}
                    target="_blank"
                    color="inherit"
                    className={menuItemsProp.url ? classes.navLink : classes.disabledNavLink}
                  >
                    <IconButton disabled color="primary">
                      <ReceiptIcon />
                    </IconButton>
                    {menuItemsProp.label}
                  </Link>
                </MenuItem>
              ))}
          </MenuList>
        </Popover>
      </div>
    );
  }
};
