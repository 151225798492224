import React from 'react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { CognitoUser } from '@aws-amplify/auth';

const initialAuthState: AuthState | any = AuthState.SignIn;
const initialUser: CognitoUser | any = {};
const initialUserGroups: any = [];

export interface IAuthenticationInfo {
  authState: AuthState;
  user: CognitoUser | null;
  userGroups: string[];
}

const createInitAuthenticationInfo = () => ({
  authState: initialAuthState,
  user: initialUser,
  userGroups: initialUserGroups
});

export const AuthenticationContext = React.createContext<IAuthenticationInfo>(createInitAuthenticationInfo());

interface AuthProps {
  children: React.ReactNode;
}

export const AuthenticationProvider = ({ children }: AuthProps) => {
  const auth = useAuthentication();

  return <AuthenticationContext.Provider value={auth}>{children}</AuthenticationContext.Provider>;
};

export function useAuthentication() {
  const [authenticationInfo, setAuthenticationInfo] = React.useState<IAuthenticationInfo>(
    createInitAuthenticationInfo()
  );

  React.useEffect(() => {
    (async () => {
      onAuthUIStateChange((nextAuthState, authData) => {
        const user = authData !== undefined ? (authData as CognitoUser) : null;
        const userGroups = user?.getSignInUserSession()?.getAccessToken().payload['cognito:groups'];
        const body = {
          authState: nextAuthState,
          user,
          userGroups
        };
        setAuthenticationInfo(body);
      });
    })();
  }, [setAuthenticationInfo]);

  return authenticationInfo;
}

export function useUser() {
  const { user } = React.useContext(AuthenticationContext);

  return user;
}

export function useAuthState() {
  const { authState } = React.useContext(AuthenticationContext);

  return authState;
}

export function useUserGroups() {
  const { userGroups } = React.useContext(AuthenticationContext);

  return userGroups;
}
