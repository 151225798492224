import React, { Fragment, useState, useEffect} from 'react';
import { InputAdornment } from '@material-ui/core';
import { IDiscrepancyEntity } from '../../model';
import { DiscrepancyTextField } from '../discrepancy-text-field';

export interface IDiscrepancyFormProps {
  discrepancy: IDiscrepancyEntity;
  isDiscrepancyFieldHasValue(name: string): boolean;
  onInputChange(name: string, value: string): void;
}


export const DiscrepancyForm = (props: IDiscrepancyFormProps) => {
  const { discrepancy, isDiscrepancyFieldHasValue, onInputChange } = props;
  const [netWeight, setNetWeight] = useState("");

  const handleFormControlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { name, value } = event.target;

    if (name=="GrossWeight"){
       let net_weight_val:any = Math.floor(parseInt(value) * 0.95)
       net_weight_val = net_weight_val <= 1 ? 1 : net_weight_val
       net_weight_val = net_weight_val.toString()
       if (net_weight_val === "NaN") {
          net_weight_val = ""
       }
       setNetWeight(net_weight_val)
       onInputChange("NetWeight", net_weight_val);
    }
    onInputChange(name, value);
  };

  return (
    <Fragment>
      <DiscrepancyTextField disabled value={discrepancy.SKU} name="SKU" />
      <DiscrepancyTextField disabled value={discrepancy.catalogueNumber} name="CatalogueNumber" />
      <DiscrepancyTextField disabled multiline value={discrepancy.ItemDescription} name="ItemDescription" />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={isDiscrepancyFieldHasValue('CostPrice')}
        type="text"
        value={discrepancy.CostPrice}
        name="CostPrice"
        InputProps={{ startAdornment: <InputAdornment position="start">&pound;</InputAdornment> }}
      />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={isDiscrepancyFieldHasValue('GrossWeight')}
        value={discrepancy.GrossWeight}
        type="text"
        name="GrossWeight"
        InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment> }}
      />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={true}
        value={discrepancy.NetWeight || netWeight}
        type="text"
        name="NetWeight"
        InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment> }}
      />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={isDiscrepancyFieldHasValue('HarmonisedSystemCode')}
        value={discrepancy.HarmonisedSystemCode}
        type="text"
        name="HarmonisedSystemCode"
      />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={isDiscrepancyFieldHasValue('UKHarmonisedSystemCode')}
        value={discrepancy.UKHarmonisedSystemCode}
        type="text"
        name="UKHarmonisedSystemCode"
      />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={isDiscrepancyFieldHasValue('CountryOfOrigin')}
        value={discrepancy.CountryOfOrigin}
        name="CountryOfOrigin"
        inputProps={{ maxLength: 2 }}
      />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={isDiscrepancyFieldHasValue('UKVATRate')}
        value={discrepancy.UKVATRate}
        type="text"
        name="UKVATRate"
        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
      />
      <DiscrepancyTextField
        onChange={handleFormControlChange}
        disabled={isDiscrepancyFieldHasValue('RoIVATRate')}
        value={discrepancy.RoIVATRate}
        type="text"
        name="RoIVATRate"
        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
      />
    </Fragment>
  );
};
