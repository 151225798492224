import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EditVesselNumber } from '../../components/edit-vessel-number';
import { getConsignmentById } from '../../services/api';
import { PURPOSES } from '../../constants';
import { IConsignmentEntity, createEmptyConsignment } from '../../models/consignment';

interface MatchParams {
  consignmentId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export const EditVesselNumberPage = ({ match }: Props) => {
  const { consignmentId } = match.params;

  const [consignment, setConsignmentData] = useState<IConsignmentEntity>(createEmptyConsignment());

  useEffect(() => {
    (async () => {
      setConsignmentData(await getConsignmentById(consignmentId));
    })();
  }, []);

  return (
    {
      [PURPOSES.INBOUND]: <EditVesselNumber consignment={consignment} />
    }[consignment.purpose] || null
  );
};
