import React, { ReactNode, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  createStyles,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  PriorityHigh as PriorityHighIcon,
  Help as HelpIcon
} from '@material-ui/icons';
import { HTTP_ERROR_MESSAGE, HTTP_SUCCESS_MESSAGE, HTTP_UNKNOWN_MESSAGE } from '../../../constants';

export class PopupDefinition {
  constructor(public type: keyof PopupClassNames | null, public message?: string) {
    this.type = type ? type : null;
    this.message = message ? message : this.defaultMessage();
  }

  static create(type: keyof PopupClassNames | null, message?: string) {
    return new PopupDefinition(type, message);
  }

  private defaultMessage() {
    switch (this.type) {
      case 'AMBER':
      case 'ERROR':
      case 'INVALID':
        return HTTP_ERROR_MESSAGE;
      case 'SUCCESS':
        return HTTP_SUCCESS_MESSAGE;
      case 'UNKNOWN':
        return HTTP_UNKNOWN_MESSAGE;
      case null:
        return '';
    }
  }
}

export const EmptyPopup = PopupDefinition.create(null, '');

interface IStatusPopupProps {
  type: keyof PopupClassNames;
  text: string;
  upi?: string;
  key?: number;
}

interface PopupIcons {
  SUCCESS: ReactNode;
  AMBER: ReactNode;
  ERROR: ReactNode;
  UNKNOWN: ReactNode;
  INVALID: ReactNode;
}

interface PopupClassNames {
  SUCCESS: string;
  AMBER: string;
  ERROR: string;
  UNKNOWN: string;
  INVALID: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalBox: {
      width: theme.spacing(88),
      outline: 'none'
    },
    statusIcon: {
      width: theme.spacing(15),
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      background: '#fff',
      fontSize: theme.typography.pxToRem(100)
    },
    textHolder: {
      padding: theme.spacing(7),
      color: (props: IStatusPopupProps) =>
        (({
          AMBER: '#3d404e',
          SUCCESS: '#fff',
          ERROR: '#fff',
          UNKNOWN: '#fff',
          INVALID: '#fff'
        } as PopupClassNames)[props.type])
    },
    statusText: {
      fontSize: theme.typography.pxToRem(36),
      lineHeight: theme.typography.pxToRem(60),
      fontWeight: 'bold'
    },
    upi: {
      fontSize: theme.spacing(3.5)
    },
    success: {
      background: '#67a81f',
      color: '#67a81f'
    },
    amber: {
      background: '#fbd632',
      color: '#fbd632'
    },
    error: {
      background: '#d83d3d',
      color: '#d83d3d'
    },
    unknown: {
      background: '#e94790',
      color: '#e94790'
    },
    invalid: {
      background: '#306d85',
      color: '#306d85'
    }
  })
);

export function handleAPIException(exception: any, setPopup: React.Dispatch<React.SetStateAction<PopupDefinition>>) {
  if (!exception.response) {
    // API Gateway Timeout (>30s)
    setPopup(
      PopupDefinition.create(
        'UNKNOWN',
        'Request was accepted, however it is taking longer than usual to complete. Please check for results later.'
      )
    );
  } else {
    let errorMessage = HTTP_ERROR_MESSAGE;
    const data = exception.response.data;
    if (data) {
      const bodyMessage = typeof data === 'string' ? data : data.message;
      if (bodyMessage) {
        errorMessage = bodyMessage;
      }
    }
    if (errorMessage !== HTTP_ERROR_MESSAGE && exception.response.status < 500 && exception.response.status >= 400) {
      setPopup(PopupDefinition.create('AMBER', errorMessage));
    } else {
      setPopup(PopupDefinition.create('ERROR', errorMessage));
    }
  }
}

const DEFAULT_TIMEOUT: number = 1000;

export const StatusPopup = (props: IStatusPopupProps) => {
  const classes = useStyles(props);
  const { key, text, type, upi } = props;
  const [open, setOpen] = useState(true);
  const popupClasses: PopupClassNames = {
    SUCCESS: 'success',
    AMBER: 'amber',
    ERROR: 'error',
    UNKNOWN: 'unknown',
    INVALID: 'invalid'
  };
  const boxClass: string = popupClasses[type as keyof PopupClassNames];

  const icons: PopupIcons = {
    SUCCESS: <CheckIcon fontSize="inherit" />,
    AMBER: <PriorityHighIcon fontSize="inherit" />,
    ERROR: (
      <CloseIcon
        fontSize="inherit"
        onClick={(event) => {
          setOpen(false);
        }}
      />
    ),
    UNKNOWN: <HelpIcon fontSize="inherit" />,
    INVALID: <PriorityHighIcon fontSize="inherit" />
  };
 
  useEffect(() => {
    const timer = setTimeout(() => setOpen(false), DEFAULT_TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      className={classes.modal}
      open={open}
      disableAutoFocus
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box className={classes.modalBox}>
          <Paper className={(classes as any)[boxClass]}>
            <Grid container>
              <Grid item container xs={3} className={classes.statusIcon}>
                {icons[type as keyof PopupIcons]}
              </Grid>
              <Grid item xs={9} className={classes.textHolder}>
                <Typography variant="h5" className={classes.statusText}>
                  {text}
                </Typography>
                {upi && <Typography className={classes.upi}>UPI {upi}</Typography>}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
};
