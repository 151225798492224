import React, { Fragment, forwardRef, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Grid, Paper, ButtonBase, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { HTTP_ERROR_MESSAGE, URLS } from '../../constants';
import { IConsignmentEntity } from '../../models/consignment';
import { customTheme } from '../main/materialStyles';
import { ReturnConsignmentInfo } from '../return-consignment-info';
import { formatAlphanumericInput } from '../../helpers';
import { closeConsignment, editConsignment } from '../../services/api';
import { validateVesselNumber } from '../common/vessel-number-algorithm';

interface Props {
  consignment: IConsignmentEntity;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeading: {
      fontSize: 26,
      fontWeight: 100,
      margin: theme.spacing(1, 0, 3)
    },
    root: {
      flexGrow: 1
    },
    scanBox: {
      padding: theme.spacing(4),
      height: '100%'
    },
    accepted: {
      backgroundColor: '#a7cb19',
      color: '#fff',
      textAlign: 'center',
      padding: '34px 0',
      height: '200px'
    },
    discrepancies: {
      backgroundColor: '#e47536',
      color: '#fff',
      textAlign: 'center',
      padding: '34px 0',
      height: '200px'
    },
    parcelCount: {
      margin: '30px 0 0',
      color: '#fff',
      fontSize: 36
    },
    btnBack: {
      textTransform: 'none',
      margin: theme.spacing(0, 0, 0, 0.5)
    },
    buttonsHolder: {
      margin: theme.spacing(-2, -80)
    }
  })
);

export const EditVesselNumber = (props: Props) => {
  const { consignment }: { consignment: IConsignmentEntity } = props;
  const classes = useStyles();
  const [loader, setLoader] = React.useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [vesselNumberToValidate, setVesselNumber] = React.useState('');
  const history = useHistory();
  const [error, setError] = React.useState('');

  const ConsignmentsLink = forwardRef((linkProps: any, ref) => (
    <Link to={URLS.CONSIGNMENT_LIST} {...linkProps} ref={ref} />
  ));

  const ConsignmentsListLink = forwardRef((linkProps: any, ref) => (
    <Link to={URLS.CONSIGNMENT_LIST} {...linkProps} ref={ref} />
  ));
  let formattedValue;

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setInvalid(false);
    const { name, value } = event.target;
    formattedValue = formatAlphanumericInput(value);
    setVesselNumber(formattedValue);
    setFormTouched(true);
    if (formattedValue === '') {
      setFormTouched(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoader(true);
    setError('');
    event.preventDefault();
    const isValid = validateVesselNumber(vesselNumberToValidate);
    const userDefinedId = consignment.userDefinedId;
    const vesselNumber = vesselNumberToValidate;
    try {
      if (isValid) {
        await editConsignment(consignment.consignmentId, { userDefinedId, vesselNumber });
        await closeConsignment(consignment.consignmentId);
        setLoader(false);
        history.push(URLS.CONSIGNMENT_LIST);
      } else {
        console.log('Invalid vessel number');
        setLoader(false);
        setInvalid(true);
      }
    } catch (e) {
      setError(HTTP_ERROR_MESSAGE);
      console.error(e);
      setLoader(false);
      return false;
    }
    return false;
  };

  const formButtons = () => {
    if (loader) {
      return (
        <Grid container spacing={4} justify="center" className={classes.buttonsHolder}>
          <CircularProgress disableShrink />
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={4} justify="center" className={classes.buttonsHolder}>
          <Grid item>
            <Button variant="contained" size="large" component={ConsignmentsLink}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" disabled={!formTouched} color="primary" size="large" type="submit">
              Close Consignment
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  const [invalid, setInvalid] = useState(false);

  return (
    <MuiThemeProvider theme={customTheme}>
      <Fragment>
        <ButtonBase component={ConsignmentsListLink} className={classes.btnBack}>
          <ChevronLeftIcon />
          <Typography id="back-to-list">Back to list</Typography>
        </ButtonBase>
        <Typography className={classes.pageHeading}>Edit Vessel Number</Typography>
        <ReturnConsignmentInfo {...consignment} />
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={10} className={classes.root} direction="row" alignItems="stretch">
            <Grid item xs={12}>
              <Paper className={classes.scanBox}>
                <Typography>Please enter a valid vessel number</Typography>
                <br />
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    onChange={handleTextFieldChange}
                    autoComplete="off"
                    label="Vessel Number"
                    variant="outlined"
                    name=""
                    id="vesselNumber"
                    inputProps={{
                      maxLength: 25
                    }}
                    helperText={invalid ? 'Incorrect vessel number entered' : ''}
                    error
                  />
                </Grid>
                <br />
                {formButtons()}
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Fragment>
    </MuiThemeProvider>
  );
};
