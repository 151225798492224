import React, { useReducer } from 'react';
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'set':
      return { ...state, parcels: action.items };
    default:
      return;
  }
};

const parcels: any = {};

const ParcelsContext = React.createContext(parcels);

function ParcelsProvider(props: any) {
  const [parcelsState, dispatchParcels] = useReducer(reducer, parcels);
  return <ParcelsContext.Provider value={{ parcelsState, dispatchParcels }}>{props.children}</ParcelsContext.Provider>;
}

export { ParcelsContext, ParcelsProvider };
