import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeading } from '../../components/common/page-heading';
import { EditConsignmentForm } from '../../components/edit-consignment-form';
import { HandlersProvider } from '../../context/handlers';

interface MatchParams {
  consignmentId: string;
}

export const EditConsignmentPage = ({ match }: RouteComponentProps<MatchParams>) => {
  const { consignmentId } = match.params;

  return (
    <Fragment>
      <PageHeading>Edit Consignment</PageHeading>
      <HandlersProvider>
        <EditConsignmentForm consignmentId={consignmentId} />
      </HandlersProvider>
    </Fragment>
  );
};
