import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import Helmet from 'react-helmet';
import { withRoot } from './theme';
import { Routes } from './routes';
import { Main } from './components/main';
import { Header } from './components/header';
import config from './config';
import './assets/scss/main.scss';
import withAuthenticationProvider from './containers/with-authentication-provider';
import { AuthState } from '@aws-amplify/ui-components';
import { useAuthState, useUserGroups } from './context/authentication';
import { SignInBrand } from './components/sign-in-brand';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AlertDialog } from './components/common/alert-dialog';
import withSessionTimeout from './containers/with-session-timeout';
import { pallete } from './components/main/materialStyles';
import { CSSProperties } from '@material-ui/styles';

Amplify.configure({
  Auth: config.auth,
  AWSS3: config.AWSS3
});

const AuthenticationPage = () => {
  const style: CSSProperties = {
    '--amplify-primary-color': pallete.primary.main,
    '--amplify-primary-tint': pallete.primary.tint,
    '--amplify-primary-shade': pallete.primary.shade
  };

  return (
    <div>
      <SignInBrand />
      <AmplifyAuthenticator style={style}>
        <AmplifySignIn slot="sign-in" hideSignUp />
      </AmplifyAuthenticator>
    </div>
  );
};

const Home = (
  <BrowserRouter>
    <Helmet titleTemplate="%s - Customs Reprocessing System" />
    <Header />
    <Main>
      <Routes />
    </Main>
  </BrowserRouter>
);

const NoGroupAlert = () => (
  <AlertDialog open onClose={() => Auth.signOut()}>
    <Fragment>User does not belong to any group</Fragment>
  </AlertDialog>
);

const App = () => {
  const state = useAuthState();
  const userGroups = useUserGroups();
  return state && state === AuthState.SignedIn
    ? userGroups && userGroups.length > 0
      ? Home
      : NoGroupAlert()
    : AuthenticationPage();
};

export default withRoot(withAuthenticationProvider(withSessionTimeout(App)));
