import React, { useState } from 'react';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { addParcel } from '../../../../services/api';
import {formatAlphanumericInput, isValidBarcode} from '../../../../helpers';
import { StatusPopup } from '../../../common/status-popup';
import { customTheme } from '../../../main/materialStyles';
import { useUserGroups } from "../../../../context/authentication";

interface Props {
  consignmentId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    progressWrapper: {
      position: 'relative'
    },
    fabProgress: {
      position: 'absolute',
      top: theme.spacing(0),
      zIndex: 1
    },
    scanForm: {
      marginTop: theme.spacing(3)
    },
    statusContainer: {
      margin: theme.spacing(0, 0, 0, 2)
    }
  })
);

export const ScanParcel = ({ consignmentId }: Props) => {
  const classes = useStyles();
  const states = {
    NEW: 'new',
    IN_PROGRESS: 'inprogress',
    ERRORED: 'errored',
    ALREADY_ASSIGNED: 'already_assigned',
    DATA_MISSING: 'data_missing',
    SUCCEDED: 'succeded',
    ALREADY_RELEASED: 'already_released',
    INVALID: 'invalid'
  };

  const [scanState, setScanState] = useState(states.NEW);
  const [upi, setUPI] = useState('');
  const [scannedUPI, setScannedUPI] = useState('');
  const groups = useUserGroups();
  const isWrexhamUser = groups.includes("WREXHAM");

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13) {
      event.preventDefault();
      const { value } = event.target as HTMLInputElement;
      if (!value) {
        return;
      }
      setScanState(states.IN_PROGRESS);
      setScannedUPI(value);

      try {
        if ( !isValidBarcode(upi, isWrexhamUser) ) {
          // Reject the scan if the UPI is an invalid type of barcode
          setScanState(states.INVALID);
        } else {
          const result: any = await addParcel(consignmentId, value);
          if (result.error) {
            setScanState(states.ALREADY_ASSIGNED);
          } else {
            setScanState(states.SUCCEDED);
          }
        }
      } catch (error) {
        // @todo: Make error handling to work without HTTP status codes
        switch (error.response.status) {
          case 400:
            setScanState(states.ALREADY_ASSIGNED);
            break;
          case 430:
            setScanState(states.DATA_MISSING);
            break;
          case 460:
            setScanState(states.ALREADY_RELEASED);
            break;
          default:
            setScanState(states.ERRORED);
        }
      } finally {
        setUPI('');
      }
    }
  };

  const handleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (scanState == states.INVALID) {
      /* Reset the scan state on user input. Helps fix an issue where messages
      were appearing every time user pressed a key */
      setScanState(states.NEW);
    }
    const element = event.target as HTMLInputElement;
    setUPI(formatAlphanumericInput(element.value));
  };

  const renderStatus = (status: string, scannedParcelUPI: string) => {
    const acceptedStatusBox = <StatusPopup text="Accepted" type="SUCCESS" upi={scannedParcelUPI} />;
    const noOrderDataStatusBox = <StatusPopup text="Parcel not found" type="ERROR" upi={scannedParcelUPI} />;
    const dataMissingStatusBox = <StatusPopup text="Data missing" type="AMBER" upi={scannedParcelUPI} />;
    const alreadyAssignedStatusBox = (
      <StatusPopup text="Parcel has been already scanned" type="SUCCESS" upi={scannedParcelUPI} />
    );
    const alreadyReleasedStatusBox = (
      <StatusPopup text={`Consignment ${consignmentId} has been already released!`} type="ERROR" />
    );
    const progressIndicator = (
      <div className={classes.progressWrapper}>
        <CircularProgress size={50} className={classes.fabProgress} />
      </div>
    );

    // Special case for 'invalid' scan: must include a unique key value so the status popup message code recognises it as a new
    // instance of the popup. Fixes issue where no error message appears if an invalid code is scanned more than once.
    const invalidBarcodeScanned = (
      <StatusPopup text="Invalid Barcode Scanned" type="INVALID" upi={scannedParcelUPI} key={Math.random()} />
    );

    return (
      {
        [states.NEW]: null,
        [states.SUCCEDED]: acceptedStatusBox,
        [states.IN_PROGRESS]: progressIndicator,
        [states.ALREADY_ASSIGNED]: alreadyAssignedStatusBox,
        [states.ERRORED]: noOrderDataStatusBox,
        [states.DATA_MISSING]: dataMissingStatusBox,
        [states.ALREADY_RELEASED]: alreadyReleasedStatusBox,
        [states.INVALID]: invalidBarcodeScanned
      }[status] || null
    );
  };

  const messageStatusPopup = (
    <MuiThemeProvider theme={customTheme}>
      <form className={classes.scanForm}>
        <Grid container className={classes.root} direction="row" alignItems="stretch">
          <Grid item xs={4}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="off"
              onKeyPress={handleKeyPress}
              onInput={handleInput}
              label="Unique Parcel Identifier"
              variant="outlined"
              name="upi"
              id="upi"
              value={upi}
              inputProps={{
                maxLength: 25
              }}
            />
          </Grid>
          <Grid item xs className={classes.statusContainer}>
            {renderStatus(scanState, scannedUPI)}
          </Grid>
        </Grid>
      </form>
    </MuiThemeProvider>
  );

  return messageStatusPopup;
};
