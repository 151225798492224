import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { Theme } from '@material-ui/core';
import { CustomCalendar } from './customCalendar';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '5px',
    justifyContent: 'space-between'
  },
  textField: {
    margin: '0 10px'
  },
  selectField: {
    width: '90%',
    margin: 0
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  cell: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 10
  },
  tooltipsCell: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 10,
    width: '86px',
    padding: '0 16px'
  }
});

const labelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '0.75rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem'
      }
    }
  })
);

const inputStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      fontSize: '0.85rem',
      padding: theme.spacing(2, 1),
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem',
        padding: theme.spacing(2)
      }
    }
  })
);

const labelDateStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '0.75rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '0.85rem'
      }
    }
  })
);

// @ts-ignore
export const Filters = ({ filter, dateFilter, handleInputTyping, values, rows }) => {
  const classes = useStyles();
  const labelClasses = labelStyles();
  const inputClasses = inputStyles();
  const labelDateClasses = labelDateStyles();
  const forms = () => {
    const filtersMarkUp = [];
    for (const key in rows) {
      if (rows[key].fieldType === 'select') {
        filtersMarkUp.push(
          <td className={classes.cell} key={key}>
            <form className={classes.container} noValidate autoComplete="off">
              <TextField
                id={rows[key].id}
                label={rows[key].filter_label || rows[key].label}
                select
                className={classes.selectField}
                InputLabelProps={{ classes: labelClasses }}
                InputProps={{ classes: inputClasses }}
                name={rows[key].id}
                value={values[rows[key].id]}
                onChange={filter}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {rows[key].options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </td>
        );
      } else if (rows[key].fieldType === 'date') {
        filtersMarkUp.push(
          <td className={classes.cell} key={key}>
            <form className={classes.container} noValidate autoComplete="off">
              <CustomCalendar dateFilter={dateFilter} />
            </form>
          </td>
        );
      } else if (rows[key].fieldType === 'text') {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
          event.target.value ? handleInputTyping(event) : filter(event);
        filtersMarkUp.push(
          <td className={classes.cell} key={key}>
            <form className={classes.container} noValidate autoComplete="off">
              <TextField
                id={rows[key].id}
                label={rows[key].filter_label || rows[key].label}
                type="search"
                className={classes.textField}
                InputLabelProps={{ classes: labelClasses }}
                InputProps={{ classes: inputClasses }}
                name={rows[key].id}
                value={values[rows[key].id]}
                onChange={handleChange}
                onKeyDown={filter}
                margin="normal"
                variant="outlined"
              />
            </form>
          </td>
        );
      } else if (rows[key].fieldType === 'disabled') {
        filtersMarkUp.push(
          <td className={classes.cell} key={key}>
            <form className={classes.container} noValidate autoComplete="off">
              <TextField
                className={classes.textField}
                InputLabelProps={{ classes: labelClasses }}
                InputProps={{ classes: inputClasses }}
                margin="normal"
                type="hidden"
                disabled
              />
            </form>
          </td>
        );
      } else if (rows[key].fieldType === 'tooltips') {
        filtersMarkUp.push(<td className={classes.tooltipsCell} key={key} />);
      }
    }
    return filtersMarkUp;
  };

  return <>{forms()}</>;
};
