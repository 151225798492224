export interface IConsignmentEntity {
  consignmentId: string;
  handler: string;
  origin: string;
  purpose: string;
  userDefinedId: string;
  created: string;
  trailerType: string;
}

export const createEmptyConsignment = () => ({
  consignmentId: '',
  handler: '',
  origin: '',
  purpose: '',
  userDefinedId: '',
  created: '',
  trailerType: ''
});
