import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const commonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '27px 23px',
      whiteSpace: 'nowrap'
    },
    button: {
      margin: '10px 5px 0',
      float: 'right'
    },
    table: {
      tableLayout: 'fixed'
    },
    usersWrapper: {
      overflow: 'overlay',
      minHeight: window.innerHeight - 200 + 'px'
    },
    loader: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      height: '100%'
    },
    emptyData: {
      opacity: 0.5
    },
    paper: {
      textAlign: 'center'
    },
    paperLeft: {
      paddingLeft: '50px !important'
    },
    checkbox: {
      display: 'flex'
    },
    listItem: {
      flexDirection: 'column'
    },
    listNames: {
      padding: '13px 23px'
    },
    listHeader: {
      fontWeight: 'bold'
    },
    formStyles: {
      display: 'grid'
    },
    container: {
      padding: theme.spacing(3, 0, 0),
      width: theme.spacing(62),
      margin: '0 auto'
    },
    formInput: {
      marginTop: theme.spacing(4)
    },
    buttonsHolder: {
      margin: theme.spacing(4, -1.5)
    },
    flexDisplay: {
      display: 'flex'
    }
  })
);
