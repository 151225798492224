import React, { Fragment } from 'react';
import { Typography, Divider, Grid, List, ListItem } from '@material-ui/core';
import { IResolvedDiscrepancyEntity } from '../../model';
import labels from '../../../../dictionaries/discrepancy-labels.json';

interface IResolvedDiscrepancyChangesListProps {
  resolvedDiscrepancy: IResolvedDiscrepancyEntity;
  resolvedCommodityChildrenList: any;
}

interface IDiscrepancyLabels {
  [key: string]: string;
}

export const ResolvedDiscrepancyChangesList = (props: IResolvedDiscrepancyChangesListProps) => {
  const { resolvedDiscrepancy, resolvedCommodityChildrenList } = props;

  return (
    <Fragment>
      <List component="ul">
        {Object.keys(resolvedDiscrepancy).map((value: string) => (
          <ListItem component="li" key={value}>
            <Grid container>
              <Grid item xs>
                {(labels as IDiscrepancyLabels)[value as string]}
              </Grid>
              <Grid item xs>
                <strong>{resolvedDiscrepancy[value as keyof IResolvedDiscrepancyEntity]}</strong>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      {resolvedCommodityChildrenList.map((value: any) => (
        <Fragment key={value.item.id}>
          <Typography variant="subtitle1">{value.item.ItemDescription}</Typography>
          <Divider />
          <List component="ul">
            {Object.keys(value.resolvedFields).map((propName: any) => (
              <ListItem component="li" key={propName}>
                <Grid container>
                  <Grid item xs>
                    {(labels as IDiscrepancyLabels)[propName as string]}
                  </Grid>
                  <Grid item xs>
                    <strong>{value.resolvedFields[propName as keyof IResolvedDiscrepancyEntity]}</strong>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Fragment>
      ))}
    </Fragment>
  );
};
