import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase, Paper, Theme, Typography } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { URLS } from '../../constants';
import { abortParcel, addItemsToParcel } from '../../services/api';
import { ConsignmentInfo } from '../consignment-info';
import { ScanParcelItem } from './components/scan-parcel-item';
import { ScanParcelLabel } from './components/scan-parcel-label';
import { AdvancedSearchForm } from './components/advanced-search-form';
import { IConsignmentEntity } from '../../models/consignment';

interface IAddParcelToReturnProps {
  consignment: IConsignmentEntity;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    pageHeading: {
      fontSize: 26,
      fontWeight: 100,
      margin: theme.spacing(1, 0, 3)
    },
    scanBox: {
      padding: theme.spacing(4),
      height: '100%',
      margin: theme.spacing(1, 0)
    },
    btnLink: {
      textTransform: 'none',
      margin: theme.spacing(0, 0, 0, 0.5)
    },
    advancedSearchCaption: {
      margin: theme.spacing(3, 0, 0)
    },
    opened: {
      transform: 'rotate(180deg)'
    },
    advancedSearchOpener: {
      padding: theme.spacing(1, 0, 0)
    },
    advancedSearchTitle: {
      color: '#d83d3d'
    },
    scanParcelLabelCaption: {
      fontStyle: 'italic'
    }
  })
);

export const AddParcelToReturn = (props: IAddParcelToReturnProps) => {
  const { consignment }: { consignment: IConsignmentEntity } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const parcelLabelInputRef = useRef<HTMLInputElement>(null);
  const parcelItemLabelInputRef = useRef<HTMLInputElement>(null);
  const ConsignmentsListLink = forwardRef((linkProps: any, ref) => (
    <Link to={URLS.CONSIGNMENT_LIST} {...linkProps} ref={ref} />
  ));
  const [parcelUPI, setParcelUPI] = useState('');
  const [enteringFinished, setEnteringFinished] = useState(false);
  const [isAdvancedSearchOpened, setIisAdvancedSearchOpened] = useState(false);
  const [parcelItems, setParcelItems] = useState<string[]>([]);
  const [isParcelClosingInProgress, setIsParcelClosingInProgress] = useState(false);

  useEffect(() => {
    if (!enteringFinished && parcelLabelInputRef && parcelLabelInputRef.current) {
      parcelLabelInputRef.current.focus();
    }
    if (enteringFinished && parcelItemLabelInputRef && parcelItemLabelInputRef.current) {
      parcelItemLabelInputRef.current.focus();
    }
  }, [enteringFinished, parcelItems]);

  const closeParcel = async () => {
    if (!parcelItems.length) {
      enqueueSnackbar(`Empty parcel can not be added to consignment`, { variant: 'error' });
      return;
    }
    setIsParcelClosingInProgress(true);
    try {
      const parcelItemsKeys = parcelItems.map((item: any) => item.EWS);
      const result = await addItemsToParcel(parcelUPI, consignment.consignmentId, parcelItemsKeys);
      if (result) {
        enqueueSnackbar(`Parcel ${parcelUPI} added to consignment`, { variant: 'success' });
        setParcelUPI('');
        setEnteringFinished(false);
        setParcelItems([]);
        setIisAdvancedSearchOpened(false);
        setIsParcelClosingInProgress(false);
      }
    } catch (error) {
      switch (error.response.status) {
        case 460:
          enqueueSnackbar(`Consignment ${consignment.consignmentId} has been already released!`, { variant: 'error' });
          break;
        case 400:
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
          break;
        default:
          break;
      }
    }
    setIsParcelClosingInProgress(false);
  };

  const handleCompleteParcelItemEWS = (item: any) => {
    if (typeof item === 'string') {
      if (parcelUPI === item) {
        closeParcel();
      }
    } else {
      addParcelItem(item);
    }
  };

  const onComplete = (upi: string) => {
    setEnteringFinished(true);
    setParcelUPI(upi);
  };

  const addParcelItem = (item: any) => {
    setParcelItems([...parcelItems, item]);
  };

  const handleParcelAbort = () => {
    if (isParcelClosingInProgress) {
      return;
    }
    abortParcel(parcelUPI);
    enqueueSnackbar(`Parcel ${parcelUPI} has been cancelled`, { variant: 'success' });
    setParcelUPI('');
    setEnteringFinished(false);
    setParcelItems([]);
    setIisAdvancedSearchOpened(false);
  };

  const handleToggleAdvancedSearch = (event: React.MouseEvent) => {
    event.preventDefault();
    setIisAdvancedSearchOpened(!isAdvancedSearchOpened);
  };
  return (
    <Fragment>
      <ButtonBase component={ConsignmentsListLink} className={classes.btnLink}>
        <ChevronLeftIcon />
        <Typography id="back-to-list">Back to list</Typography>
      </ButtonBase>
      <Typography className={classes.pageHeading}>Add Parcels</Typography>
      <ConsignmentInfo {...consignment} />
      <Paper className={classes.scanBox}>
        <Typography>1. Please scan the return bar-code on parcel packaging</Typography>
        <ScanParcelLabel
          onComplete={onComplete}
          onUPIChange={setParcelUPI}
          onParcelCancel={handleParcelAbort}
          onParcelClose={closeParcel}
          enteringFinished={enteringFinished}
          upi={parcelUPI}
          parcelLabelInputRef={parcelLabelInputRef}
          isParcelClosingInProgress={isParcelClosingInProgress}
        />
      </Paper>
      {parcelUPI && enteringFinished && (
        <Paper className={classes.scanBox}>
          <Typography>2. Please Scan the return slip or enter item number.</Typography>
          <Typography className={classes.scanParcelLabelCaption}>
            Once all scans complete please re-scan the return bar-code to seal the parcel and add it to the consignment
          </Typography>
          <ScanParcelItem
            parcelUPI={parcelUPI}
            parcelItems={parcelItems}
            onComplete={handleCompleteParcelItemEWS}
            parcelItemLabelInputRef={parcelItemLabelInputRef}
          />
          <Typography className={classes.advancedSearchOpener}>
            Have some issues?
            <ButtonBase
              component={ConsignmentsListLink}
              className={classes.btnLink}
              onClick={handleToggleAdvancedSearch}
            >
              Try advanced search
              <span className={isAdvancedSearchOpened ? classes.opened : ''}>&#9662;</span>
            </ButtonBase>
          </Typography>
          {isAdvancedSearchOpened && (
            <Fragment>
              <Typography className={classes.advancedSearchCaption}>
                <strong className={classes.advancedSearchTitle}>Advanced search</strong> - Scan original parcel UPI or
                Scan any return slip bar code then select item from list
              </Typography>
              <AdvancedSearchForm onKeyAdded={addParcelItem} parcelItems={parcelItems} />
            </Fragment>
          )}
        </Paper>
      )}
    </Fragment>
  );
};
