import React, { Fragment } from 'react';
import { CreateConsignmentForm } from '../../components/create-consignment-form';
import { HandlersProvider } from '../../context/handlers';
import { PageHeading } from '../../components/common/page-heading';

export const CreateConsignmentPage = () => (
  <Fragment>
    <PageHeading>New Consignment</PageHeading>
    <HandlersProvider>
      <CreateConsignmentForm />
    </HandlersProvider>
  </Fragment>
);
