import {
  Button,
  Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup, Modal,
  TextField,
  Theme
} from '@material-ui/core';
import React, { useState } from 'react';
import { commonStyles } from '../../styles';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { EmptyPopup, handleAPIException, PopupDefinition, StatusPopup } from '../../components/common/status-popup';
import { createUser } from '../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '0 auto'
    },
    formInput: {
      marginTop: theme.spacing(2)
    },
    buttonsHolder: {
      margin: theme.spacing(4, -1.5)
    },
    inlineBlock: {
      display: 'inline-block'
    }
  })
);

interface UsersInfo {
  users: any;
  userGroups: any;
  onUserCreated: () => void;
}

interface NewUserForm {
  username: string;
  email: string;
  groups: any;
}

export const NewUserPage = (props: UsersInfo) => {
  const classes = commonStyles();
  const jjStyles = useStyles();
  const { users, userGroups, onUserCreated } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [popup, setPopup] = React.useState(EmptyPopup);
  const initData = {
    username: '',
    email: '',
    groups: {}
  };
  const [data, setData] = React.useState<NewUserForm>(initData);
  const [inputFlag, setinputFlag] = useState(false);
  const userNames: any = [];
  const emails: any = [];
  for (const userId in users) {
    userNames.push(users[userId].username);
    emails.push(users[userId].email);
  }

  const handleClickOpen = async () => {
    setPopup(EmptyPopup);
    const groups: any = {};
    for (const group in userGroups) {
      groups[userGroups[group]] = false;
    }
    setData({ ...data, groups });
    setinputFlag(false);
    setOpen(true);
  };

  const handleClose = () => {
    setData(initData);
    setOpen(false);
  };

  function validateEmail(email: string) {
    const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/;
    return re.test(String(email).toLowerCase());
  }

  function checkCheckpoints(groups: any) {
    let flag: boolean = false;
    for (const carrier in groups) {
      if (groups[carrier] === true) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  const handleSubmit = async (event: React.FormEvent) => {
    if (data.username === '') {
      setPopup(PopupDefinition.create('AMBER', 'Username must be not blank'));
    } else if (userNames.includes(data.username)) {
      setPopup(PopupDefinition.create('AMBER', 'Username already exists'));
    } else if (!validateEmail(data.email)) {
      setPopup(PopupDefinition.create('AMBER', 'Incorrect email'));
    } else if (emails.includes(data.email)) {
      setPopup(PopupDefinition.create('AMBER', 'Email already exists'));
    } else if (!checkCheckpoints(data.groups)) {
      setPopup(PopupDefinition.create('AMBER', 'Please set at least 1 group'));
    } else {
      try {
        setLoading(true);
        const user = { ...data };
        await createUser(user);
        setPopup(PopupDefinition.create('SUCCESS', 'User "' + user.username + '" created successfully'));
        setLoading(false);
        setData({ ...initData });
        setOpen(false);
        await onUserCreated();
      } catch (e) {
        handleAPIException(e, setPopup);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | any>) => {
    setPopup(EmptyPopup);
    event.persist();
    const { name, value } = event.target;
    const newData = { ...data };
    if (name === 'carrier') {
      const carrierId = event.target.id;
      newData.groups[carrierId] = !newData.groups[carrierId];
      setData(newData);
    } else {
      // @ts-ignore
      newData[name] = value;
      setData(newData);
    }
    if (data.username !== '' && data.email !== '' && checkCheckpoints(data.groups)) {
      setinputFlag(true);
    } else {
      setinputFlag(false);
    }
  };

  let popupMessage = <></>;
  if (popup.type) {
    // @ts-ignore
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }
  return (
    <Grid item>
      <Modal open={loading}>
        <Grid container className={classes.loader} justify="center">
          <CircularProgress disableShrink />
        </Grid>
      </Modal>
      {popupMessage}
      <Button className={classes.button} color="primary" onClick={handleClickOpen} variant="contained">
        + New user
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle id="alert-dialog-title">{'Create new User'}</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid className={jjStyles.container}>
              <FormControl fullWidth variant="outlined" className={jjStyles.formInput}>
                <TextField
                  name="username"
                  variant="outlined"
                  label="username"
                  onChange={handleChange}
                  helperText="Username must be unique"
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" className={jjStyles.formInput}>
                <TextField name="email" variant="outlined" label="email" type="email" onChange={handleChange} />
              </FormControl>
              <FormControl fullWidth variant="outlined" className={jjStyles.formInput}>
                <FormGroup className={jjStyles.inlineBlock}>
                  {Object.keys(data.groups).map((carrier) => {
                    return (
                      <FormControlLabel
                        key={carrier}
                        control={
                          <Checkbox
                            checked={data.groups[carrier]}
                            onChange={handleChange}
                            id={carrier}
                            name={'carrier'}
                          />
                        }
                        label={carrier}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!inputFlag} variant="contained" onClick={handleSubmit} color="primary" autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
