import React, { Fragment, useContext, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles, Theme, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { PageHeading } from '../../components/common/page-heading';
import { ConsignmentsList } from '../../components/consignments-list';
import { ConsignmentsProvider } from '../../context/consignments';
import { HandlersContext } from '../../context/handlers';
import { StatusPopup } from '../../components/common/status-popup';
import { getHandlers } from '../../services/api';
import withHandlersProvider from '../../containers/with-handler-provider';
import { HTTP_ERROR_MESSAGE } from '../../constants';
import { customTheme } from '../../components/main/materialStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: '10px 0 0',
      float: 'right'
    },
    addIcon: {
      marginRight: theme.spacing(1),
      fontSize: 20
    }
  })
);

export const ConsignmentsPage = withHandlersProvider(() => {
  const classes = useStyles();
  const CreateConsignmentLink = forwardRef((props: any, ref) => <Link to="/create-consignment" {...props} ref={ref} />);
  const { dispatchHandlers } = useContext(HandlersContext);

  const [error, setError] = React.useState('');

  useEffect(() => {
    (async () => {
      try {
        const body = await getHandlers();
        dispatchHandlers({ type: 'set', body });
      } catch (e) {
        console.error(e);
        setError(HTTP_ERROR_MESSAGE);
      }
    })();
  }, []);

  let errorMsg = <></>;
  if (error !== '') {
    errorMsg = <StatusPopup text={error} type="ERROR" />;
  }
  return (
    <MuiThemeProvider theme={customTheme}>
      <Fragment>
        {errorMsg}
        <Grid container>
          <Grid item xs={7}>
            <PageHeading>Consignments</PageHeading>
          </Grid>
          <Grid item xs={5}>
            <Button
              id="create-new-button"
              variant="contained"
              className={classes.button}
              color="primary"
              component={CreateConsignmentLink}
            >
              <AddIcon className={classes.addIcon} />
              Create new
            </Button>
          </Grid>
        </Grid>
        <ConsignmentsProvider>
          <ConsignmentsList />
        </ConsignmentsProvider>
      </Fragment>
    </MuiThemeProvider>
  );
});
