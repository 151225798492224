import { useContext, useState } from 'react';
import { HandlersContext } from '../context/handlers';
import { IHandler } from '../models/handler';
import { createEmptyConsignment } from '../components/create-consignment-form/model';

export const useCreateConsignmentForm = () => {
  const { handlersState } = useContext(HandlersContext);

  const initForm = (fieldValue: string) => {
    let origin: string;
    let purpose = '';
    const originList: any[] = [];
    if (handlersState.handlers) {
      if (fieldValue === 'DHL') {
        originList.push('ARROWXL');
      }
      handlersState.handlers.handlers.map((handler: IHandler) => {
        if (fieldValue === handler.PK) {
          originList.push(handler.origin);
          purpose = handler.purpose;
        }
      });
    }
    origin = originList.toString();
    return { userDefinedId: '', handler: fieldValue, origin, purpose };
  };

  const [values, setValues] = useState(createEmptyConsignment());
  return { values, setValues, initForm };
};
