import React, { useState } from 'react';
import { makeStyles, createStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { CircularProgress, Grid, TextField, Typography, Theme } from '@material-ui/core';
import { Cancel as CancelIcon, Check as CheckIcon } from '@material-ui/icons';
import { SearchResultsList } from './components/search-results-list';
import { formatAlphanumericInput } from '../../../../helpers';
import { searchParcelItems } from '../../../../services/api';
import { customTheme } from '../../../main/materialStyles';

interface IScanParcelItemProps {
  parcelItems: any;
  onKeyAdded(key: string): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    progressWrapper: {
      position: 'relative'
    },
    fabProgress: {
      position: 'absolute',
      top: theme.spacing(0),
      zIndex: 1
    },
    scanForm: {
      margin: theme.spacing(3, 0, 0)
    },
    statusContainer: {
      margin: theme.spacing(0, 0, 0, 2)
    },
    error: {
      background: '#fed5d5',
      color: '#bf3939',
      height: '100%',
      borderRadius: theme.spacing(0.5),
      padding: '10px',
      lineHeight: '36px'
    },
    success: {
      background: '#edf5d1',
      color: '#528618',
      height: '100%',
      borderRadius: '5px',
      padding: '10px',
      lineHeight: '36px'
    },
    amber: {
      background: '#fae3d7',
      color: '#bd561c',
      height: '100%',
      borderRadius: '5px',
      padding: '10px',
      lineHeight: '36px'
    },
    statusIcon: {
      float: 'left',
      margin: '5px 5px 0 0'
    }
  })
);

export const AdvancedSearchForm = (props: IScanParcelItemProps) => {
  const classes = useStyles();
  const { parcelItems } = props;
  const states = {
    NEW: 'new',
    IN_PROGRESS: 'inprogress',
    ERRORED: 'errored',
    SUCCEDED: 'succeded'
  };

  const [scanState, setScanState] = useState(states.NEW);
  const [isEnteringFinished, setIsEnteringFinished] = useState(false);
  const [upi, setUPI] = useState('');
  const [searchParcelItemsResults, setSearchParcelItemsResult] = useState([]);
  const [addedItem, setAddedItem] = useState<{ SKU: string; ItemDescription: string }>({
    SKU: '',
    ItemDescription: ''
  });

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13) {
      event.preventDefault();
      setIsEnteringFinished(true);
      setSearchParcelItemsResult([]);
      const { value } = event.target as HTMLInputElement;
      try {
        setScanState(states.IN_PROGRESS);
        const result: any = await searchParcelItems(value);
        setSearchParcelItemsResult(result.items);
        setScanState(states.NEW);
      } catch (e) {
        setScanState(states.ERRORED);
      } finally {
        setUPI('');
      }
    } else {
      setScanState(states.NEW);
      if (isEnteringFinished) {
        setIsEnteringFinished(false);
        setUPI('');
      }
    }
  };

  const handleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    setUPI(formatAlphanumericInput(element.value));
  };

  const renderStatus = (status: string) => {
    const progressIndicator = (
      <div className={classes.progressWrapper}>
        <CircularProgress size={50} className={classes.fabProgress} />
      </div>
    );
    const acceptedStatusBox = (
      <Typography variant="subtitle1" className={classes.success}>
        <CheckIcon className={classes.statusIcon} />
        Item <strong>{addedItem.SKU}</strong> - <strong>{addedItem.ItemDescription}</strong> added to parcel
      </Typography>
    );
    const noItemsFoundStatusBox = (
      <Typography variant="subtitle1" className={classes.amber}>
        <CancelIcon className={classes.statusIcon} />
        Item is not found. Please try to search by another field or abort parcel
      </Typography>
    );

    return (
      {
        [states.NEW]: null,
        [states.IN_PROGRESS]: progressIndicator,
        [states.SUCCEDED]: acceptedStatusBox,
        [states.ERRORED]: noItemsFoundStatusBox
      }[status] || null
    );
  };

  const handleSelectItem = (item: any) => {
    props.onKeyAdded(item);
    const { SKU, ItemDescription } = item.SKU;
    setAddedItem({ SKU, ItemDescription });
    setScanState(states.SUCCEDED);
  };

  return (
    <form className={classes.scanForm}>
      <MuiThemeProvider theme={customTheme}>
      <Grid container className={classes.root}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            autoFocus
            inputProps={{
              maxLength: 25
            }}
            label="Original parcel UPI/EWS of other item"
            autoComplete="off"
            onKeyPress={handleKeyPress}
            onInput={handleInput}
            variant="outlined"
            name="upi"
            id="upi"
            value={upi}
          />
        </Grid>
        <Grid item xs className={classes.statusContainer}>
          {renderStatus(scanState)}
        </Grid>
      </Grid>
      {searchParcelItemsResults.length > 0 && (
        <SearchResultsList items={searchParcelItemsResults} parcelItems={parcelItems} onSelect={handleSelectItem} />
      )}
    </MuiThemeProvider>
    </form>
  );
};
