import { Storage } from 'aws-amplify';
import config from '../../config';

interface StorageCustomPrefix {
  public?: string;
  protected?: string;
  private?: string;
}

const DefaultStorageCustomPrefix: StorageCustomPrefix = {
  public: config.app.environment + '/'
};

interface StorageConfig {
  bucket?: string;
  expires?: number;
  customPrefix?: StorageCustomPrefix;
}

const DefaultStorageConfig: StorageConfig = {
  bucket: config.AWSS3.bucket,
  expires: 60,
  customPrefix: DefaultStorageCustomPrefix
};

interface GetFileURLNamedParameters {
  bucket?: string;
  key: string;
  expires?: number;
  customPrefix?: StorageCustomPrefix;
}

export const getFileURL = async ({
  bucket = DefaultStorageConfig.bucket,
  key,
  expires = DefaultStorageConfig.expires,
  customPrefix = DefaultStorageConfig.customPrefix
}: GetFileURLNamedParameters) => {
  try {
    if (customPrefix !== undefined && customPrefix.public !== undefined && key.startsWith(customPrefix.public)) {
      key = key.replace(customPrefix.public, '');
    }
    const link = await Storage.get(key, { bucket, expires, customPrefix });
    if (typeof link === 'string') {
      return link;
    }
  } catch (e) {
    console.error(e);
  }
};
