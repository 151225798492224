import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { customTheme } from '../../components/main/materialStyles';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { PageHeading } from '../../components/common/page-heading';
import {
  Assessment,
  Code,
  CropSquare,
  DeleteForever,
  DirectionsBoat,
  ExpandMore,
  GridOn,
  Inbox,
  List as ListIcon,
  LocalAtm,
  LocalShipping,
  Money,
  PictureAsPdf,
  Repeat,
  SettingsInputComponent
} from '@material-ui/icons';
import {
  getFeatures,
  getSettings,
  regenerateConsignmentDocs,
  removeUpisFromConsignment,
  updateExchangeRate,
  updateFreightCharges,
  updateSftpConfigs,
  updateStatisticalValue
} from '../../services/api';
import { EmptyPopup, handleAPIException, PopupDefinition, StatusPopup } from '../../components/common/status-popup';
import { commonStyles } from '../../styles';
import AlertDialog from './historyPopup';
import moment from 'moment';
import { IFeatureToggle } from '../../components/feature-toggle-component';

interface ICSVData {
  fastway: boolean;
  commercial_invoice: boolean;
  packing_list: boolean;
}

interface IPDFData {
  vat_commercial_invoice: boolean;
  customs_commercial_invoice: boolean;
  packing_list: boolean;
}

interface IAdminData {
  exchangeRate: string;
  consignment: string;
  crn_number: number;
  parcelLevelCrns: string;
  declaration: string;
  pdf: IPDFData;
  csv: ICSVData;
  sftpConfigs: any;
  smallParcelConfig: any;
  statistical_values: any;
  freightCharges: any;
}

const OUTBOUND_DELCARATIONS = ['None', 'All', 'UKExport', 'IEImport'];
const INBOUND_DELCARATIONS = ['None', 'All', 'UKImport', 'IEExport'];

const CONS_PREFIX_TO_DECLARATION: any = {
  A: OUTBOUND_DELCARATIONS,
  S: OUTBOUND_DELCARATIONS,
  X: OUTBOUND_DELCARATIONS,
  F: INBOUND_DELCARATIONS,
  D: INBOUND_DELCARATIONS,
  '': ['None']
};
const CONSIGNMENT_REGEXP = /[A,S,X,F,D]\d{4}$/;

const getExchangeRateUrl = (): string => {
  const NOW = moment();
  const CURRENT_MONTH = NOW.format('MMMM').toString().toLowerCase();
  const CURRENT_YEAR = NOW.format('YYYY').toString().toLowerCase();
  return (
    'https://www.revenue.ie/en/customs/businesses/importing-exporting/exchange-rates/' +
    CURRENT_YEAR +
    '/' +
    CURRENT_MONTH +
    '.aspx'
  );
};

const EXCHANGE_RATE_URL = getExchangeRateUrl();

const DEFAULT_ADMIN_DATA = {
  exchangeRate: '',
  consignment: '',
  crn_number: 0,
  parcelLevelCrns: '',
  declaration: 'None',
  pdf: {
    vat_commercial_invoice: true,
    customs_commercial_invoice: true,
    packing_list: true
  },
  csv: {
    fastway: false,
    commercial_invoice: true,
    packing_list: true
  },
  sftpConfigs: {},
  smallParcelConfig: {},
  statistical_values: {
    admin_charges: '',
    warehouse_picking: {
      one_person: '',
      two_person: {
        DC: '',
        DD: ''
      }
    },
    freight_cost: {}
  },
  freightCharges: {
    onePerson: {
      ddToTvgDc: '',
      ukFcPicking: '',
      tvgUkFcToIeDc: '',
      insurance: '',
      deliveryToCustomer: ''
    },
    twoPerson: {
      ddToTvgDc: '',
      ukFcPicking: '',
      tvgUkFcToIeDc: '',
      insurance: '',
      deliveryToCustomer: ''
    }
  }
};

export const AdminPage = () => {
  const classes = commonStyles();
  const [popup, setPopup] = React.useState(EmptyPopup);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<IAdminData>(DEFAULT_ADMIN_DATA);
  const [upiList, setUpiList] = React.useState('');
  const [configNames, setConfigNames] = React.useState([]);
  const [historyFlag, setHistoryFlag] = React.useState(false);
  const [lvcEnabled, setLvcEnabled] = React.useState(false);
  const [parcelLevel, setParcelLevel] = React.useState(false);

  const getConfigNames = (items: any) => {
    const tmpConfigNames: any = [];
    const newData = { ...items };

    Object.keys(items.sftpConfigs).map((carrier) => {
      Object.keys(items.sftpConfigs[carrier]).map((configName) => {
        if (!tmpConfigNames.includes(configName)) {
          tmpConfigNames.push(configName);
        }
      });
    });
    setConfigNames(tmpConfigNames);
    setData({ ...data, ...newData });
  };

  const getConsigmentPrefix = () => {
    if (!data.consignment) return '';
    const prefix = data.consignment[0];
    if (prefix && prefix in CONS_PREFIX_TO_DECLARATION) {
      return prefix;
    }
    return '';
  };
  const validateConsignment = () => {
    return data.consignment.match(CONSIGNMENT_REGEXP) != null;
  };

  const validateParcelLevelCrns = () => {
    return (
      !parcelLevel ||
      data.parcelLevelCrns.trim().length > 0 ||
      (!data.pdf.packing_list &&
        !data.pdf.vat_commercial_invoice &&
        data.declaration !== 'IEImport' &&
        data.declaration !== 'All')
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const body = await getSettings();
        getConfigNames(body);
      } catch (e) {
        handleAPIException(e, setPopup);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (lvcEnabled && getConsigmentPrefix() === 'S') {
      setParcelLevel(true);
    } else {
      setParcelLevel(false);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      const features = await getFeatures();
      setLvcEnabled(
        features.some(
          (feature: IFeatureToggle) => feature.featureName === 'ENABLE_LOW_VALUE_CONSIGNMENTS' && feature.isActive
        )
      );
    })();
  }, []);

  useEffect(() => {
    const upis = upiList.split('\n');
    if (upis.length === 1) {
      setHistoryFlag(true);
    } else {
      setHistoryFlag(false);
    }
  }, [upiList]);

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    setPopup(EmptyPopup);
    setLoading(true);
    try {
      switch (target.name) {
        case 'exchange':
          await updateExchangeRate(data.exchangeRate);
          break;
        case 'remove_upis':
          const upis = upiList.split('\n');
          await removeUpisFromConsignment(upis);
          break;
        case 'regenerate_docs':
          await regenerateConsignmentDocs({ ...data });
          setData(DEFAULT_ADMIN_DATA);
          break;
        case 'configs':
          await updateSftpConfigs({ ...data.sftpConfigs });
          break;
        case 'smallParcels':
          await updateSftpConfigs({ ...data.smallParcelConfig });
          break;
        case 'statisticValues':
          await updateStatisticalValue({ ...data.statistical_values });
          break;
        case 'freightCharges':
          await updateFreightCharges({ ...data.freightCharges });
          break;
      }
      setPopup(new PopupDefinition('SUCCESS'));
    } catch (e) {
      handleAPIException(e, setPopup);
    } finally {
      setLoading(false);
    }
  };

  const handleFormControlChange = (event: React.ChangeEvent<HTMLInputElement | any>) => {
    event.persist();
    let { name, value } = event.target;
    if (name.startsWith('csv_')) {
      const csv = { ...data.csv, [name.replace('csv_', '')]: event.target.checked };
      setData({ ...data, csv });
      return;
    }
    if (name.startsWith('pdf_')) {
      const pdf = { ...data.pdf, [name.replace('pdf_', '')]: event.target.checked };
      setData({ ...data, pdf });
      return;
    }
    switch (name) {
      case 'upiList':
        let values: any = value.replace(/\s/g, '\n');
        values = values.split('\n');
        values = values.map((n: string) => n.replace(/[^a-zA-Z0-9]/g, ''));
        setUpiList(values.join('\n'));
        return;
      case 'consignment':
        value = value.toUpperCase();
        data.consignment = value;
        if (!getConsigmentPrefix()) {
          data.declaration = 'None';
        }
        break;
      case 'sftp':
        let carrierConfig: any = [];
        carrierConfig = value.split(',');
        const configs: any = { ...data };
        configs.sftpConfigs[carrierConfig[0]][carrierConfig[1]] = !configs.sftpConfigs[carrierConfig[0]][
          carrierConfig[1]
        ];
        setData(configs);
        break;
      case 'smallParcel':
        let smallCarrierConfig: any = [];
        smallCarrierConfig = value.split(',');
        const smallData: any = { ...data };
        smallData.smallParcelConfig[smallCarrierConfig[0]][smallCarrierConfig[1]] = !smallData.smallParcelConfig[
          smallCarrierConfig[0]
        ][smallCarrierConfig[1]];
        setData(smallData);
        break;
      case 'adminCharges':
        const statAdminData: any = { ...data };
        statAdminData.statistical_values.admin_charges = value;
        setData(statAdminData);
        break;
      case 'one_person':
        const onePersonData: any = { ...data };
        onePersonData.statistical_values.warehouse_picking.one_person = value;
        setData(onePersonData);
        break;
      case 'two_person':
        const persons = event.target.id;
        const twoPersonData: any = { ...data };
        twoPersonData.statistical_values.warehouse_picking.two_person[persons] = value;
        setData(twoPersonData);
        break;
      case 'freightCost':
        const carrier = event.target.id;
        const statFreightCostData: any = { ...data };
        statFreightCostData.statistical_values.freight_cost[carrier] = value;
        setData(statFreightCostData);
        break;
      case 'freightCharges':
        const isOnePerson = event.target.dataset.onePerson === 'true';
        const chargeType = event.target.dataset.chargeType;
        const freightChargesData: any = { ...data };
        const warehouseTypeKey = isOnePerson ? 'onePerson' : 'twoPerson';
        value = typeof value === 'undefined' || !value ? '0.0' : value;
        freightChargesData.freightCharges[warehouseTypeKey][chargeType] = value;
        setData(freightChargesData);
        return;
    }
    setData({ ...data, [name]: value });
  };

  let popupMessage = <></>;
  if (popup.type && popup.message) {
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }
  const exchage_rate_section = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Money />
        <Typography>Set Exchange Rate</Typography>
      </AccordionSummary>
      <Paper>
        <form name="exchange" onSubmit={submitForm} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={2} className={classes.paper}>
              <TextField value={data.exchangeRate} name="exchangeRate" onChange={handleFormControlChange} />
            </Grid>
            <Grid item xs={2} className={classes.paper}>
              <Button variant="contained" className={classes.button} color="primary" type="submit">
                Submit
              </Button>
            </Grid>
            <Grid item xs={2} className={classes.paper}>
              <Button
                variant="outlined"
                className={classes.button}
                color="primary"
                type="submit"
                href={EXCHANGE_RATE_URL}
                target="_blank"
              >
                View Exchange Rate
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Accordion>
  );

  const remove_upis_section = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <DeleteForever />
        <Typography>Remove UPIs</Typography>
      </AccordionSummary>
      <Paper>
        <form name="remove_upis" onSubmit={submitForm} noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={2} className={classes.paper}>
              <TextField multiline rowsMax={5} name="upiList" value={upiList} onChange={handleFormControlChange} />
            </Grid>
            <Grid item xs={2} className={classes.paper}>
              <Button variant="contained" className={classes.button} color="primary" type="submit">
                Remove
              </Button>
            </Grid>
            <Grid item xs={1} className={classes.paper}>
              {AlertDialog(historyFlag, upiList)}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Accordion>
  );

  const parcelLevelCard = (
    <Card style={{ width: '95%', padding: '15px' }}>
      <CardHeader avatar={<GridOn />} title="Parcel Level Documents" />
      <Grid container direction="row" justify="space-around" spacing={3}>
        <Grid container direction="row" justify="space-around" spacing={3}>
          <Grid item xs={3} className={classes.paperLeft}>
            <Typography>CRNs (New line separated list of CRNs)</Typography>
          </Grid>
          <Grid item xs={9} className={classes.paper}>
            <TextField
              multiline
              name="parcelLevelCrns"
              value={data.parcelLevelCrns}
              onChange={handleFormControlChange}
              helperText={
                !validateParcelLevelCrns()
                  ? "Must be filled for Parcel Level documents. Type 'All' for all CRNs."
                  : "Type 'All' for all CRNs."
              }
              error={!validateParcelLevelCrns()}
            />
          </Grid>
        </Grid>
        <Card style={{ width: '95%', padding: '15px' }}>
          <CardHeader avatar={<PictureAsPdf />} title="PDF documents" />
          <Grid container direction="row" justify="space-around" spacing={3}>
            <Grid item xs={3} className={classes.paperLeft}>
              <Typography>Commercial Invoice</Typography>
            </Grid>
            <Grid item xs={9} className={classes.paper}>
              <Checkbox
                checked={data.pdf.vat_commercial_invoice}
                name="pdf_vat_commercial_invoice"
                onChange={handleFormControlChange}
              />
            </Grid>
            <Grid item xs={3} className={classes.paperLeft}>
              <Typography>Packing List</Typography>
            </Grid>
            <Grid item xs={9} className={classes.paper}>
              <Checkbox checked={data.pdf.packing_list} name="pdf_packing_list" onChange={handleFormControlChange} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Card>
  );

  const regenerateDocsTrailerLevelXmlCrnSection = (
    <>
      <Grid item xs={3} className={classes.paperLeft}>
        <Typography>CRN (set 0 for all CRNs)</Typography>
      </Grid>
      <Grid item xs={9} className={classes.paper}>
        <TextField
          type="number"
          helperText={data.crn_number < 0 && 'CRN cannot be negative'}
          error={data.crn_number < 0}
          name="crn_number"
          value={data.crn_number}
          onChange={handleFormControlChange}
        />
      </Grid>
    </>
  );

  const trailerLevelPdfCard = (
    <Card style={{ width: '95%', padding: '15px' }}>
      <CardHeader avatar={<PictureAsPdf />} title="PDF documents" />
      <Grid container direction="row" justify="space-around" spacing={3}>
        <Grid item xs={3} className={classes.paperLeft}>
          <Typography>VAT Commercial Invoice</Typography>
        </Grid>
        <Grid item xs={9} className={classes.paper}>
          <Checkbox
            checked={data.pdf.vat_commercial_invoice}
            name="pdf_vat_commercial_invoice"
            onChange={handleFormControlChange}
          />
        </Grid>
        <Grid item xs={3} className={classes.paperLeft}>
          <Typography>Customs Commercial Invoice</Typography>
        </Grid>
        <Grid item xs={9} className={classes.paper}>
          <Checkbox
            checked={data.pdf.customs_commercial_invoice}
            name="pdf_customs_commercial_invoice"
            onChange={handleFormControlChange}
          />
        </Grid>
        <Grid item xs={3} className={classes.paperLeft}>
          <Typography>Packing List</Typography>
        </Grid>
        <Grid item xs={9} className={classes.paper}>
          <Checkbox checked={data.pdf.packing_list} name="pdf_packing_list" onChange={handleFormControlChange} />
        </Grid>
      </Grid>
    </Card>
  );

  const csvCard = (
    <Card style={{ width: '95%', padding: '15px' }}>
      <CardHeader avatar={<ListIcon />} title="Commercial Invoice & Packing List CSVs" />
      <Grid container direction="row" justify="space-around" spacing={3}>
        <Grid item xs={3} className={classes.paperLeft}>
          <Typography>Commercial Invoice</Typography>
        </Grid>
        <Grid item xs={9} className={classes.paper}>
          <Checkbox
            checked={data.csv.commercial_invoice}
            name="csv_commercial_invoice"
            onChange={handleFormControlChange}
          />
        </Grid>

        <Grid item xs={3} className={classes.paperLeft}>
          <Typography>Packing List</Typography>
        </Grid>
        <Grid item xs={9} className={classes.paper}>
          <Checkbox checked={data.csv.packing_list} name="csv_packing_list" onChange={handleFormControlChange} />
        </Grid>
      </Grid>
    </Card>
  );

  const trailerLevelCard = (
    <Card style={{ width: '95%', padding: '15px' }}>
      <CardHeader avatar={<LocalShipping />} title="Trailer Level Documents" />
      <Grid container direction="row" justify="space-around" spacing={3}>
        {(!parcelLevel || data.declaration !== 'IEImport') &&
          data.declaration !== 'None' &&
          regenerateDocsTrailerLevelXmlCrnSection}
        {!parcelLevel && trailerLevelPdfCard}
        {csvCard}
      </Grid>
    </Card>
  );

  const regenerate_docs_section = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Repeat />
        <Typography>Regenerate Customs Documents</Typography>
      </AccordionSummary>
      <Paper>
        <form name="regenerate_docs" onSubmit={submitForm} noValidate autoComplete="off">
          <Grid container direction="row" justify="space-around" spacing={3}>
            <Grid item xs={3} className={classes.paperLeft}>
              <Typography>Consignment</Typography>
            </Grid>
            <Grid item xs={9} className={classes.paper}>
              <TextField
                value={data.consignment}
                error={!validateConsignment()}
                helperText={!validateConsignment() && 'Wrong Consignment number'}
                name="consignment"
                onChange={handleFormControlChange}
              />
            </Grid>
            <Card style={{ width: '95%', padding: '15px' }}>
              <CardHeader avatar={<Code />} title="XML declarations" />
              <Grid container direction="row" justify="space-around" spacing={3}>
                <Grid item xs={3} className={classes.paperLeft}>
                  <Typography>Declaration</Typography>
                </Grid>
                <Grid item xs={9} className={classes.paper}>
                  <Select
                    name="declaration"
                    value={data.declaration}
                    label="Declaration"
                    autoWidth
                    onChange={handleFormControlChange}
                  >
                    {CONS_PREFIX_TO_DECLARATION[getConsigmentPrefix()].map((value: any) => (
                      <MenuItem value={value} key={value}>
                        {value.replace(/UK|IE/, '')}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Card>
            {trailerLevelCard}
            {parcelLevel && parcelLevelCard}
            <Grid item xs={2} className={classes.paper}>
              <Button
                variant="contained"
                disabled={!validateConsignment() || data.crn_number < 0 || !validateParcelLevelCrns()}
                className={classes.button}
                color="primary"
                type="submit"
              >
                Regenerate
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Accordion>
  );

  const setupConfigsList = (items: any) => (
    <Grid className={classes.checkbox}>
      <Grid>
        <Typography className={classes.root}>Names</Typography>
        <List>
          {configNames.map((configName: string) => {
            return (
              <ListItem className={classes.listNames} key={configName}>
                <ListItemText>{configName}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      {Object.keys(items).map((carrier) => {
        return (
          <Grid key={carrier}>
            <Typography className={classes.root}>{carrier}</Typography>
            <List>
              {Object.keys(items[carrier]).map((configName) => {
                return (
                  <ListItem className={classes.listItem} key={carrier + configName}>
                    <Checkbox
                      checked={items[carrier][configName]}
                      disableRipple
                      name="sftp"
                      value={[carrier, configName]}
                      onChange={handleFormControlChange}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        );
      })}
    </Grid>
  );

  const set_sftp_configs = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <SettingsInputComponent />
        <Typography>Set SFTP Configs</Typography>
      </AccordionSummary>
      <Paper>
        <form name="configs" onSubmit={submitForm} noValidate autoComplete="off">
          <Grid container className={classes.formStyles} spacing={3}>
            <Grid item xs={2} className={classes.paper}>
              {setupConfigsList(data.sftpConfigs)}
            </Grid>
            <Grid item xs={2} className={classes.formStyles}>
              <Button variant="contained" className={classes.button} color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Accordion>
  );

  const set_small_parcels = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Inbox />
        <Typography>Set Small Parcels Config</Typography>
      </AccordionSummary>
      <Paper>
        <form name="smallParcels" onSubmit={submitForm} noValidate autoComplete="off">
          <Grid className={classes.formStyles} container spacing={3}>
            <Grid item xs={2} className={classes.paper}>
              <List className={classes.checkbox}>
                {Object.keys(data.smallParcelConfig).map((carrier) => {
                  return Object.keys(data.smallParcelConfig[carrier]).map((configName) => {
                    return (
                      <ListItem className={classes.listItem} key={carrier + configName}>
                        <Checkbox
                          checked={data.smallParcelConfig[carrier][configName]}
                          disableRipple
                          name="smallParcel"
                          value={[carrier, configName]}
                          onChange={handleFormControlChange}
                        />
                        <ListItemText>{configName}</ListItemText>
                      </ListItem>
                    );
                  });
                })}
              </List>
            </Grid>
            <Grid item xs={2} className={classes.formStyles}>
              <Button variant="contained" className={classes.button} color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Accordion>
  );

  const stat_values = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Assessment />
        <Typography>Statistic values</Typography>
      </AccordionSummary>
      <Paper>
        <form name="statisticValues" onSubmit={submitForm} noValidate autoComplete="off">
          <Grid container direction="row" justify="space-around" spacing={3}>
            <Card style={{ width: '95%', padding: '15px' }}>
              <CardHeader avatar={<LocalAtm />} title="Charges" />
              <Grid container direction="row" justify="space-around" spacing={3}>
                <Grid item xs={3} className={classes.paperLeft}>
                  <Typography>Insurance</Typography>
                </Grid>
                <Grid item xs={9} className={classes.paper}>
                  <TextField
                    value={data.statistical_values.admin_charges}
                    name="adminCharges"
                    onChange={handleFormControlChange}
                  />
                </Grid>
                <Card style={{ width: '95%', padding: '15px' }}>
                  <CardHeader avatar={<LocalShipping />} title="Warehouse picking (GBP)" />
                  <Grid container direction="row" justify="space-around" spacing={3}>
                    <Grid item xs={3} className={classes.paper}>
                      <Typography>One person</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.paper}>
                      <TextField
                        value={data.statistical_values.warehouse_picking.one_person}
                        name="one_person"
                        onChange={handleFormControlChange}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.paper}>
                      <Typography>Two person (DC)</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.paper}>
                      <TextField
                        value={data.statistical_values.warehouse_picking.two_person.DC}
                        name="two_person"
                        id={'DC'}
                        onChange={handleFormControlChange}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.paper}>
                      <Typography>Two person (DD)</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.paper}>
                      <TextField
                        value={data.statistical_values.warehouse_picking.two_person.DD}
                        name="two_person"
                        id={'DD'}
                        onChange={handleFormControlChange}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Card>

            <Card style={{ width: '95%', padding: '15px' }}>
              <CardHeader avatar={<DirectionsBoat />} title="Freight cost (GBP)" />
              {Object.keys(data.statistical_values.freight_cost).map((carrier) => {
                return (
                  <Grid container direction="row" justify="space-around" spacing={3} key={carrier}>
                    <Grid item xs={3} className={classes.paperLeft}>
                      <Typography>{carrier}</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.paper}>
                      <TextField
                        value={data.statistical_values.freight_cost[carrier]}
                        name="freightCost"
                        id={carrier}
                        onChange={handleFormControlChange}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Card>

            <Grid item xs={2} className={classes.paper}>
              <Button variant="contained" className={classes.button} color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Accordion>
  );

  const freightCharges = (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <DirectionsBoat />
        <Typography>Freight Charges</Typography>
      </AccordionSummary>
      <Paper>
        <form name="freightCharges" onSubmit={submitForm} noValidate autoComplete="off">
          <Card style={{ width: '95%', padding: '15px' }}>
            <Grid className={classes.formStyles} container spacing={3}>
              <Grid className={classes.flexDisplay}>
                <Grid>
                  <Typography className={`${classes.root} ${classes.listHeader}`}>Cost Type</Typography>
                  <List>
                    <ListItem className={classes.listNames} key="dd_to_tvg_fc_key">
                      <ListItemText>DD {'->'} TVG FC</ListItemText>
                    </ListItem>
                    <ListItem className={classes.listNames} key="uk_fc_picking_key">
                      <ListItemText>UK FC Picking</ListItemText>
                    </ListItem>
                    <ListItem className={classes.listNames} key="tvg_uk_fc_to_ie_dc_key">
                      <ListItemText>
                        TVG UK FC {'->'} Dublin Port {'->'} IE DC
                      </ListItemText>
                    </ListItem>
                    <ListItem className={classes.listNames} key="insurance_key">
                      <ListItemText>Insurance</ListItemText>
                    </ListItem>
                    <ListItem className={classes.listNames} key="delivery_to_customer_key">
                      <ListItemText>Delivery To Customer</ListItemText>
                    </ListItem>
                  </List>
                </Grid>
                <Grid>
                  <Typography className={`${classes.root} ${classes.listHeader}`}>1 Person</Typography>
                  <List>
                    <ListItem className={classes.listNames} key="dd_to_tvg_fc_1_person">
                      <TextField
                        value={data.freightCharges.onePerson.ddToTvgDc}
                        inputProps={{ min: 0, step: 0.01, 'data-one-person': true, 'data-charge-type': 'ddToTvgDc' }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="uk_fc_picking_1_person">
                      <TextField
                        value={data.freightCharges.onePerson.ukFcPicking}
                        inputProps={{ min: 0, step: 0.01, 'data-one-person': true, 'data-charge-type': 'ukFcPicking' }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="tvg_uk_fc_to_ie_dc_1_person">
                      <TextField
                        value={data.freightCharges.onePerson.tvgUkFcToIeDc}
                        inputProps={{
                          min: 0,
                          step: 0.01,
                          'data-one-person': true,
                          'data-charge-type': 'tvgUkFcToIeDc'
                        }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="insurance_1_person">
                      <TextField
                        value={data.freightCharges.onePerson.insurance}
                        inputProps={{ min: 0, step: 0.01, 'data-one-person': true, 'data-charge-type': 'insurance' }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="delivery_to_customer_1_person">
                      <TextField
                        value={data.freightCharges.onePerson.deliveryToCustomer}
                        inputProps={{
                          min: 0,
                          step: 0.01,
                          'data-one-person': true,
                          'data-charge-type': 'deliveryToCustomer'
                        }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid>
                  <Typography className={`${classes.root} ${classes.listHeader}`}>2 Person</Typography>
                  <List>
                    <ListItem className={classes.listNames} key="dd_to_tvg_fc_2_person">
                      <TextField
                        value={data.freightCharges.twoPerson.ddToTvgDc}
                        inputProps={{ min: 0, step: 0.01, 'data-one-person': false, 'data-charge-type': 'ddToTvgDc' }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="uk_fc_picking_2_person">
                      <TextField
                        value={data.freightCharges.twoPerson.ukFcPicking}
                        inputProps={{ min: 0, step: 0.01, 'data-one-person': false, 'data-charge-type': 'ukFcPicking' }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="tvg_uk_fc_to_ie_dc_2_person">
                      <TextField
                        value={data.freightCharges.twoPerson.tvgUkFcToIeDc}
                        inputProps={{
                          min: 0,
                          step: 0.01,
                          'data-one-person': false,
                          'data-charge-type': 'tvgUkFcToIeDc'
                        }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="insurance_2_person">
                      <TextField
                        value={data.freightCharges.twoPerson.insurance}
                        inputProps={{ min: 0, step: 0.01, 'data-one-person': false, 'data-charge-type': 'insurance' }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                    <ListItem className={classes.listNames} key="delivery_to_customer_2_person">
                      <TextField
                        value={data.freightCharges.twoPerson.deliveryToCustomer}
                        inputProps={{
                          min: 0,
                          step: 0.01,
                          'data-one-person': false,
                          'data-charge-type': 'deliveryToCustomer'
                        }}
                        name="freightCharges"
                        type="number"
                        onChange={handleFormControlChange}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.formStyles}>
              <Button variant="contained" className={classes.button} color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Card>
        </form>
      </Paper>
    </Accordion>
  );

  return (
    <MuiThemeProvider theme={customTheme}>
      {popupMessage}
      <Modal open={loading}>
        <Grid container className={classes.loader} justify="center">
          <CircularProgress disableShrink />
        </Grid>
      </Modal>
      <Grid container>
        <Grid item xs={7}>
          <PageHeading>Administration</PageHeading>
        </Grid>
      </Grid>
      {exchage_rate_section}
      {remove_upis_section}
      {regenerate_docs_section}
      {set_sftp_configs}
      {set_small_parcels}
      {stat_values}
      {freightCharges}
    </MuiThemeProvider>
  );
};
