import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { CircularProgress, Grid, Paper, Table, Typography } from '@material-ui/core';
import { reverse } from 'named-urls';
import { useSnackbar } from 'notistack';
import { EnhancedTableBody, EnhancedTableHead, Filters } from '../common/filterable-table';
import { getConsignments } from '../../services/api';
import { ConsignmentsContext } from '../../context/consignments';
import { URLS } from '../../constants';
import { useUserHandlers, useUserHandlersInfo } from '../../hooks/use-user-handlers';
import { createEmptyConsignment } from './model';
import consignmentStatusesDict from '../../dictionaries/consignment-statuses.json';
import trailerTypesDict from '../../dictionaries/trailer-types.json';

import {
  formatAlphanumericInput,
  formatDate,
  ISOFormatDateAtEndOfDay,
  ISOFormatDateAtStartOfDay,
  objectFilter
} from '../../helpers';
import columnsConfig from '../consignments-list/consignments-table-config.json';
import { Tooltips } from './tooltips';
import { EmptyPopup, handleAPIException, StatusPopup } from '../common/status-popup';
import { customTheme } from '../main/materialStyles';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
  root: {
    padding: '27px 23px'
  },
  table: {
    tableLayout: 'fixed'
  },
  tableWrapper: {
    overflow: 'overlay',
    height: window.innerHeight - 360 + 'px'
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%'
  },
  emptyData: {
    opacity: 0.5
  }
});

const useTooltipsStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      padding: theme.spacing(0, 1, 0)
    },
    statusMarker: {
      display: 'inline-block',
      width: theme.spacing(1),
      height: theme.spacing(1),
      margin: theme.spacing(0, 1, 0, 0),
      borderRadius: 4
    },
    green: {
      backgroundColor: '#a7cb19'
    },
    blue: {
      backgroundColor: '#76c5fd'
    },
    orange: {
      backgroundColor: '#e7b15e'
    },
    tooltips: {
      width: theme.spacing(11)
    }
  })
);

const useRowsStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      cursor: 'pointer'
    }
  })
);

const carrierMap: any = {};
const trailerTypeMap: any = {};
const getCarrier = (handler: string) => {
  return carrierMap[handler] || handler;
};

export const ConsignmentsList = withRouter((props) => {
  const classes = useStyles();
  const rowsClasses = useRowsStyles();
  const tooltipsClasses = useTooltipsStyles();
  const carriers = useUserHandlers();
  const originsDict = useUserHandlers();
  const handlersInfo = useUserHandlersInfo();
  const { consignmentsState, dispatchConsignments } = useContext(ConsignmentsContext);
  const defaultSearchParams = new URLSearchParams();
  defaultSearchParams.set('date', `${ISOFormatDateAtStartOfDay()}&${ISOFormatDateAtEndOfDay()}`);
  const [filters, setFilters] = useState(defaultSearchParams.toString());
  const [popup, setPopup] = React.useState(EmptyPopup);
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState(createEmptyConsignment());
  const [closeConsignmentsEvent, setCloseConsignmentsEvent] = useState(false);
  const [loader, setLoader] = useState(true);
  const didMount = useRef(false);
  const contextUpdateReference = useRef('');
  const allFilter = {
    value: '',
    label: 'ALL'
  };
  const handlers = [allFilter, ...carriers];
  const statuses = [allFilter, ...consignmentStatusesDict];
  const origins = [allFilter, ...originsDict];
  const trailerTypes = [allFilter, ...trailerTypesDict];

  for (const carrier of carriers) {
    carrierMap[carrier.value] = carrier.label;
  }

  const exists = handlers.filter((handler) => handler.value === 'GREENOGUE').length > 0;
  if (!exists) {
    handlers.push(
      { label: 'GREENOGUE', value: 'GREENOGUE' },
      { label: 'WREXHAM', value: 'WREXHAM' }
    );
    origins.push(
      { label: 'GREENOGUE', value: 'GREENOGUE' },
      { label: 'WREXHAM', value: 'WREXHAM' }
    );
    handlersInfo.WREXHAM = 'WREXHAM';
    handlersInfo.GREENOGUE = 'GREENOGUE';

  } else {
    handlers.push(
      { label: 'ARROW XL - WIGAN', value: 'ARROWXL' },
      { label: 'XPO Wrexham', value: 'XPO' },
      { label: 'DHL - DUBLIN', value: 'DHL' }
    );
    origins.push(
      { label: 'ARROW XL - WIGAN', value: 'ARROWXL' },
      { label: 'XPO Wrexham', value: 'XPO' },
      { label: 'DHL - DUBLIN', value: 'DHL' }
    );
    handlersInfo.ARROWXL = 'ARROW XL - WIGAN';
    handlersInfo.XPO = 'XPO Wrexham';
    handlersInfo.DHL = 'DHL - DUBLIN';


  }
  const columns = columnsConfig.map((column: any, index: number) => {
    if (column.id === 'handler') {
      column.options = handlers;
    }
    if (column.id === 'origin') {
      column.options = origins.map((origin) => {
        return { value: origin.value, label: handlersInfo[origin.label] || origin.label };
      });
    }
    if (column.id === 'statusLabel') {
      column.options = statuses;
    }
    if (column.id === 'trailerType') {
      column.options = trailerTypes.map((trailerType) => {
        return { value: trailerType.value, label: handlersInfo[trailerType.label] || trailerType.label };
      });
    }
    return column;
  });

  useEffect(() => {
    (async () => {
      await setConsignmentsToContext(filters);
    })();
  }, [closeConsignmentsEvent]);

  useEffect(() => {
    (async () => {
      if (didMount.current) {
        const thisContextUpdateReference = uuidv4();
        contextUpdateReference.current = thisContextUpdateReference;
        const anyConsignmentsInProgress = consignmentsState.consignments.some(
          (consignment: any) => consignment.status === 'SENDING_XML_DECLARATIONS' || consignment.status === 'CLOSING'
        );
        if (anyConsignmentsInProgress) {
          setTimeout(() => {
            /***
             * Update consignment context only if it wasn't already updated
             */
            if (thisContextUpdateReference === contextUpdateReference.current) {
              setConsignmentsToContext(filters);
            }
          }, 10000);
        }
      } else {
        didMount.current = true;
      }
    })();
  }, [consignmentsState]);

  const formatInputValue = (fieldName: string, value: string) => {
    const fields = {
      consignmentId: formatAlphanumericInput,
      userDefinedId: formatAlphanumericInput
    } as any;
    return fields[fieldName] ? fields[fieldName](value) : value.trim();
  };

  const switcherCloseConsignmentsEvent = () => {
    closeConsignmentsEvent ? setCloseConsignmentsEvent(false) : setCloseConsignmentsEvent(true);
  };

  const setInputValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    const formattedValue = formatInputValue(name, value);
    setValues({ ...values, [name]: formattedValue });
    return { name, formattedValue };
  };

  const prepareFilterParams = (name: string, value: string) => {
    if (name === 'statusLabel') {
      name = 'status';
    }
    const searchParams = new URLSearchParams(filters);
    if (value === '') {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }
    const filterParams = searchParams.toString();
    setFilters(filterParams);
    return filterParams;
  };

  const setConsignmentsToContext = async (filterParams: string) => {
    try {
      const { items } = await getConsignments(`?${filterParams}`);
      dispatchConsignments({ type: 'set', items });
      setLoader(false);
    } catch (e) {
      handleAPIException(e, setPopup);
      setLoader(false);
    }
  };

  const filter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, formattedValue } = setInputValue(event);
    const filterParams = prepareFilterParams(name, formattedValue);
    const { type } = event.target as HTMLInputElement;
    if (type !== 'search') {
      await setConsignmentsToContext(filterParams);
    } else if (event.which === 13) {
      event.preventDefault();
      await setConsignmentsToContext(filterParams);
    } else if (!formattedValue && typeof event.which === 'undefined') {
      await setConsignmentsToContext(filterParams);
    }
  };

  const dateFilter = async (date: string) => {
    const searchParams = new URLSearchParams(filters);
    if (date === null) {
      searchParams.delete('date');
    } else {
      searchParams.set('date', `${ISOFormatDateAtStartOfDay(date[0])}&${ISOFormatDateAtEndOfDay(date[1])}`);
    }
    const filterParams = searchParams.toString();
    setFilters(filterParams);
    setValues({ ...values, date });
    await setConsignmentsToContext(filterParams);
  };

  const consignments = consignmentsState.consignments
    ? consignmentsState.consignments.map((consignment: any) => {
        const isClosed =
          consignment.status === 'CLOSED' ||
          consignment.status === 'CLOSING' ||
          consignment.status === 'SENDING_XML_DECLARATIONS';
        const isReturn = consignment.purpose === 'INBOUND';
        const handleClick = () => {
          if (isClosed) {
            enqueueSnackbar(`Consignment ${consignment.consignmentId} has been already released!`, {
              variant: 'error'
            });
          } else {
            props.history.push(reverse(URLS.ADD_PARCELS_TO_CONSIGNMENT, { consignmentId: consignment.consignmentId }));
          }
        };
        const rowStyles = !isClosed ? rowsClasses.tableRow : '';
        const status = () => (
          <Fragment>
            <div
              className={`${tooltipsClasses.statusMarker}
                     ${consignment.status === 'CREATED' && tooltipsClasses.blue}
                      ${consignment.status === 'OPEN' && tooltipsClasses.orange}
                      ${consignment.status === 'SENDING_XML_DECLARATIONS' && tooltipsClasses.orange}
                      ${consignment.status === 'CLOSING' && tooltipsClasses.orange}
                      ${consignment.status === 'CLOSED' && tooltipsClasses.green}`}
            />
            {consignment.status}
          </Fragment>
        );
        const tooltip = () => (
          <Tooltips
            closeEvent={switcherCloseConsignmentsEvent}
            status={consignment.status}
            consignmentId={consignment.consignmentId}
            invoiceKeyIE={
              (consignment.invoiceKeyIE && consignment.invoiceKeyIE) ||
              (!isReturn && consignment.invoiceKey && consignment.invoiceKey) ||
              undefined
            }
            invoiceKeyUK={
              (consignment.invoiceKeyUK && consignment.invoiceKeyUK) ||
              (isReturn && consignment.invoiceKey && consignment.invoiceKey) ||
              undefined
            }
            customsInvoiceKeyIE={
              (consignment.customsInvoiceKeyIE && consignment.customsInvoiceKeyIE) ||
              (!isReturn && consignment.customsInvoiceKey && consignment.customsInvoiceKey) ||
              undefined
            }
            customsInvoiceKeyUK={
              (consignment.customsInvoiceKeyUK && consignment.customsInvoiceKeyUK) ||
              (isReturn && consignment.customsInvoiceKey && consignment.customsInvoiceKey) ||
              undefined
            }
            manifestKeyIE={
              (consignment.manifestKeyIE && consignment.manifestKeyIE) ||
              (!isReturn && consignment.manifestKey && consignment.manifestKey) ||
              undefined
            }
            manifestKeyUK={
              (consignment.manifestKeyUK && consignment.manifestKeyUK) ||
              (isReturn && consignment.manifestKey && consignment.manifestKey) ||
              undefined
            }
            isLvc={consignment.isLvc || false}
            purpose={consignment.purpose}
            vesselNumber={consignment.vesselNumber}
          />
        );
        const created = () => formatDate(consignment.created);
        consignment.tooltips = tooltip();
        consignment.date = created();
        consignment.statusLabel = status();
        const data: any = objectFilter(consignment, columns, 'id');
        data.handler = handlersInfo[consignment.handler];
        data.origin = handlersInfo[consignment.origin];

        return { data, rowStyles, handleClick };
      })
    : [];

  const MemoizedEnhancedTable = useMemo(
    () => (
      <Table className={classes.table}>
        <EnhancedTableBody rows={consignments} cellsStyles={tooltipsClasses} />
      </Table>
    ),
    [consignmentsState.consignments, handlersInfo]
  );
  let popupMessage = <></>;
  if (popup.type && popup.message) {
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Paper className={classes.root}>
        {popupMessage}
        <Table className={classes.table}>
          <EnhancedTableHead rows={columns}>
            <Filters
              filter={filter}
              dateFilter={dateFilter}
              handleInputTyping={setInputValue}
              values={values}
              rows={columns}
            />
          </EnhancedTableHead>
        </Table>
        <div className={classes.tableWrapper}>
          {!loader ? (
            typeof consignmentsState.consignments !== 'undefined' && consignmentsState.consignments.length > 0 ? (
              MemoizedEnhancedTable
            ) : (
              <Grid container className={classes.loader} justify="center">
                <Typography className={classes.emptyData} variant="h5">
                  No Data
                </Typography>
              </Grid>
            )
          ) : (
            <Grid container className={classes.loader} justify="center">
              <CircularProgress disableShrink />
            </Grid>
          )}
        </div>
      </Paper>
    </MuiThemeProvider>
  );
});
