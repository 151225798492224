import { withStyles, Theme } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    fontSize: '0.75rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem'
    }
  }
});

export const EnhancedTableCell = withStyles(styles, { name: 'MuiTableCell' })(TableCell);
