import {
  Button,
  Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup, Modal,
  TextField,
  Theme
} from '@material-ui/core';
import React, { useState } from 'react';
import { commonStyles } from '../../styles';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { EmptyPopup, handleAPIException, PopupDefinition, StatusPopup } from '../../components/common/status-popup';
import { updateUser } from '../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '0 auto'
    },
    formInput: {
      marginTop: theme.spacing(2)
    },
    buttonsHolder: {
      margin: theme.spacing(4, -1.5)
    },
    inlineBlock: {
      display: 'inline-block'
    }
  })
);

interface SelectedData {
  userInfo: any;
  onUserChanged: () => void;
}

interface EditUserForm {
  username: string;
  groups: any;
  enabled: boolean;
  email: string;
}

export const EditUserForm = (props: SelectedData) => {
  const classes = commonStyles();
  const jjStyles = useStyles();
  const { userInfo, onUserChanged } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(EmptyPopup);
  const [inputFlag, setinputFlag] = useState(true);
  const initData = {
    username: '',
    groups: {},
    enabled: false,
    email: ''
  };
  const [data, setData] = React.useState<EditUserForm>(initData);

  const handleClickOpen = async () => {
    if (userInfo) {
      setPopup(EmptyPopup);
      const groups: any = { ...userInfo.groups };
      setData({ groups, username: userInfo.username, enabled: userInfo.enabled, email: userInfo.email });
      setinputFlag(true);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setData(initData);
    setOpen(false);
  };

  function checkCheckpoints(groups: any) {
    let flag: boolean = false;
    for (const carrier in groups) {
      if (groups[carrier] === true) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  const handleSubmit = async () => {
    if (!checkCheckpoints(data.groups)) {
      setPopup(PopupDefinition.create('AMBER', 'Please set at least 1 group'));
    } else {
      try {
        setLoading(true);
        const user = { ...data };
        await updateUser(user);
        setPopup(PopupDefinition.create('SUCCESS', 'User "' + data.username + '" updated successfully'));
        setLoading(false);
        setData({ ...initData });
        setOpen(false);
        await onUserChanged();
      } catch (e) {
        handleAPIException(e, setPopup);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | any>) => {
    setPopup(EmptyPopup);
    event.persist();
    const newData = { ...data };
    const carrierId = event.target.id;
    newData.groups[carrierId] = !newData.groups[carrierId];
    setData(newData);
    if (checkCheckpoints(data.groups)) {
      setinputFlag(true);
    } else {
      setinputFlag(false);
    }
  };

  let popupMessage = <></>;
  if (popup.type) {
    // @ts-ignore
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }
  return (
    <Grid item>
      <Modal open={loading}>
        <Grid container className={classes.loader} justify="center">
          <CircularProgress disableShrink />
        </Grid>
      </Modal>
      {popupMessage}
      <Button className={classes.button} color="primary" onClick={handleClickOpen} variant="contained">
        Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle id="alert-dialog-title">{'Edit User'}</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid className={jjStyles.container}>
              <FormControl fullWidth variant="outlined" className={jjStyles.formInput}>
                <TextField
                  name="username"
                  variant="outlined"
                  label="username"
                  value={data.username}
                  helperText="Username must be unique"
                  disabled
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" className={jjStyles.formInput}>
                <FormGroup className={jjStyles.inlineBlock}>
                  {Object.keys(data.groups).map((carrier) => {
                    return (
                      <FormControlLabel
                        key={carrier}
                        control={
                          <Checkbox
                            checked={data.groups[carrier]}
                            onChange={handleChange}
                            id={carrier}
                            name={'carrier'}
                          />
                        }
                        label={carrier}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!inputFlag} variant="contained" onClick={handleSubmit} color="primary" autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
