import React, { useReducer } from 'react';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'set':
      return { ...state, consignments: action.items };
    case 'close': {
      const updateConsignmentList = () => {
        state.consignments.map(
          (consignment: {
            userDefinedId: string;
            consignmentId: string;
            consignmentNumber: string;
            invoiceKey: string;
            customsInvoiceKey: string;
            manifestKey: string;
            isLvc: boolean;
            handler: React.ReactNode;
            origin: React.ReactNode;
            created: React.ReactNode;
            status: React.ReactNode;
            trailerType: string;
          }) => {
            if (consignment.consignmentId === action.close[0]) {
              consignment.status = 'CLOSING';
            }
          }
        );
        return state.consignments;
      };
      return { ...state, consignments: updateConsignmentList() };
    }
    default:
      return;
  }
};

const consignments: any = {};

const ConsignmentsContext = React.createContext(consignments);

function ConsignmentsProvider(props: any) {
  const [consignmentsState, dispatchConsignments] = useReducer(reducer, consignments);
  return (
    <ConsignmentsContext.Provider value={{ consignmentsState, dispatchConsignments }}>
      {props.children}
    </ConsignmentsContext.Provider>
  );
}

export { ConsignmentsContext, ConsignmentsProvider };
