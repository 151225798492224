import React from 'react';
import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { formatDate } from '../../helpers';
import { PURPOSES } from '../../constants';
import { IConsignmentEntity } from '../../models/consignment';
import { customTheme } from '../main/materialStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    consignmentInfo: {
      padding: theme.spacing(4),
      margin: theme.spacing(1, 0)
    }
  })
);

export const ReturnConsignmentInfo = (props: IConsignmentEntity) => {
  const { consignmentId, handler, origin, created, purpose, userDefinedId, trailerType } = props;
  const classes = useStyles();

  const purposeLabel = {
    [PURPOSES.OUTBOUND]: 'Dispatch',
    [PURPOSES.INBOUND]: 'Returns'
  }[purpose];

  return (
    <MuiThemeProvider theme={customTheme}>
      <Paper className={classes.consignmentInfo}>
        <Grid container spacing={10} className={classes.root} direction="row" alignItems="stretch">
          <Grid item xs={3}>
            <Typography>Consignment</Typography>
            <Typography id="consignment-id" variant="h5">
              {consignmentId}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Purpose</Typography>
            <Typography>
              <strong>{purposeLabel}</strong>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Destination</Typography>
            <Typography>
              <strong>{origin}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </MuiThemeProvider>
  );
};
