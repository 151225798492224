import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  InputAdornment,
  Typography
} from '@material-ui/core';
import { Error as ErrorIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { DiscrepancyTextField } from '../discrepancy-text-field';
import { formatAlphaInput, formatFloatNumericInput, formatNumericInput } from '../../../../helpers';

interface ICommodityItemProps {
  item: ICommodityItemEntity;
  onChange(id: string, data: { [key: string]: string }): void;
}

interface ICommodityItemEntity {
  SKU: string;
  ItemDescription: string;
  CostPrice: number;
  NetWeight: number;
  GrossWeight: number;
  HarmonisedSystemCode: number;
  UKHarmonisedSystemCode: number;
  CountryOfOrigin: string;
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0)
    },
    formContainer: {
      width: '100%',
      padding: theme.spacing(0, 3)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    errorIcon: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(-4),
      color: '#bf3939'
    }
  })
);

export const CommoditySplitItem = (props: ICommodityItemProps) => {
  const classes = useStyles();
  const { item, onChange } = props;
  const [itemData, setItemData] = useState<ICommodityItemEntity>(item);

  const formatInputValue = (fieldName: string, value: string) => {
    const fields = {
      CountryOfOrigin: formatAlphaInput,
      CostPrice: formatFloatNumericInput,
      NetWeight: formatFloatNumericInput,
      GrossWeight: formatFloatNumericInput,
      HarmonisedSystemCode: formatNumericInput
    } as any;
    return fields[fieldName] ? fields[fieldName](value) : value;
  };

  const handleFormControlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const { name, value } = event.target;

    const formattedValue = formatInputValue(name, value);
    setItemData((data) => ({ ...data, [name]: formattedValue }));
    onChange(item.id, { [name]: formattedValue });
  };

  const isDiscrepancyFieldHasValue = (name: keyof ICommodityItemEntity) => item[name] !== '';

  const isCommodityItemValid = ([
    'CostPrice',
    'NetWeight',
    'GrossWeight',
    'HarmonisedSystemCode',
    'UKHarmonisedSystemCode',
    'CountryOfOrigin'
  ] as any)
    .map(isDiscrepancyFieldHasValue)
    .every(Boolean);

  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{item.ItemDescription}</Typography>
        {!isCommodityItemValid && <ErrorIcon className={classes.errorIcon} />}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.formContainer}>
          <DiscrepancyTextField
            onChange={handleFormControlChange}
            disabled={isDiscrepancyFieldHasValue('CostPrice')}
            type="text"
            value={itemData.CostPrice}
            name="CostPrice"
            InputProps={{ startAdornment: <InputAdornment position="start">&pound;</InputAdornment> }}
          />
          <DiscrepancyTextField
            onChange={handleFormControlChange}
            disabled={isDiscrepancyFieldHasValue('NetWeight')}
            value={itemData.NetWeight}
            type="text"
            name="NetWeight"
            InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment> }}
          />
          <DiscrepancyTextField
            onChange={handleFormControlChange}
            disabled={isDiscrepancyFieldHasValue('GrossWeight')}
            value={itemData.GrossWeight}
            type="text"
            name="GrossWeight"
            InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment> }}
          />
          <DiscrepancyTextField
            onChange={handleFormControlChange}
            disabled={isDiscrepancyFieldHasValue('HarmonisedSystemCode')}
            value={itemData.HarmonisedSystemCode}
            type="text"
            name="HarmonisedSystemCode"
          />
          <DiscrepancyTextField
            onChange={handleFormControlChange}
            disabled={isDiscrepancyFieldHasValue('UKHarmonisedSystemCode')}
            value={itemData.UKHarmonisedSystemCode}
            type="text"
            name="UKHarmonisedSystemCode"
          />
          <DiscrepancyTextField
            onChange={handleFormControlChange}
            disabled={isDiscrepancyFieldHasValue('CountryOfOrigin')}
            value={itemData.CountryOfOrigin}
            name="CountryOfOrigin"
            inputProps={{ maxLength: 2 }}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
