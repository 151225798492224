import React from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, Toolbar, Tooltip, Typography, Theme } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { NavigationMenu } from './components/navigation-menu';
import logo from '../../assets/images/logo.svg';
import { useUser } from '../../context/authentication';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#fff',
      padding: 16
    },
    title: {
      flexGrow: 1,
      borderLeft: '1px solid #b9bdc6',
      padding: theme.spacing(0, 0, 0, 1),
      fontSize: 16
    },
    logo: {
      height: theme.spacing(4),
      marginRight: theme.spacing(2)
    },
    logoLink: {
      padding: theme.spacing(0, 0, 0, 1)
    }
  })
);

export const Header = () => {
  const classes = useStyles();
  const user = useUser();
  const handleSignOut = () => Auth.signOut();

  return (
    <Toolbar className={classes.root}>
      <NavigationMenu />
      <Link to="/" className={classes.logoLink}>
        <img className={classes.logo} src={logo} alt="logo" />
      </Link>
      <Typography variant="h6" className={classes.title}>
        Customs Reprocessing System
      </Typography>
      <Typography>{user && user.getUsername()}</Typography>
      <Tooltip title="Sign Out">
        <IconButton onClick={handleSignOut}>
          <ExitToAppIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};
