import { customTheme } from '../main/materialStyles';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MuiThemeProvider,
  Paper,
  Theme,
  Typography
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close, Send } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { submitDocsRequest } from '../../services/api';
import { EmptyPopup, handleAPIException, PopupDefinition } from '../common/status-popup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      height: '100%'
    },
    paper: {
      padding: theme.spacing(10, 7),
      margin: theme.spacing(10, 10),
      width: '44rem',
      maxHeight: '50rem'
    },
    chipInput: {
      margin: theme.spacing(2, 0, 1),
      maxHeight: '20rem',
      minHeight: '3.5rem',
      overflow: 'overlay'
    },
    helper: {
      margin: theme.spacing(0, 0, 1)
    },
    button: {
      margin: theme.spacing(0, 2, 0, 0)
    }
  })
);

interface IDocumentRequestProps {
  consignmentId: string;
  setOpenDocsRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setPopup: React.Dispatch<React.SetStateAction<PopupDefinition>>;
}

export const DocumentsRequest = React.forwardRef((props: IDocumentRequestProps, ref) => {
  const classes = useStyles();
  const { consignmentId, setOpenDocsRequest, setPopup } = props;
  const keyCodes = [13, 32, 9, 188]; // return, space, tab, comma
  const [crns, setCrns] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [submitIcon, setSubmitIcon] = useState(<Send />);
  const separatorHelperText = 'CRNs can be seperated by space, comma, or return (new line)';
  const [helperText, setHelperText] = useState(separatorHelperText);
  const crnLimit = 500;

  function helperError(message: string) {
    setError(true);
    setHelperText(message);
  }

  function resetError() {
    setError(false);
    setDisabled(false);
    setHelperText(separatorHelperText);
  }

  function handleAddCrn(crn: string) {
    setCrns([...crns, crn]);
    checkLimit();
  }

  function handleAddCrnArray(crnArray: string[]) {
    const concat = new Set<string>([...crns, ...crnArray]);
    setCrns(Array.from(concat));
    checkLimit();
  }

  function handleDeleteCrn(chip: string, index: number) {
    const spliced = [...crns];
    spliced.splice(index, 1);
    setCrns(spliced);
  }

  async function handleSubmit() {
    setPopup(EmptyPopup);
    setDisabled(true);
    setSubmitIcon(<CircularProgress size={20} />);

    try {
      await submitDocsRequest(consignmentId, { crns });
      setOpenDocsRequest(false);
      setPopup(PopupDefinition.create('SUCCESS', 'Documents Requested.'));
    } catch (e) {
      handleAPIException(e, setPopup);
    }
  }

  function handleOnBlur() {
    if (crns.length === 0) {
      helperError('You must provide at least 1 crn.');
    } else {
      checkLimit();
    }
  }

  function checkLimit() {
    if (crns.length > crnLimit) {
      helperError(`You have exceeded the 500 crn limit by ${crns.length - crnLimit}`);
    } else {
      resetError();
    }
  }

  function onPaste(event: React.ClipboardEvent<HTMLDivElement>) {
    const clipboardText = event.clipboardData.getData('Text');
    event.preventDefault();
    const filtered = [...clipboardText.split(/[ ,\n\r]+/).filter((t) => t.length > 0)];

    handleAddCrnArray(filtered);
  }

  function handleClose() {
    setOpenDocsRequest(false);
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Box className={classes.modal}>
        <Paper className={classes.paper} ref={ref}>
          <FormControl fullWidth variant="outlined">
            <Grid container className={classes.modal}>
              <Typography variant="h3">Document Request</Typography>
              <Typography variant="subtitle1">Please provide a list of CRNs:</Typography>
              <ChipInput
                error={error}
                className={classes.chipInput}
                variant="outlined"
                newChipKeyCodes={keyCodes}
                value={crns}
                onPaste={(event) => {
                  onPaste(event);
                }}
                onAdd={(crn) => handleAddCrn(crn)}
                onDelete={(crn, index) => handleDeleteCrn(crn, index)}
                onBlur={handleOnBlur}
                aria-describedby="chips-helper-text"
                fullWidth
                fullWidthInput
                required
              />
              <FormHelperText id="chips-helper-text" error={error} className={classes.helper}>
                {helperText}
              </FormHelperText>
            </Grid>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <Button
                  id="open-docs-request"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={disabled || error}
                  onClick={handleSubmit}
                >
                  {submitIcon}
                  &nbsp;Submit
                </Button>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={4}>
                <Button id="open-docs-request" variant="contained" color="primary" fullWidth onClick={handleClose}>
                  <Close />
                  &nbsp;Close
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
      </Box>
    </MuiThemeProvider>
  );
});
