export const validateVesselNumber = (vesselNumberToValidate: string) => {
  const originalShippingId = vesselNumberToValidate;
  const shippingIdCharArray = Array.from(originalShippingId);
  let extractedShippingId: string;

  if (shippingIdCharArray.length === 7 && parseInt(shippingIdCharArray[0]) === 9) {
    extractedShippingId = originalShippingId.substring(0, 6);
  } else if (shippingIdCharArray.length === 8) {
    extractedShippingId = originalShippingId.substring(1, 7);
  } else {
    return false;
  }

  const extractedArray = Array.from(extractedShippingId);
  const checksumDigit = parseInt(originalShippingId.slice(-1));
  const digitArray = [];
  const indexToMultiplier: any = {
    0: 7,
    1: 6,
    2: 5,
    3: 4,
    4: 3,
    5: 2
  };

  for (const ch of extractedArray) {
    digitArray.push(parseInt(ch));
  }

  let productSum = 0;
  for (let i = 0; i < digitArray.length; i++) {
    productSum = productSum + digitArray[i] * indexToMultiplier[i];
  }
  const remainder = productSum % 10;
  if (remainder === checksumDigit) {
    return true;
  } else {
    return false;
  }
};
