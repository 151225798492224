import React, { RefObject, useEffect, useState } from 'react';
import { createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Button, Grid, TextField, Theme, Typography } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import {StatusPopup} from '../../../common/status-popup';
import { formatAlphanumericInput } from '../../../../helpers';
import {getParcel} from "../../../../services/api";
import { customTheme } from "../../../main/materialStyles";
import { yodelBarcodePrefix } from "../../../../constants";

interface IScanParcelLabelProps {
  enteringFinished: boolean;
  isParcelClosingInProgress: boolean;
  upi: string;
  parcelLabelInputRef?: RefObject<HTMLInputElement>;
  onComplete(upi: string): void;
  onUPIChange(upi: string): void;
  onParcelCancel(): void;
  onParcelClose(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3, 0)
    },
    statusContainer: {
      background: '#edf5d1',
      color: '#528618',
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 2),
      margin: theme.spacing(0, 0, 0, 2),
      alignItems: 'center',
      justify: 'space-between'
    },
    parcelStatusActionsContainer: {
      textAlign: 'right'
    },
    statusIcon: {
      float: 'left'
    }
  })
);

export const ScanParcelLabel = (props: IScanParcelLabelProps) => {
  const classes = useStyles();
  const states = {
    NEW: 'new',
    ERRORED: 'errored',
    SUCCEDED: 'succeded',
    INVALID: 'invalid',
  };
  const {
    enteringFinished,
    parcelLabelInputRef,
    isParcelClosingInProgress,
    onComplete,
    onUPIChange,
    onParcelCancel
  } = props;
  const [upi, setUPI] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [upiStatus, setUpiStatus] = useState(states.NEW);

  useEffect(() => {
    setShowSuccessPopup(enteringFinished);
  }, [enteringFinished]);

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13) {
      event.preventDefault();
      try {
        if( upi.startsWith(yodelBarcodePrefix) ){
          // Reject the scan if user has scanned the Yodel routing barcode
          setUpiStatus(states.INVALID);
          onUPIChange('');
        } else{
          const result: any = await getParcel(upi);
          setUpiStatus(states.ERRORED);
          onUPIChange('');
        }
      } catch (error) {
        setUpiStatus(states.SUCCEDED);
        onComplete(upi);
      }
    }
  };

  const handleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    setUpiStatus(states.NEW);
    setUPI(formatAlphanumericInput(element.value));
    onUPIChange(formatAlphanumericInput(element.value));
  };

  const ParcelStatus = () => (
    <Grid item xs container className={classes.statusContainer}>
      <Grid item xs>
        <Typography variant="subtitle1">
          <CheckIcon className={classes.statusIcon} />
          Parcel <strong>{upi}</strong> has been created
        </Typography>
      </Grid>
      <Grid item xs className={classes.parcelStatusActionsContainer}>
        <Button variant="contained" onClick={onParcelCancel} disabled={isParcelClosingInProgress}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <form className={classes.root}>
      <MuiThemeProvider theme={customTheme}>
      <Grid container>
        <Grid item xs={4}>
          <TextField
            fullWidth
            autoFocus
            inputProps={{
              maxLength: 25
            }}
            autoComplete="off"
            disabled={enteringFinished}
            onKeyPress={handleKeyPress}
            onInput={handleInput}
            label="Returns Label"
            variant="outlined"
            name="upi"
            value={props.upi}
            inputRef={parcelLabelInputRef}
          />
        </Grid >
        {showSuccessPopup && upiStatus === states.SUCCEDED && <StatusPopup text="Parcel has been created" type="SUCCESS" upi={upi} />}
        {!showSuccessPopup && upiStatus === states.ERRORED && <StatusPopup text="Parcel has already exist" type="ERROR" upi={upi} />}
        {!showSuccessPopup && upiStatus === states.INVALID && <StatusPopup text="Invalid Barcode Scanned" type="INVALID" upi={upi} key={Math.random()} />}
        {enteringFinished && <ParcelStatus />}
      </Grid>
      </MuiThemeProvider>
    </form>
  );
};
