import moment from 'moment';
import { USER_GROUPS, yodelBarcodePrefix, validWrexhamBarcodes } from '../constants';

export const formatDate = (dateString?: string | undefined) => moment(dateString).format('DD/MM/YYYY');
export const dashFormatDate = (dateString?: string | undefined) => moment(dateString).format('YYYY-MM-DD');
export const ISOFormatDateAtStartOfDay = (dateString?: string | undefined) =>
  moment(dateString).startOf('D').toISOString();
export const ISOFormatDateAtEndOfDay = (dateString?: string | undefined) =>
  moment(dateString).endOf('D').toISOString();

/**
 * Filters and sorts objects by predefined configuration.
 */
export const objectFilter = (obj: any, filter: any, sortKey?: any) => {
  const filteredResult = {};
  const sortedResult = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (filter.some((item: any) => item[sortKey] === key)) {
      // @ts-ignore
      filteredResult[key] = value;
    }
  });

  if (sortKey) {
    // @ts-ignore
    filter.forEach((outerKey) => {
      // @ts-ignore
      for (const innerKey in filteredResult) {
        if (outerKey[sortKey] === innerKey) {
          // @ts-ignore
          sortedResult[innerKey] = filteredResult[innerKey];
        }
      }
    });
  }
  // @ts-ignore
  return sortedResult ? sortedResult : filteredResult;
};

export const extractString = (str: string, pattern: RegExp) => str.replace(pattern, '') || '';
export const extractAlphanumericString = (str: string) => extractString(str, /[\W_]+/g);
export const extractAlphaString = (str: string) => extractString(str, /[^A-Za-z]+/g);
export const formatNumericInput = (str: string) => extractString(str, /[\D]+/g);
export const formatFloatNumericInput = (str: string) =>
  extractString(str, /[^0-9.]+/g).replace(/\./g, (match, offset, all) =>
    match === '.' ? (all.indexOf('.') === offset ? '.' : '') : ''
  );
export const formatAlphaInput = (str: string) => extractAlphaString(str).toUpperCase();
export const formatAlphanumericInput = (str: string) => extractAlphanumericString(str).toUpperCase();
export const isAllObjectValuesTrue = (obj: any) => Object.keys(obj).every((key) => obj[key]);

export const isUserAuthorised = (userGroups: string[], authorisedGroup: string) =>
  userGroups.indexOf(USER_GROUPS.DEV) !== -1 || userGroups.indexOf(authorisedGroup) !== -1;

// Wrexham checks scanned UPI against a whitelist of valid barcodes. Other warehouses just check the user hasn't
// accidentally scanned the Yodel routing barcode.
export const isValidBarcode = (upi : string, isWrexhamUser : boolean) => {
  if (isWrexhamUser) {
    return validWrexhamBarcodes.some(barcodeRegex => new RegExp(barcodeRegex).test(upi));
  }
  return !upi.startsWith(yodelBarcodePrefix);
};
