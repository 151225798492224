import { withRouter } from 'react-router-dom';
import { commonStyles } from '../../styles';
import { customTheme } from '../main/materialStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
  Paper,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core';
import { getFeatures, updateFeature } from '../../services/api';
import { EmptyPopup, handleAPIException, StatusPopup } from '../common/status-popup';

export interface IFeatureToggle {
  featureName: string;
  featureDescription: string;
  isActive: boolean;
}

export const FeatureToggleComponent = withRouter(() => {
  const classes = commonStyles();

  const [popup, setPopup] = React.useState(EmptyPopup);
  const [loading, setLoading] = React.useState(false);
  const [features, setFeatures] = React.useState(new Array<IFeatureToggle>());

  const setFeaturesToContext = async () => {
    try {
      setLoading(true);
      const body = await getFeatures();
      setFeatures(body);
    } catch (e) {
      handleAPIException(e, setPopup);
    } finally {
      setLoading(false);
    }
  };

  const updateFeatureState = async (event: any) => {
    try {
      const featureName: string = event.target.name;
      const enabled: boolean = event.target.checked;

      await updateFeature({
        featureName,
        enabled
      });

      setFeatures(
        features.map((feature: IFeatureToggle, index) =>
          feature.featureName === featureName ? { ...feature, isActive: enabled } : feature
        )
      );
    } catch (e) {
      handleAPIException(e, setPopup);
    }
  };

  useEffect(() => {
    (async () => {
      await setFeaturesToContext();
    })();
  }, []);

  let popupMessage = <></>;
  if (popup.type) {
    // @ts-ignore
    popupMessage = <StatusPopup text={popup.message} type={popup.type} />;
  }
  return (
    <MuiThemeProvider theme={customTheme}>
      <Modal open={loading}>
        <Grid container className={classes.loader} justify="center">
          <CircularProgress disableShrink />
        </Grid>
      </Modal>
      <Paper className={classes.root}>
        {popupMessage}
        <FormGroup>
          {features.map((feature: IFeatureToggle, index: number) => (
            <Tooltip key={index} title={feature.featureDescription}>
              <FormControlLabel
                key={index}
                control={
                  <Switch
                    data-feature-index={index}
                    checked={feature.isActive}
                    onChange={updateFeatureState}
                    name={feature.featureName}
                    value={index}
                  />
                }
                label={feature.featureName}
              />
            </Tooltip>
          ))}
        </FormGroup>
      </Paper>
    </MuiThemeProvider>
  );
});
