import React from 'react';
import { SessionTimeout } from '../context/session-timeout';

const withSessionTimeout = (Component: any) => {
  return (props: any) => {
    return (
      <>
        <SessionTimeout />
        <Component {...props} />
      </>
    );
  };
};

export default withSessionTimeout;
