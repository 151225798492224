import React, { RefObject, useState } from 'react';
import { makeStyles, createStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { CircularProgress, Grid, TextField, Theme } from '@material-ui/core';
import { formatAlphanumericInput } from '../../../../helpers';
import { getParcelItemById } from '../../../../services/api';
import { ConfirmationDialog } from '../../../common/confirmation-dialog';
import { StatusPopup } from '../../../common/status-popup';
import { customTheme } from "../../../main/materialStyles";

interface IScanParcelItemProps {
  parcelUPI: string;
  parcelItems: any;
  parcelItemLabelInputRef: RefObject<HTMLInputElement>;
  onComplete(item: any): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    progressWrapper: {
      position: 'relative'
    },
    fabProgress: {
      position: 'absolute',
      top: 0,
      zIndex: 1
    },
    scanForm: {
      margin: theme.spacing(3, 0, 0)
    },
    statusContainer: {
      margin: theme.spacing(0, 0, 0, 2)
    },
    error: {
      background: '#fed5d5',
      color: '#bf3939',
      height: '100%',
      borderRadius: theme.spacing(0.5),
      padding: '10px',
      lineHeight: '36px'
    },
    success: {
      background: '#edf5d1',
      color: '#528618',
      height: '100%',
      borderRadius: theme.spacing(0.5),
      padding: '10px',
      lineHeight: '36px'
    },
    amber: {
      background: '#fae3d7',
      color: '#bd561c',
      height: '100%',
      borderRadius: theme.spacing(0.5),
      padding: '10px',
      lineHeight: '36px'
    },
    statusIcon: {
      float: 'left',
      margin: '5px 5px 0 0'
    }
  })
);

export const ScanParcelItem = (props: IScanParcelItemProps) => {
  const classes = useStyles();
  const { parcelItems, parcelUPI, onComplete, parcelItemLabelInputRef } = props;
  const states = {
    NEW: 'new',
    IN_PROGRESS: 'inprogress',
    ERRORED: 'errored',
    SUCCEDED: 'succeded',
    ALREADY_ADDED: 'already_added'
  };

  const [scanState, setScanState] = useState(states.NEW);
  const [upi, setUPI] = useState('');
  const [sku, setSKU] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [confirmationDialogVisibility, setConfirmationDialogVisibility] = useState(false);
  const [parcelItemToAdd, setParcelItemToAdd] = useState<any>({});

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13) {
      event.preventDefault();
      const { value } = event.target as HTMLInputElement;

      if (!value) {
        return;
      }

      if (value !== parcelUPI) {
        try {
          setScanState(states.IN_PROGRESS);
          const results: any = await getParcelItemById(value);
          const { items, foundBy } = results;
          if (foundBy === 'QPL') {
            const newParcelItem = items.find(
              (v: any) => !parcelItems.map((pItem: any) => pItem.QPL.toString()).includes(v.QPL.toString())
            );
            if (newParcelItem) {
              const { SKU, ItemDescription } = newParcelItem.SKU;
              setSKU(SKU);
              setItemDescription(ItemDescription);
              setScanState(states.SUCCEDED);
              onComplete(newParcelItem);
              setUPI('');
            } else {
              setParcelItemToAdd(items[0]);
              setConfirmationDialogVisibility(true);
            }
          } else {
            const [item] = items;
            const itemIsAlreadyPresent = !!parcelItems.find(
              (pItem: any) => pItem.EWS.toString() === item.EWS.toString()
            );
            if (itemIsAlreadyPresent) {
              setScanState(states.ALREADY_ADDED);
              setUPI('');
            } else {
              const { SKU, ItemDescription } = item.SKU;
              setSKU(SKU);
              setItemDescription(ItemDescription);
              setScanState(states.SUCCEDED);
              onComplete(item);
              setUPI('');
            }
          }
        } catch (error) {
          console.log(error);
          setScanState(states.ERRORED);
          setUPI('');
        }
      } else {
        setScanState(states.NEW);
        setUPI('');
        onComplete(value);
      }
    }
  };

  const handleDialogClose = () => {
    setConfirmationDialogVisibility(false);
    setScanState(states.NEW);
    setUPI('');
  };

  const handleDialogConfirm = () => {
    const { SKU, ItemDescription } = parcelItemToAdd.SKU;
    setConfirmationDialogVisibility(false);
    setSKU(SKU);
    setItemDescription(ItemDescription);
    setScanState(states.SUCCEDED);
    onComplete(parcelItemToAdd);
    setUPI('');
  };

  const handleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    setUPI(formatAlphanumericInput(element.value));
  };

  const renderStatus = (status: string) => {
    const acceptedStatusBox = (
      <StatusPopup type="SUCCESS" text={`Item ${sku} - ${itemDescription} has been added to parcel`} />
    );
    const progressIndicator = (
      <div className={classes.progressWrapper}>
        <CircularProgress size={50} className={classes.fabProgress} />
      </div>
    );
    const noOrderDataStatusBox = <StatusPopup type="ERROR" text="Item not found" />;
    const itemAlreadyScannedStatusBox = <StatusPopup type="SUCCESS" text="Item has been already added to parcel" />;

    return (
      {
        [states.SUCCEDED]: acceptedStatusBox,
        [states.IN_PROGRESS]: progressIndicator,
        [states.ERRORED]: noOrderDataStatusBox,
        [states.ALREADY_ADDED]: itemAlreadyScannedStatusBox
      }[status] || null
    );
  };

  return (
    <form className={classes.scanForm}>
      <MuiThemeProvider theme={customTheme}>
      <Grid container className={classes.root} alignItems="stretch">
        <Grid item xs={4}>
          <TextField
            fullWidth
            autoFocus
            inputProps={{
              maxLength: 25
            }}
            autoComplete="off"
            onKeyPress={handleKeyPress}
            onInput={handleInput}
            label="Item Label"
            variant="outlined"
            name="upi"
            id="upi"
            value={upi}
            inputRef={parcelItemLabelInputRef}
          />
        </Grid>
        <Grid item xs className={classes.statusContainer}>
          {renderStatus(scanState)}
        </Grid>
        <ConfirmationDialog
          open={confirmationDialogVisibility}
          onClose={handleDialogClose}
          onConfirm={handleDialogConfirm}
          title="Confirm"
          text={`Item ${upi} is already present. Add again?`}
        />
      </Grid>
      </MuiThemeProvider>
    </form>
  );
};
