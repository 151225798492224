import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';

interface IAlertDialog {
  children: ReactNode;
  open: boolean;
  onClose(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(3)
    },
    actions: {
      padding: theme.spacing(0, 3, 3, 3)
    }
  })
);

export const AlertDialog = (props: IAlertDialog) => {
  const classes = useStyles();
  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog onClose={handleClose} open={props.open} disableBackdropClick maxWidth="sm">
      <DialogContent className={classes.content}>
        <Typography>{props.children}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
